import trim from 'lodash/trim';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(obj: any): obj is object {
  return obj !== null && typeof obj === 'object';
}

export function isUrlExternal(url: string, currentBaseUrl: string): boolean {
  try {
    const baseUrlL = currentBaseUrl.toLowerCase();
    const parsedUrl = new URL(url, baseUrlL);
    return parsedUrl.origin !== baseUrlL;
  } catch (e) {
    console.error('Error parsing URL:', url);
    return false;
  }
}

export function pathToCraftUri(path: string): string {
  return path === '/' ? '__home__' : trim(path.toLowerCase(), '/');
}

export function craftUriToPath(uri: string): string {
  return uri === '__home__' ? '/' : `/${uri}`;
}

export function craftSiteHandleToUrl(siteHandle: string): string {
  switch (siteHandle) {
    case 'default':
      return process.env.NEXT_PUBLIC_PRIMARY_URL!;
    case 'shareholderRights':
      return process.env.NEXT_PUBLIC_SR_URL!;
    default:
      throw new Error(`Unknown site handle: ${siteHandle}`);
  }
}

/**
 * Spacing utility function that takes values from the CMS and returns the appropriate Tailwind CSS
 * classes
 */
export function getSpacingClasses(value: string): string {
  switch (value) {
    case '0': {
      return 'py-0';
    }
    case '6': {
      return 'py-4 md:py-6';
    }
    case '12': {
      return 'py-8 md:py-12';
    }
    case '20': {
      return 'py-12 md:py-20';
    }
    default: {
      return 'py-8 md:py-12';
    }
  }
}
