'use client';

import type { Block_Insights } from '@/types/fragments/mainNeo';
import type { InsightCategory } from '@/types/queries/getPageWithUri';
import { Suspense, useEffect, useRef, useState, type MutableRefObject } from 'react';
import { useSearchParams } from 'next/navigation';
import { getGqlClient, getGqlSdk } from '@/lib/gql';
import { getSpacingClasses } from '@/lib/utils';
import InsightCard from '@/components/cards/InsightCard';
import InsightsPagination from './InsightsPagination';

const PAGE_LIMIT = 12;

const gqlSdk = getGqlSdk(getGqlClient(null));

type InsightsIndexInnerProps = {
  entry: InsightCategory;
  headingRef: MutableRefObject<HTMLDivElement | null>;
};

function InsightsIndexInner({ entry, headingRef }: InsightsIndexInnerProps) {
  const searchParams = useSearchParams();
  const [insights, setInsights] = useState<Block_Insights['insights'] | null>(null);
  const [numResults, setNumResults] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const numPages = numResults ? Math.ceil(numResults / PAGE_LIMIT) : null;
  const page = clampNum(Number(searchParams.get('p')), 1, numPages || 1);

  useEffect(() => {
    getPaginatedInsights({
      entry,
      page,
    }).then(({ insights, numResults }) => {
      setIsLoading(false);
      setInsights(insights);
      setNumResults(numResults);
    });
    // entry is static
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!insights?.length)
    return isLoading ? (
      <div className="grid place-items-center">
        <div
          className="inline-block size-8 animate-spin rounded-full border-[3px] border-current border-t-transparent text-brabners-copper"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : (
      <p>No insights were found.</p>
    );

  return (
    <>
      <div className="grid gap-10 sm:grid-cols-2 md:grid-cols-3 md:gap-14">
        {insights.map((insight) => (
          <InsightCard key={insight.id} {...insight} />
        ))}
      </div>
      <div className="mt-14">
        <InsightsPagination currentPage={page} numPages={numPages!} headingRef={headingRef} />
      </div>
    </>
  );
}

type InsightsIndexBlockProps = Partial<
  Omit<Block_Insights, 'insightSelectionMethod' | 'insights' | 'insightCategory' | 'link'>
> & { entry: InsightCategory };

export default function InsightsIndex({
  entry,
  heading = '',
  headingElement = 'h2',
  anchor = '',
  spacing = '12', // Medium
}: InsightsIndexBlockProps) {
  const spacingClasses = getSpacingClasses(spacing);
  const Heading = headingElement as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  const headingRef = useRef<HTMLHeadingElement | null>(null);

  // https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
  return (
    <section id={anchor || undefined} className={`container ${spacingClasses}`}>
      <Heading ref={headingRef} className="heading mb-11 text-center text-4xl md:mb-8 lg:text-5xl">
        {heading || 'Related insights'}
      </Heading>
      <Suspense fallback={<p>Loading insights...</p>}>
        <InsightsIndexInner entry={entry} headingRef={headingRef} />
      </Suspense>
    </section>
  );
}

async function getPaginatedInsights({
  entry: insightCategory,
  page,
}: {
  entry: InsightsIndexBlockProps['entry'];
  page: number;
}): Promise<{ insights: Block_Insights['insights']; numResults: number }> {
  /**
   * Using the same tag as GetPageWithUri so that it's invalidated at the same time
   */
  const res = await gqlSdk.GetPaginatedInsights({
    entryIds: [insightCategory.id],
    limit: PAGE_LIMIT,
    offset: (page - 1) * PAGE_LIMIT,
    siteHandle: insightCategory.siteHandle,
  });

  const { insights, numResults } = res;

  // Use the insight category's URL
  return {
    insights: insights.map((insight) => ({
      ...insight,
      uri: `${insightCategory.uri}/${insight.slug}`,
    })),
    numResults,
  };
}

function clampNum(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max);
}
