'use client';

import { useCallback, useEffect, useState, type Dispatch, type SetStateAction } from 'react';

/**
 * A basic local storage hook which has some limitations which aren't required for this app:
 *
 * - No facility to remove an item from local storage - Cookiebot handles this
 * - Assumes JSON for serialisation
 */
export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = typeof window === 'undefined' ? null : window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore = isFunction(value) ? value(storedValue) : value;
        setStoredValue(valueToStore);

        if (valueToStore === null) {
          window.localStorage.removeItem(key);
        } else {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [key, storedValue],
  );

  // Subscribe to storage events from other tabs
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        const newValue = event.newValue === null ? null : JSON.parse(event.newValue);
        setStoredValue(newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  });

  return [storedValue, setValue];
}

function isFunction(value: unknown) {
  return typeof value === 'function';
}
