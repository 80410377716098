import type { Block_SummaryCards_Entry } from '@/types/fragments/mainNeo';
import Link from '@/components/Link';

type SummaryCardProps = Block_SummaryCards_Entry & {
  className?: string;
};

export default function SummaryCard({
  title,
  indexHeading,
  indexSummary,
  url,
  className,
}: SummaryCardProps) {
  return (
    <div className={`flex flex-col items-center text-center ${className || ''}`}>
      <h3 className="heading mb-2 text-3xl text-brabners-copper-300">{indexHeading || title}</h3>
      <p className="mb-2.5 text-sm">{indexSummary}</p>
      <Link
        href={url}
        // Move right a bit to account for the arrow which doesn't take its full width until hovered
        className="group flex translate-x-[9px] items-center font-bold text-brabners-heading"
      >
        Read more
        <div className="ml-3.5 overflow-hidden">
          <svg
            width="56"
            height="14"
            viewBox="0 0 56 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="presentation"
            className="-translate-x-1/3 transition-transform duration-300 motion-safe:group-hover:translate-x-0"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M51.5391 6.28345L47.1387 2.00002L48.5338 0.566895L55.4336 7.28345L48.5338 14L47.1387 12.5669L51.5391 8.28345H0V6.28345H51.5391Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </Link>
    </div>
  );
}
