/**
 * All possible colourway options
 */
export type Colourway =
  | 'black'
  | 'blue'
  | 'green'
  | 'mushroom'
  | 'navy'
  | 'pink'
  | 'white'
  | 'yellow';

/**
 * Colourway options available for Case Highlights block
 */
export type CaseHighlightsColourway = 'blue' | 'green';

/**
 * Colourway options available for TextHero block
 */
export type TextHeroColourway = 'black' | 'green' | 'navy' | 'pink' | 'white';

/**
 * Colourway options available for Testimonials block
 */
export type TestimonialsColourway = 'blue' | 'green' | 'pink' | 'white';

type ColourScheme = {
  bg: string;
  text: string;
  heading: string;
  pattern: string;
  swiperTheme: string | undefined;
  swiperPaginationInactive: string | undefined;
};

// swiperPagination: ' [--swiper-theme-color:theme(colors.brabners.copper.600)]',
// swiperPaginationInactive: '[ --swiper-pagination-bullet-inactive-color:theme(colors.brabners.storm)]',

export default function getColourScheme(colourway: Colourway): ColourScheme {
  switch (colourway) {
    case 'blue':
      return {
        bg: 'bg-brabners-blue',
        text: 'text-white',
        heading: '[--color-heading:theme(colors.brabners.copper.300)]',
        pattern: 'text-brabners-blue-300',
        swiperTheme: '[--swiper-theme-color:theme(colors.brabners.copper.300)]',
        swiperPaginationInactive:
          '[--swiper-pagination-bullet-inactive-color:theme(colors.brabners.blue.300)]',
      };

    case 'pink':
      return {
        bg: 'bg-brabners-stone-pink',
        text: 'text-brabners-black',
        heading: '[--color-heading:theme(colors.brabners.copper.600)]',
        pattern: 'text-brabners-stone-pink-600',
        swiperTheme: '[--swiper-theme-color:theme(colors.brabners.copper.600)]',
        swiperPaginationInactive:
          '[--swiper-pagination-bullet-inactive-color:theme(colors.brabners.stone-pink.700)]',
      };

    case 'yellow':
      return {
        bg: 'bg-brabners-sunset-yellow',
        text: 'text-black',
        heading: '[--color-heading:theme(colors.brabners.copper.600)]',
        pattern: 'text-brabners-copper-700',
        // Carousel not supported
        swiperTheme: undefined,
        swiperPaginationInactive: undefined,
      };

    case 'black':
      return {
        bg: 'bg-black',
        text: 'text-white',
        heading: '[--color-heading:white]',
        pattern: 'text-brabners-navy-200',
        // Carousel not supported
        swiperTheme: undefined,
        swiperPaginationInactive: undefined,
      };

    case 'navy':
      return {
        bg: 'bg-brabners-navy',
        text: 'text-white',
        heading: '[--color-heading:theme(colors.brabners.copper.300)]',
        pattern: 'text-brabners-navy-200',
        // Carousel not supported
        swiperTheme: undefined,
        swiperPaginationInactive: undefined,
      };

    case 'mushroom':
      return {
        bg: 'bg-brabners-mushroom',
        text: 'text-brabners-black',
        heading: '[--color-heading:theme(colors.brabners.copper.600)]',
        pattern: 'text-brabners-navy-200', // Placeholder - not used anywhere
        // Carousel not supported
        swiperTheme: undefined,
        swiperPaginationInactive: undefined,
      };

    case 'green':
      return {
        bg: 'bg-brabners-lunar-green',
        text: 'text-white',
        heading: '[--color-heading:theme(colors.brabners.copper.300)]',
        pattern: 'text-brabners-lunar-green-300', // Placeholder - not used anywhere
        swiperTheme: '[--swiper-theme-color:theme(colors.brabners.copper.300)]',
        swiperPaginationInactive:
          '[--swiper-pagination-bullet-inactive-color:theme(colors.brabners.lunar-green.300)]',
      };

    // Fallback to white
    default:
      if (colourway !== 'white') {
        console.warn(`Unrecognised colourway: '${colourway}'`);
      }
      return {
        bg: 'bg-brabners-cream',
        text: 'text-brabners-black',
        heading: '[--color-heading:theme(colors.brabners.copper.600)]',
        pattern: 'text-brabners-mushroom',
        swiperTheme: '[--swiper-theme-color:theme(colors.brabners.copper.600)]',
        swiperPaginationInactive:
          '[--swiper-pagination-bullet-inactive-color:theme(colors.brabners.storm)]',
      };
  }
}

/**
 * Currently hard-coded
 */
const forLifeServiceSlugs = [
  'brabners-personal',
  'family-law',
  'medical-negligence-serious-injuries',
  'private-client',
  'contested-wills-estates-and-trusts',
];

export function isBrabnersPersonal(pathname: string) {
  // Insight category
  if (pathname === '/insights/brabners-personal') {
    return true;
  }

  // Services - currently hard-coded
  const segments = pathname.split('/');

  if (segments[1] !== 'services') {
    return false;
  }

  return forLifeServiceSlugs.includes(segments[2]?.toLowerCase());
}
