import BurgerMenu from '@/components/icons/BurgerMenu.svg';
import CloseIcon from '@/components/icons/CloseIcon.svg';
import type { Colourway } from '@/lib/theme';

type MobileTriggerProps = {
  className?: string;
  isEnabled: boolean;
  setIsEnabled: (value: boolean) => void;
  colourway: Colourway;
};

export default function MobileTrigger({
  className,
  isEnabled,
  setIsEnabled,
  colourway,
  ...attributes
}: MobileTriggerProps) {
  const textColour =
    colourway === 'yellow' || colourway === 'pink'
      ? 'text-brabners-copper-600'
      : 'text-brabners-copper-300';
  return (
    <div className={className} {...attributes}>
      <button
        type="button"
        className="inline-flex items-end justify-end align-middle"
        aria-controls="mobile-navbar"
        aria-label="Toggle navigation"
        onClick={() => {
          setIsEnabled(!isEnabled);
        }}
      >
        <BurgerMenu className={`${textColour} h-7 w-8 ${isEnabled ? 'hidden' : ''}`} />
        <CloseIcon className={`${textColour} h-7 w-8 ${isEnabled ? '' : 'hidden'}`} />
      </button>
    </div>
  );
}
