import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/AnimatedPattern.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/hero/FeatureAnimations/ConstructionAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/hero/FeatureAnimations/HealthAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/hero/FeatureAnimations/RetailAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/main/Accreditations.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/main/CaseHighlights.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/main/CookieDeclaration.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/main/Map.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/main/PeopleSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/main/SummaryCards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/main/Testimonials.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/main/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/forms/FormBody.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/forms/HubspotEmbed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/header/HeaderInner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/header/nav/SectorsNavNodeInner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/Logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/PersonalLogo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/SoundWave.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/SoundWaveRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveHeroBottomRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveHeroTopRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveLargeBottomLeft.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveLargeBottomRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveLargeHeroBottomRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveLargeTopLeft.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveLargeTopRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveLargeVertical.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveMediumHeroBottomRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveMediumSquatBottomLeft.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveMediumSquatTopLeft.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveMediumTopLeft.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveMediumTopRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveSmallTopLeft.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveSmallTopRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveSwirlRight.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/icons/WaveVerticalGraphicRight.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/insights/InsightsIndex.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ShareButton.tsx");
