import HeaderSearch from '@/components/header/search/HeaderSearch';
import type { Colourway } from '@/lib/theme';

/**
 * Client component wrapper around the HeaderInner server component, which requires data fetched on the server.
 */
export default function MobileNav({
  colourway,
  children,
  isEnabled,
}: {
  colourway: Colourway;
  children: React.ReactNode;
  isEnabled: boolean;
}) {
  return (
    <nav
      id="navbar-collapse-basic"
      className={`md:hidden ${isEnabled ? '' : 'hidden'}`}
      aria-label="Mobile navigation"
    >
      <div className="block md:hidden">
        <HeaderSearch className="mx-auto w-full max-w-container px-7 pb-4" colourway={colourway} />
      </div>
      {children}
    </nav>
  );
}
