import type { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Number: { input: any; output: any; }
  QueryArgument: { input: any; output: any; }
};

export type Address = AddressInterface & ElementInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** First line of the address */
  addressLine1: Maybe<Scalars['String']['output']>;
  /** Second line of the address */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** Administrative area. */
  administrativeArea: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Two-letter country code */
  countryCode: Scalars['String']['output'];
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Dependent locality */
  dependentLocality: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The first name on the address. */
  firstName: Maybe<Scalars['String']['output']>;
  /** The full name on the address. */
  fullName: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The last name on the address. */
  lastName: Maybe<Scalars['String']['output']>;
  /** Latitude */
  latitude: Maybe<Scalars['String']['output']>;
  /** Locality */
  locality: Maybe<Scalars['String']['output']>;
  /** Longitude */
  longitude: Maybe<Scalars['String']['output']>;
  /** Organization name */
  organization: Maybe<Scalars['String']['output']>;
  /** Organization tax ID */
  organizationTaxId: Maybe<Scalars['String']['output']>;
  /** Postal code */
  postalCode: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** Sorting code */
  sortingCode: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Address_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by all addresses. */
export type AddressInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** First line of the address */
  addressLine1: Maybe<Scalars['String']['output']>;
  /** Second line of the address */
  addressLine2: Maybe<Scalars['String']['output']>;
  /** Administrative area. */
  administrativeArea: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Two-letter country code */
  countryCode: Scalars['String']['output'];
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Dependent locality */
  dependentLocality: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The first name on the address. */
  firstName: Maybe<Scalars['String']['output']>;
  /** The full name on the address. */
  fullName: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The last name on the address. */
  lastName: Maybe<Scalars['String']['output']>;
  /** Latitude */
  latitude: Maybe<Scalars['String']['output']>;
  /** Locality */
  locality: Maybe<Scalars['String']['output']>;
  /** Longitude */
  longitude: Maybe<Scalars['String']['output']>;
  /** Organization name */
  organization: Maybe<Scalars['String']['output']>;
  /** Organization tax ID */
  organizationTaxId: Maybe<Scalars['String']['output']>;
  /** Postal code */
  postalCode: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** Sorting code */
  sortingCode: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all addresses. */
export type AddressInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type AssetCriteriaInput = {
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the assets’ files’ last-modified dates. */
  dateModified: InputMaybe<Scalars['String']['input']>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the assets’ filenames. */
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the folders the assets belong to, per the folders’ IDs. */
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on whether the assets have alternative text. */
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the assets’ image heights. */
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ IDs. */
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  /** Broadens the query results to include assets from any of the subfolders of the folder specified by `folderId`. */
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the assets’ file kinds. */
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Sets the limit for paginated results. */
  limit: InputMaybe<Scalars['Int']['input']>;
  /** Sets the offset for paginated results. */
  offset: InputMaybe<Scalars['Int']['input']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy: InputMaybe<Scalars['String']['input']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on a reference string. */
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the assets’ file sizes (in bytes). */
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the user the assets were uploaded by, per the user’s ID. */
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ handles. */
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ IDs. */
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the assets’ image widths. */
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A list of transform handles to preload. */
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Alternative text for the asset. */
  alt: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the asset file was last modified. */
  dateModified: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The file extension for the asset file. */
  extension: Scalars['String']['output'];
  /** The filename of the asset file. */
  filename: Scalars['String']['output'];
  /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
  focalPoint: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** The ID of the folder that the asset belongs to. */
  folderId: Scalars['Int']['output'];
  /** Returns the file’s format. */
  format: Maybe<Scalars['String']['output']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint: Scalars['Boolean']['output'];
  /** The height in pixels or null if it’s not an image. */
  height: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** An `<img>` tag based on this asset. */
  img: Maybe<Scalars['String']['output']>;
  /** The file kind. */
  kind: Scalars['String']['output'];
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<AssetInterface>;
  /** The asset’s path in the volume. */
  path: Scalars['String']['output'];
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<AssetInterface>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The file size in bytes. */
  size: Maybe<Scalars['String']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url: Maybe<Scalars['String']['output']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId: Maybe<Scalars['Int']['output']>;
  /** The width in pixels or null if it’s not an image. */
  width: Maybe<Scalars['Int']['output']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterface_CountArgs = {
  field: Scalars['String']['input'];
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceFormatArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceHeightArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceNextArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfacePrevArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceSrcsetArgs = {
  sizes: Array<Scalars['String']['input']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceUrlArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceWidthArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryCriteriaInput = {
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  /** Whether to only return categories that the user has permission to edit. */
  editable: InputMaybe<Scalars['Boolean']['input']>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the category groups the categories belong to per the group’s handles. */
  group: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the category groups the categories belong to, per the groups’ IDs. */
  groupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on whether the elements have any descendants in their structure. */
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ IDs. */
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ level within the structure. */
  level: InputMaybe<Scalars['Int']['input']>;
  /** Sets the limit for paginated results. */
  limit: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  /** Sets the offset for paginated results. */
  offset: InputMaybe<Scalars['Int']['input']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy: InputMaybe<Scalars['String']['input']>;
  /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on a reference string. */
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which structure data should be joined into the query. */
  structureId: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on the elements’ titles. */
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

export type Element = ElementInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Element_CountArgs = {
  field: Scalars['String']['input'];
};

/** This is the interface implemented by all elements. */
export type ElementInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all elements. */
export type ElementInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type EntryCriteriaInput = {
  /** Narrows the query results to only entries that were posted on or after a certain date. */
  after: InputMaybe<Scalars['String']['input']>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on the user group the entries’ authors belong to. */
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the user group the entries’ authors belong to, per the groups’ IDs. */
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the entries’ authors. */
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only entries that were posted before a certain date. */
  before: InputMaybe<Scalars['String']['input']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  /** Whether to only return entries that the user has permission to edit. */
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the entries’ expiry dates. */
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on whether the elements have any descendants in their structure. */
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ IDs. */
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ level within the structure. */
  level: InputMaybe<Scalars['Int']['input']>;
  /** Sets the limit for paginated results. */
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Sets the offset for paginated results. */
  offset: InputMaybe<Scalars['Int']['input']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on the entries’ post dates. */
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  /** Narrows the query results based on a reference string. */
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only elements that match a search query. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Narrows the query results based on the section handles the entries belong to. */
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the sections the entries belong to, per the sections’ IDs. */
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Determines which structure data should be joined into the query. */
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the entries’ entry type handles. */
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the entries’ entry types, per the types’ IDs. */
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all entries. */
export type EntryInterface_CountArgs = {
  field: Scalars['String']['input'];
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfacePrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceSeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type FieldAttribute = {
  label: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all fields. */
export type FieldInterface = {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type FieldOption = {
  /** Whether this option has been marked as disabled. */
  disabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether this option has been marked as a default. */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /** Whether this option has been marked as an `optgroup`. */
  isOptgroup: Maybe<Scalars['Boolean']['output']>;
  /** The label of the option. */
  label: Maybe<Scalars['String']['output']>;
  /** The value of the option. */
  value: Maybe<Scalars['String']['output']>;
};

export type Field_Address = FieldInterface & {
  address1Collapsed: Maybe<Scalars['Boolean']['output']>;
  address1DefaultValue: Maybe<Scalars['String']['output']>;
  address1Enabled: Maybe<Scalars['Boolean']['output']>;
  address1ErrorMessage: Maybe<Scalars['String']['output']>;
  address1Hidden: Maybe<Scalars['Boolean']['output']>;
  address1Label: Maybe<Scalars['String']['output']>;
  address1Placeholder: Maybe<Scalars['String']['output']>;
  address1PrePopulate: Maybe<Scalars['String']['output']>;
  address1Required: Maybe<Scalars['Boolean']['output']>;
  address2Collapsed: Maybe<Scalars['Boolean']['output']>;
  address2DefaultValue: Maybe<Scalars['String']['output']>;
  address2Enabled: Maybe<Scalars['Boolean']['output']>;
  address2ErrorMessage: Maybe<Scalars['String']['output']>;
  address2Hidden: Maybe<Scalars['Boolean']['output']>;
  address2Label: Maybe<Scalars['String']['output']>;
  address2Placeholder: Maybe<Scalars['String']['output']>;
  address2PrePopulate: Maybe<Scalars['String']['output']>;
  address2Required: Maybe<Scalars['Boolean']['output']>;
  address3Collapsed: Maybe<Scalars['Boolean']['output']>;
  address3DefaultValue: Maybe<Scalars['String']['output']>;
  address3Enabled: Maybe<Scalars['Boolean']['output']>;
  address3ErrorMessage: Maybe<Scalars['String']['output']>;
  address3Hidden: Maybe<Scalars['Boolean']['output']>;
  address3Label: Maybe<Scalars['String']['output']>;
  address3Placeholder: Maybe<Scalars['String']['output']>;
  address3PrePopulate: Maybe<Scalars['String']['output']>;
  address3Required: Maybe<Scalars['Boolean']['output']>;
  autocompleteCollapsed: Maybe<Scalars['Boolean']['output']>;
  autocompleteCurrentLocation: Maybe<Scalars['Boolean']['output']>;
  autocompleteDefaultValue: Maybe<Scalars['String']['output']>;
  autocompleteEnabled: Maybe<Scalars['Boolean']['output']>;
  autocompleteErrorMessage: Maybe<Scalars['String']['output']>;
  autocompleteIntegration: Maybe<Scalars['String']['output']>;
  autocompleteLabel: Maybe<Scalars['String']['output']>;
  autocompletePlaceholder: Maybe<Scalars['String']['output']>;
  autocompletePrePopulate: Maybe<Scalars['String']['output']>;
  autocompleteRequired: Maybe<Scalars['Boolean']['output']>;
  cityCollapsed: Maybe<Scalars['Boolean']['output']>;
  cityDefaultValue: Maybe<Scalars['String']['output']>;
  cityEnabled: Maybe<Scalars['Boolean']['output']>;
  cityErrorMessage: Maybe<Scalars['String']['output']>;
  cityHidden: Maybe<Scalars['Boolean']['output']>;
  cityLabel: Maybe<Scalars['String']['output']>;
  cityPlaceholder: Maybe<Scalars['String']['output']>;
  cityPrePopulate: Maybe<Scalars['String']['output']>;
  cityRequired: Maybe<Scalars['Boolean']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  countryCollapsed: Maybe<Scalars['Boolean']['output']>;
  countryDefaultValue: Maybe<Scalars['String']['output']>;
  countryEnabled: Maybe<Scalars['Boolean']['output']>;
  countryErrorMessage: Maybe<Scalars['String']['output']>;
  countryHidden: Maybe<Scalars['Boolean']['output']>;
  countryLabel: Maybe<Scalars['String']['output']>;
  countryOptionLabel: Maybe<Scalars['String']['output']>;
  countryOptionValue: Maybe<Scalars['String']['output']>;
  countryOptions: Maybe<Array<Maybe<FieldAttribute>>>;
  countryPlaceholder: Maybe<Scalars['String']['output']>;
  countryPrePopulate: Maybe<Scalars['String']['output']>;
  countryRequired: Maybe<Scalars['Boolean']['output']>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  stateCollapsed: Maybe<Scalars['Boolean']['output']>;
  stateDefaultValue: Maybe<Scalars['String']['output']>;
  stateEnabled: Maybe<Scalars['Boolean']['output']>;
  stateErrorMessage: Maybe<Scalars['String']['output']>;
  stateHidden: Maybe<Scalars['Boolean']['output']>;
  stateLabel: Maybe<Scalars['String']['output']>;
  statePlaceholder: Maybe<Scalars['String']['output']>;
  statePrePopulate: Maybe<Scalars['String']['output']>;
  stateRequired: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
  zipCollapsed: Maybe<Scalars['Boolean']['output']>;
  zipDefaultValue: Maybe<Scalars['String']['output']>;
  zipEnabled: Maybe<Scalars['Boolean']['output']>;
  zipErrorMessage: Maybe<Scalars['String']['output']>;
  zipHidden: Maybe<Scalars['Boolean']['output']>;
  zipLabel: Maybe<Scalars['String']['output']>;
  zipPlaceholder: Maybe<Scalars['String']['output']>;
  zipPrePopulate: Maybe<Scalars['String']['output']>;
  zipRequired: Maybe<Scalars['Boolean']['output']>;
};

export type Field_Agree = FieldInterface & {
  checkedValue: Maybe<Scalars['String']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  defaultState: Maybe<Scalars['Boolean']['output']>;
  defaultValue: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  descriptionHtml: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  uncheckedValue: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Checkboxes = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  layout: Maybe<Scalars['String']['output']>;
  limitOptions: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  max: Maybe<Scalars['Float']['output']>;
  min: Maybe<Scalars['Float']['output']>;
  multi: Maybe<Scalars['Boolean']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  options: Maybe<Array<Maybe<FieldOption>>>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  toggleCheckbox: Maybe<Scalars['String']['output']>;
  toggleCheckboxLabel: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Date = FieldInterface & {
  ampmLabel: Maybe<Scalars['String']['output']>;
  ampmPlaceholder: Maybe<Scalars['String']['output']>;
  availableDaysOfWeek: Maybe<Scalars['String']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  dateFormat: Maybe<Scalars['String']['output']>;
  datePickerOptions: Maybe<Array<Maybe<FieldAttribute>>>;
  dayLabel: Maybe<Scalars['String']['output']>;
  dayPlaceholder: Maybe<Scalars['String']['output']>;
  defaultDate: Maybe<Scalars['DateTime']['output']>;
  defaultOption: Maybe<Scalars['String']['output']>;
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  displayType: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  hourLabel: Maybe<Scalars['String']['output']>;
  hourPlaceholder: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  includeDate: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  includeTime: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  maxDate: Maybe<Scalars['DateTime']['output']>;
  maxDateOffset: Maybe<Scalars['String']['output']>;
  maxDateOffsetNumber: Maybe<Scalars['Int']['output']>;
  maxDateOffsetType: Maybe<Scalars['String']['output']>;
  maxDateOption: Maybe<Scalars['String']['output']>;
  maxYearRange: Maybe<Scalars['Int']['output']>;
  minDate: Maybe<Scalars['DateTime']['output']>;
  minDateOffset: Maybe<Scalars['String']['output']>;
  minDateOffsetNumber: Maybe<Scalars['Int']['output']>;
  minDateOffsetType: Maybe<Scalars['String']['output']>;
  minDateOption: Maybe<Scalars['String']['output']>;
  minYearRange: Maybe<Scalars['Int']['output']>;
  minuteLabel: Maybe<Scalars['String']['output']>;
  minutePlaceholder: Maybe<Scalars['String']['output']>;
  monthLabel: Maybe<Scalars['String']['output']>;
  monthPlaceholder: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  secondLabel: Maybe<Scalars['String']['output']>;
  secondPlaceholder: Maybe<Scalars['String']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  timeFormat: Maybe<Scalars['String']['output']>;
  timeLabel: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  useDatePicker: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
  yearLabel: Maybe<Scalars['String']['output']>;
  yearPlaceholder: Maybe<Scalars['String']['output']>;
};

export type Field_Dropdown = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  limitOptions: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  max: Maybe<Scalars['Float']['output']>;
  min: Maybe<Scalars['Float']['output']>;
  multi: Maybe<Scalars['Boolean']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  options: Maybe<Array<Maybe<FieldOption>>>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Email = FieldInterface & {
  blockedDomains: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  uniqueValue: Maybe<Scalars['Boolean']['output']>;
  validateDomain: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Entries = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  defaultEntry: Maybe<EntryInterface>;
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  displayType: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  entries: Maybe<Array<Maybe<EntryInterface>>>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  searchable: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};


export type Field_EntriesDefaultEntryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Field_EntriesEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

export type Field_FileUpload = FieldInterface & {
  allowSubfolders: Maybe<Scalars['Boolean']['output']>;
  allowUploads: Maybe<Scalars['Boolean']['output']>;
  allowedKinds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  defaultUploadLocationSource: Maybe<Scalars['String']['output']>;
  defaultUploadLocationSubpath: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  limitFiles: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  previewMode: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  restrictFiles: Maybe<Scalars['Boolean']['output']>;
  restrictLocation: Maybe<Scalars['Boolean']['output']>;
  restrictedDefaultUploadSubpath: Maybe<Scalars['String']['output']>;
  restrictedLocationSource: Maybe<Scalars['String']['output']>;
  restrictedLocationSubpath: Maybe<Scalars['String']['output']>;
  searchable: Maybe<Scalars['Boolean']['output']>;
  showUnpermittedFiles: Maybe<Scalars['Boolean']['output']>;
  showUnpermittedVolumes: Maybe<Scalars['Boolean']['output']>;
  sizeLimit: Maybe<Scalars['String']['output']>;
  sizeMinLimit: Maybe<Scalars['String']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  uploadLocationSource: Maybe<Scalars['String']['output']>;
  uploadLocationSubpath: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
  volumeHandle: Maybe<Scalars['String']['output']>;
};

export type Field_Group = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s nested fields. */
  fields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s nested rows. */
  nestedRows: Maybe<Array<Maybe<RowInterface>>>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Heading = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  headingSize: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Hidden = FieldInterface & {
  columnType: Maybe<Scalars['String']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  cookieName: Maybe<Scalars['String']['output']>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  defaultOption: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  queryParameter: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Html = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  htmlContent: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  purifyContent: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_MultiLineText = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  limit: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  max: Maybe<Scalars['Int']['output']>;
  maxType: Maybe<Scalars['String']['output']>;
  min: Maybe<Scalars['Int']['output']>;
  minType: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  richTextButtons: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  uniqueValue: Maybe<Scalars['Boolean']['output']>;
  useRichText: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Name = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  firstNameCollapsed: Maybe<Scalars['Boolean']['output']>;
  firstNameDefaultValue: Maybe<Scalars['String']['output']>;
  firstNameEnabled: Maybe<Scalars['Boolean']['output']>;
  firstNameErrorMessage: Maybe<Scalars['String']['output']>;
  firstNameLabel: Maybe<Scalars['String']['output']>;
  firstNamePlaceholder: Maybe<Scalars['String']['output']>;
  firstNamePrePopulate: Maybe<Scalars['String']['output']>;
  firstNameRequired: Maybe<Scalars['Boolean']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  lastNameCollapsed: Maybe<Scalars['Boolean']['output']>;
  lastNameDefaultValue: Maybe<Scalars['String']['output']>;
  lastNameEnabled: Maybe<Scalars['Boolean']['output']>;
  lastNameErrorMessage: Maybe<Scalars['String']['output']>;
  lastNameLabel: Maybe<Scalars['String']['output']>;
  lastNamePlaceholder: Maybe<Scalars['String']['output']>;
  lastNamePrePopulate: Maybe<Scalars['String']['output']>;
  lastNameRequired: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  middleNameCollapsed: Maybe<Scalars['Boolean']['output']>;
  middleNameDefaultValue: Maybe<Scalars['String']['output']>;
  middleNameEnabled: Maybe<Scalars['Boolean']['output']>;
  middleNameErrorMessage: Maybe<Scalars['String']['output']>;
  middleNameLabel: Maybe<Scalars['String']['output']>;
  middleNamePlaceholder: Maybe<Scalars['String']['output']>;
  middleNamePrePopulate: Maybe<Scalars['String']['output']>;
  middleNameRequired: Maybe<Scalars['Boolean']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  prefixCollapsed: Maybe<Scalars['Boolean']['output']>;
  prefixDefaultValue: Maybe<Scalars['String']['output']>;
  prefixEnabled: Maybe<Scalars['Boolean']['output']>;
  prefixErrorMessage: Maybe<Scalars['String']['output']>;
  prefixLabel: Maybe<Scalars['String']['output']>;
  prefixOptions: Maybe<Array<Maybe<FieldAttribute>>>;
  prefixPlaceholder: Maybe<Scalars['String']['output']>;
  prefixPrePopulate: Maybe<Scalars['String']['output']>;
  prefixRequired: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  useMultipleFields: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Number = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  decimals: Maybe<Scalars['Int']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  limit: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  max: Maybe<Scalars['Int']['output']>;
  maxValue: Maybe<Scalars['Float']['output']>;
  min: Maybe<Scalars['Int']['output']>;
  minValue: Maybe<Scalars['Float']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  uniqueValue: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Password = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Phone = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  countryAllowed: Maybe<Scalars['String']['output']>;
  countryCollapsed: Maybe<Scalars['Boolean']['output']>;
  countryDefaultValue: Maybe<Scalars['String']['output']>;
  countryEnabled: Maybe<Scalars['Boolean']['output']>;
  countryOptions: Maybe<Array<Maybe<FieldAttribute>>>;
  countryShowDialCode: Maybe<Scalars['Boolean']['output']>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Radio = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  layout: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  options: Maybe<Array<Maybe<FieldOption>>>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Recipients = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  displayType: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  multiple: Maybe<Scalars['Boolean']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  options: Maybe<Array<Maybe<FieldOption>>>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Repeater = FieldInterface & {
  addLabel: Maybe<Scalars['String']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s nested fields. */
  fields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  maxRows: Maybe<Scalars['Int']['output']>;
  minRows: Maybe<Scalars['Int']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s nested rows. */
  nestedRows: Maybe<Array<Maybe<RowInterface>>>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Section = FieldInterface & {
  borderColor: Maybe<Scalars['String']['output']>;
  borderStyle: Maybe<Scalars['String']['output']>;
  borderWidth: Maybe<Scalars['Int']['output']>;
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_SingleLineText = FieldInterface & {
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  limit: Maybe<Scalars['Boolean']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  max: Maybe<Scalars['Int']['output']>;
  maxType: Maybe<Scalars['String']['output']>;
  min: Maybe<Scalars['Int']['output']>;
  minType: Maybe<Scalars['String']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  uniqueValue: Maybe<Scalars['Boolean']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

export type Field_Table = FieldInterface & {
  addRowLabel: Maybe<Scalars['String']['output']>;
  columnType: Maybe<Scalars['String']['output']>;
  columns: Maybe<Array<Maybe<KeyValueType>>>;
  /** The field’s conditions as a JSON string. */
  conditions: Maybe<Scalars['String']['output']>;
  /** The field’s container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** The field’s default value as a string. Some fields have different fields for their default value. */
  defaultValue: Maybe<Scalars['String']['output']>;
  defaults: Maybe<Scalars['String']['output']>;
  /** The field’s display name (last portion of the class). */
  displayName: Maybe<Scalars['String']['output']>;
  /** Whether the field has conditions enabled. */
  enableConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the field has content encryption enabled. */
  enableContentEncryption: Maybe<Scalars['Boolean']['output']>;
  /** The field’s error message. */
  errorMessage: Maybe<Scalars['String']['output']>;
  /** The field’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the field should be included in email content. */
  includeInEmail: Maybe<Scalars['Boolean']['output']>;
  /** The field’s input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The field’s full GQL input type. Useful for mutations. */
  inputTypeName: Maybe<Scalars['String']['output']>;
  /** The field’s instructions. */
  instructions: Maybe<Scalars['String']['output']>;
  /** The field’s instructions position. This will be a `verbb\formie\positions` class name. */
  instructionsPosition: Maybe<Scalars['String']['output']>;
  /** The field’s label position. This will be a `verbb\formie\positions` class name. */
  labelPosition: Maybe<Scalars['String']['output']>;
  /** The field handle for another field that this value should match exactly. */
  matchField: Maybe<Scalars['String']['output']>;
  maxRows: Maybe<Scalars['Int']['output']>;
  minRows: Maybe<Scalars['Int']['output']>;
  /** The field’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The field’s placeholder. */
  placeholder: Maybe<Scalars['String']['output']>;
  /** The field’s pre-populated value extracted from the query string. */
  prePopulate: Maybe<Scalars['String']['output']>;
  /** Whether the field is required. */
  required: Maybe<Scalars['Boolean']['output']>;
  searchable: Maybe<Scalars['Boolean']['output']>;
  static: Maybe<Scalars['Boolean']['output']>;
  staticRows: Maybe<Scalars['Boolean']['output']>;
  /** The field’s sub-field label position. This will be a `verbb\formie\positions` class name. */
  subfieldLabelPosition: Maybe<Scalars['String']['output']>;
  /** The field’s type. */
  type: Maybe<Scalars['String']['output']>;
  /** The field’s full GQL type. */
  typeName: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The field’s visibility. */
  visibility: Maybe<Scalars['String']['output']>;
};

/** Contents of a file */
export type FileContents = {
  contents: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
};

export type FormIntegrationsType = {
  /** Whether the integration is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The integration’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The integration’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The integration’s settings as a JSON string. */
  settings: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all forms. */
export type FormInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** A list of captcha values (name and value) to assist with spam protection. */
  captchas: Maybe<Array<Maybe<FormieCaptchaType>>>;
  /** The form’s config as JSON. */
  configJson: Maybe<Scalars['String']['output']>;
  /** A CSRF token (name and value). */
  csrfToken: Maybe<FormieCsrfTokenType>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The form’s fields. */
  formFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The form’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the form is considered available according to user checks, scheduling and more. */
  isAvailable: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The form’s pages. */
  pages: Maybe<Array<Maybe<PageInterface>>>;
  /** The form’s rows. */
  rows: Maybe<Array<Maybe<RowInterface>>>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The form’s settings. */
  settings: Maybe<FormSettingsType>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The form’s endpoint for sending submissions to, if using POST requests. */
  submissionEndpoint: Maybe<Scalars['String']['output']>;
  /** The form’s GQL mutation name for submissions to use. */
  submissionMutationName: Maybe<Scalars['String']['output']>;
  /** The form’s rendered HTML. */
  templateHtml: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all forms. */
export type FormInterface_CountArgs = {
  field: Scalars['String']['input'];
};


/** This is the interface implemented by all forms. */
export type FormInterfaceFormFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};


/** This is the interface implemented by all forms. */
export type FormInterfaceTemplateHtmlArgs = {
  options: InputMaybe<Scalars['String']['input']>;
  populateFormValues: InputMaybe<Scalars['String']['input']>;
};

export type FormSettingsType = {
  /** The form’s default instructions position for fields. This will be a `verbb\formie\positions` class name. */
  defaultInstructionsPosition: Maybe<Scalars['String']['output']>;
  /** The form’s default label position for fields. This will be a `verbb\formie\positions` class name. */
  defaultLabelPosition: Maybe<Scalars['String']['output']>;
  /** Whether to show the form’s current page title. */
  displayCurrentPageTitle: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the form’s title. */
  displayFormTitle: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the form’s page progress. */
  displayPageProgress: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the form’s page tabs. */
  displayPageTabs: Maybe<Scalars['Boolean']['output']>;
  /** The form’s submit error message. */
  errorMessageHtml: Maybe<Scalars['String']['output']>;
  /** The form’s error message position. Either `null`, `top-form` or `bottom-form`. */
  errorMessagePosition: Maybe<Scalars['String']['output']>;
  /** The form’s enabled integrations. */
  integrations: Maybe<Array<Maybe<FormIntegrationsType>>>;
  /** The type of loading indicator to use. Either `spinner` or `text`. */
  loadingIndicator: Maybe<Scalars['String']['output']>;
  /** The form’s loading indicator text. */
  loadingIndicatorText: Maybe<Scalars['String']['output']>;
  /** The form’s progress bar position. Either `start` or `end`. */
  progressPosition: Maybe<Scalars['String']['output']>;
  /** The form’s submit action entry (for redirection), if `submitAction` is `entry`. */
  redirectEntry: Maybe<EntryInterface>;
  /** The form’s submit action redirect URL, resolved depending on `submitAction` being `entry` or `url`. */
  redirectUrl: Maybe<Scalars['String']['output']>;
  /** Whether to the form should scroll to the top of the page when submitted. */
  scrollToTop: Maybe<Scalars['Boolean']['output']>;
  /** The form’s submit action. Either `message`, `entry`, `url`, `reload`. */
  submitAction: Maybe<Scalars['String']['output']>;
  /** Whether to hide the form’s success message. */
  submitActionFormHide: Maybe<Scalars['Boolean']['output']>;
  /** The form’s submit success message. */
  submitActionMessageHtml: Maybe<Scalars['String']['output']>;
  /** The form’s submit message position. Either `top-form` or `bottom-form`. */
  submitActionMessagePosition: Maybe<Scalars['String']['output']>;
  /** The form’s submit success message timeout in seconds. */
  submitActionMessageTimeout: Maybe<Scalars['Int']['output']>;
  /** The form’s submit redirect option (if in new tab or same tab). Either `same-tab` or `new-tab`. */
  submitActionTab: Maybe<Scalars['String']['output']>;
  /** The form’s submit method. Either `page-reload` or `ajax`. */
  submitMethod: Maybe<Scalars['String']['output']>;
  /** Whether to validate the form’s on focus. */
  validationOnFocus: Maybe<Scalars['Boolean']['output']>;
  /** Whether to validate the form’s on submit. */
  validationOnSubmit: Maybe<Scalars['Boolean']['output']>;
};


export type FormSettingsTypeRedirectEntryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

export type FormieCaptchaInput = {
  name: InputMaybe<Scalars['String']['input']>;
  value: InputMaybe<Scalars['String']['input']>;
};

export type FormieCaptchaType = {
  /** The captcha handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The captcha name. */
  name: Maybe<Scalars['String']['output']>;
  /** The catpcha value. */
  value: Maybe<Scalars['String']['output']>;
};

export type FormieCsrfTokenType = {
  /** The CSRF name. */
  name: Maybe<Scalars['String']['output']>;
  /** The CSRF token. */
  value: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all link fields. */
export type HyperLinkInterface = {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type KeyValueType = {
  handle: Maybe<Scalars['String']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  width: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the matrix block. */
  fieldId: Scalars['Int']['output'];
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The ID of the primary owner of the Matrix block. */
  primaryOwnerId: Scalars['Int']['output'];
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the matrix block’s type. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the matrix block’s type. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type Mutation = {
  ping: Maybe<Scalars['String']['output']>;
  /** Save the “Contact Form” submission. */
  save_contactForm_Submission: Maybe<ContactForm_Submission>;
  /** Save the “CP's test form” submission. */
  save_cpsTestForm_Submission: Maybe<CpsTestForm_Submission>;
  /** Save the “Enquiry Form” submission. */
  save_enquiryForm_Submission: Maybe<EnquiryForm_Submission>;
  /** Save the “Games & interactive entertainment” submission. */
  save_gamesInteractiveEntertainment_Submission: Maybe<GamesInteractiveEntertainment_Submission>;
  /** Save the “Mailing List” submission. */
  save_mailingList_Submission: Maybe<MailingList_Submission>;
};


export type MutationSave_ContactForm_SubmissionArgs = {
  clickToReceiveMarketingUpdates: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  emailAddress: InputMaybe<Scalars['String']['input']>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  isIncomplete: InputMaybe<Scalars['Boolean']['input']>;
  message: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
  statusId: InputMaybe<Scalars['Int']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  uid: InputMaybe<Scalars['String']['input']>;
  yourName: InputMaybe<ContactForm_YourName_FormieNameInput>;
};


export type MutationSave_CpsTestForm_SubmissionArgs = {
  address: InputMaybe<CpsTestForm_Address_FormieAddressInput>;
  clickToReceiveMarketingUpdates: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  emailAddress: InputMaybe<Scalars['String']['input']>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  howDidYouHearAboutUs: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  isIncomplete: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  message: InputMaybe<Scalars['String']['input']>;
  recaptchaCaptcha: InputMaybe<FormieCaptchaInput>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
  statusId: InputMaybe<Scalars['Int']['input']>;
  subject: InputMaybe<Scalars['String']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  uid: InputMaybe<Scalars['String']['input']>;
  yourName: InputMaybe<CpsTestForm_YourName_FormieNameInput>;
};


export type MutationSave_EnquiryForm_SubmissionArgs = {
  address: InputMaybe<EnquiryForm_Address_FormieAddressInput>;
  clickToReceiveMarketingUpdates: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  emailAddress: InputMaybe<Scalars['String']['input']>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  howDidYouHearAboutUs: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  isIncomplete: InputMaybe<Scalars['Boolean']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  message: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
  statusId: InputMaybe<Scalars['Int']['input']>;
  subject: InputMaybe<Scalars['String']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  uid: InputMaybe<Scalars['String']['input']>;
  yourName: InputMaybe<EnquiryForm_YourName_FormieNameInput>;
};


export type MutationSave_GamesInteractiveEntertainment_SubmissionArgs = {
  areYouHappyForUsToSendYouMarketingUpda: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  emailAddress: InputMaybe<Scalars['String']['input']>;
  emailSubject: InputMaybe<Scalars['String']['input']>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  firstName: InputMaybe<Scalars['String']['input']>;
  howDidYouFindUs: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  isIncomplete: InputMaybe<Scalars['Boolean']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  phoneNumber1: InputMaybe<Scalars['String']['input']>;
  recaptchaCaptcha: InputMaybe<FormieCaptchaInput>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
  statusId: InputMaybe<Scalars['Int']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  uid: InputMaybe<Scalars['String']['input']>;
  yourEnquiry: InputMaybe<Scalars['String']['input']>;
};


export type MutationSave_MailingList_SubmissionArgs = {
  clickToReceiveMarketingUpdates: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  isIncomplete: InputMaybe<Scalars['Boolean']['input']>;
  name1: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
  statusId: InputMaybe<Scalars['Int']['input']>;
  title: InputMaybe<Scalars['String']['input']>;
  uid: InputMaybe<Scalars['String']['input']>;
};

/** This is the interface implemented by all Neo blocks. */
export type NeoBlockInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all Neo blocks. */
export type NeoBlockInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type NodeCustomAttribute = {
  attribute: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all nodes. */
export type NodeInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The node’s children. Accepts the same arguments as the `nodes` query. */
  children: Maybe<Array<Maybe<NodeInterface>>>;
  /** Any additional classes for the node. */
  classes: Maybe<Scalars['String']['output']>;
  /** Any additional custom attributes for the node. */
  customAttributes: Maybe<Array<Maybe<NodeCustomAttribute>>>;
  /** Any additional data for the node. */
  data: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The element the node links to. */
  element: Maybe<ElementInterface>;
  /** The ID of the element this node is linked to. */
  elementId: Maybe<Scalars['Int']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The handle of the navigation this node belongs to. */
  navHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the navigation this node belongs to. */
  navId: Maybe<Scalars['Int']['output']>;
  /** The name of the navigation this node belongs to. */
  navName: Maybe<Scalars['String']['output']>;
  /** Whether this node should open in a new window. */
  newWindow: Maybe<Scalars['String']['output']>;
  /** The node’s URI */
  nodeUri: Maybe<Scalars['String']['output']>;
  /** The node’s parent. */
  parent: Maybe<NodeInterface>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The type of node this is. */
  type: Maybe<Scalars['String']['output']>;
  /** The display name for the type of node this is. */
  typeLabel: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The node’s full URL */
  url: Maybe<Scalars['String']['output']>;
  /** The URL for this navigation item. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all nodes. */
export type NodeInterface_CountArgs = {
  field: Scalars['String']['input'];
};


/** This is the interface implemented by all nodes. */
export type NodeInterfaceChildrenArgs = {
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nav: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle: InputMaybe<Scalars['String']['input']>;
  navId: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all pages. */
export type PageInterface = {
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The page’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The page’s fields. */
  pageFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The page’s rows. */
  rows: Maybe<Array<Maybe<RowInterface>>>;
  /** The page’s settings, including buttons. */
  settings: Maybe<PageSettingsInterface>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all pages. */
export type PageInterfacePageFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all pages. */
export type PageSettingsInterface = {
  /** The page’s back button label. */
  backButtonLabel: Maybe<Scalars['String']['output']>;
  /** The page’s button (back and submit) positions. */
  buttonsPosition: Maybe<Scalars['String']['output']>;
  /** The page’s button (back and submit) container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The page’s button (back and submit) CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** Whether the page’s next button has conditions enabled, for multi-page forms. */
  enableNextButtonConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the page has conditions enabled. */
  enablePageConditions: Maybe<Scalars['Boolean']['output']>;
  /** The page’s button (back and submit) input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The page’s conditions for whether to show the next button, for multi-page forms as a JSON string. */
  nextButtonConditions: Maybe<Scalars['String']['output']>;
  /** The page’s conditions as a JSON string. */
  pageConditions: Maybe<Scalars['String']['output']>;
  /** The page’s draft button label. */
  saveButtonLabel: Maybe<Scalars['String']['output']>;
  /** Whether to show the page’s back button. */
  showBackButton: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the page’s draft button. */
  showSaveButton: Maybe<Scalars['Boolean']['output']>;
  /** The page’s submit button label. */
  submitButtonLabel: Maybe<Scalars['String']['output']>;
};

export type PageSettingsType = PageSettingsInterface & {
  /** The page’s back button label. */
  backButtonLabel: Maybe<Scalars['String']['output']>;
  /** The page’s button (back and submit) positions. */
  buttonsPosition: Maybe<Scalars['String']['output']>;
  /** The page’s button (back and submit) container attributes. */
  containerAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The page’s button (back and submit) CSS classes. */
  cssClasses: Maybe<Scalars['String']['output']>;
  /** Whether the page’s next button has conditions enabled, for multi-page forms. */
  enableNextButtonConditions: Maybe<Scalars['Boolean']['output']>;
  /** Whether the page has conditions enabled. */
  enablePageConditions: Maybe<Scalars['Boolean']['output']>;
  /** The page’s button (back and submit) input attributes. */
  inputAttributes: Maybe<Array<Maybe<FieldAttribute>>>;
  /** The page’s conditions for whether to show the next button, for multi-page forms as a JSON string. */
  nextButtonConditions: Maybe<Scalars['String']['output']>;
  /** The page’s conditions as a JSON string. */
  pageConditions: Maybe<Scalars['String']['output']>;
  /** The page’s draft button label. */
  saveButtonLabel: Maybe<Scalars['String']['output']>;
  /** Whether to show the page’s back button. */
  showBackButton: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the page’s draft button. */
  showSaveButton: Maybe<Scalars['Boolean']['output']>;
  /** The page’s submit button label. */
  submitButtonLabel: Maybe<Scalars['String']['output']>;
};

export type PageType = PageInterface & {
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The page’s name. */
  name: Maybe<Scalars['String']['output']>;
  /** The page’s fields. */
  pageFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The page’s rows. */
  rows: Maybe<Array<Maybe<RowInterface>>>;
  /** The page’s settings, including buttons. */
  settings: Maybe<PageSettingsInterface>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
};


export type PageTypePageFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};

export type Query = {
  /** Entries within the accordionEntries section. */
  accordionEntriesEntries: Maybe<Array<Maybe<AccordionEntriesSectionEntryUnion>>>;
  /** Entries within the accreditations section. */
  accreditationsEntries: Maybe<Array<Maybe<AccreditationsSectionEntryUnion>>>;
  /** This query is used to query for a single asset. */
  asset: Maybe<AssetInterface>;
  /** This query is used to return the number of assets. */
  assetCount: Scalars['Int']['output'];
  /** This query is used to query for assets. */
  assets: Maybe<Array<Maybe<AssetInterface>>>;
  /** Entries within the careers section. */
  careersEntries: Maybe<Array<Maybe<CareersSectionEntryUnion>>>;
  /** Entries within the caseHighlights section. */
  caseHighlightsEntries: Maybe<Array<Maybe<CaseHighlightsSectionEntryUnion>>>;
  /** Entries within the ctas section. */
  ctasEntries: Maybe<Array<Maybe<CtasSectionEntryUnion>>>;
  /** Entries within the detailedCtas section. */
  detailedCtasEntries: Maybe<Array<Maybe<DetailedCtasSectionEntryUnion>>>;
  /** This query is used to query for entries. */
  entries: Maybe<Array<Maybe<EntryInterface>>>;
  /** This query is used to query for a single entry. */
  entry: Maybe<EntryInterface>;
  /** This query is used to return the number of entries. */
  entryCount: Scalars['Int']['output'];
  /** Entries within the events section. */
  eventsEntries: Maybe<Array<Maybe<EventsSectionEntryUnion>>>;
  /** This query is used to query for a single form. */
  formieForm: Maybe<FormInterface>;
  /** This query is used to return the number of forms. */
  formieFormCount: Scalars['Int']['output'];
  /** This query is used to query for forms. */
  formieForms: Maybe<Array<Maybe<FormInterface>>>;
  /** Entries within the general section. */
  generalEntries: Maybe<Array<Maybe<GeneralSectionEntryUnion>>>;
  /** Entries within the home section. */
  homeEntries: Maybe<Array<Maybe<HomeSectionEntryUnion>>>;
  /** Entries within the insightCategories section. */
  insightCategoriesEntries: Maybe<Array<Maybe<InsightCategoriesSectionEntryUnion>>>;
  /** Entries within the insights section. */
  insightsEntries: Maybe<Array<Maybe<InsightsSectionEntryUnion>>>;
  /** Entries within the insightsIndex section. */
  insightsIndexEntries: Maybe<Array<Maybe<InsightsIndexSectionEntryUnion>>>;
  /** Entries within the mapMarkers section. */
  mapMarkersEntries: Maybe<Array<Maybe<MapMarkersSectionEntryUnion>>>;
  /** This query is used to query for a single node. */
  navigationNode: Maybe<NodeInterface>;
  /** This query is used to query for nodes. */
  navigationNodes: Maybe<Array<Maybe<NodeInterface>>>;
  /** Entries within the offices section. */
  officesEntries: Maybe<Array<Maybe<OfficesSectionEntryUnion>>>;
  /** Entries within the people section. */
  peopleEntries: Maybe<Array<Maybe<PeopleSectionEntryUnion>>>;
  /** Entries within the peopleIndex section. */
  peopleIndexEntries: Maybe<Array<Maybe<PeopleIndexSectionEntryUnion>>>;
  ping: Maybe<Scalars['String']['output']>;
  /**
   * This query is used to query for Retour redirects.
   * @deprecated This query is deprecated and will be removed in the future. You should use `retourResolveRedirect` instead.
   */
  retour: Maybe<RetourInterface>;
  /** This query is used to query for all Retour redirects for a site. */
  retourRedirects: Maybe<Array<Maybe<RetourInterface>>>;
  /** This query is used to query for Retour redirects. */
  retourResolveRedirect: Maybe<RetourInterface>;
  /** Entries within the sectors section. */
  sectorsEntries: Maybe<Array<Maybe<SectorsSectionEntryUnion>>>;
  /** Entries within the sectorsIndex section. */
  sectorsIndexEntries: Maybe<Array<Maybe<SectorsIndexSectionEntryUnion>>>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** Entries within the services section. */
  servicesEntries: Maybe<Array<Maybe<ServicesSectionEntryUnion>>>;
  /** Entries within the servicesIndex section. */
  servicesIndexEntries: Maybe<Array<Maybe<ServicesIndexSectionEntryUnion>>>;
  /** Entries within the siteNotice section. */
  siteNoticeEntries: Maybe<Array<Maybe<SiteNoticeSectionEntryUnion>>>;
  /** Entries within the statistics section. */
  statisticsEntries: Maybe<Array<Maybe<StatisticsSectionEntryUnion>>>;
  /** This query is used to query for a single tag. */
  tag: Maybe<TagInterface>;
  /** This query is used to return the number of tags. */
  tagCount: Scalars['Int']['output'];
  /** This query is used to query for tags. */
  tags: Maybe<Array<Maybe<TagInterface>>>;
  /** Entries within the testimonials section. */
  testimonialsEntries: Maybe<Array<Maybe<TestimonialsSectionEntryUnion>>>;
  /** Entries within the usps section. */
  uspsEntries: Maybe<Array<Maybe<UspsSectionEntryUnion>>>;
};


export type QueryAccordionEntriesEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAccreditationsEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAssetArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAssetCountArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAssetsArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCareersEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCaseHighlightsEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCtasEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDetailedCtasEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEntryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEntryCountArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEventsEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryFormieFormArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  handle: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFormieFormCountArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  handle: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFormieFormsArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  handle: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGeneralEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryHomeEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryInsightCategoriesEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryInsightsEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryInsightsIndexEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMapMarkersEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNavigationNodeArgs = {
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nav: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle: InputMaybe<Scalars['String']['input']>;
  navId: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNavigationNodesArgs = {
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nav: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle: InputMaybe<Scalars['String']['input']>;
  navId: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOfficesEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPeopleEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPeopleIndexEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRetourArgs = {
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};


export type QueryRetourRedirectsArgs = {
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRetourResolveRedirectArgs = {
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};


export type QuerySectorsEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySectorsIndexEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};


export type QueryServicesEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryServicesIndexEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySiteNoticeEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryStatisticsEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTagArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  group: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTagCountArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  group: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTagsArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  group: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTestimonialsEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryUspsEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by Retour. */
export type RetourInterface = {
  /** The id of the Element associated with this redirect (unused/vestigial). */
  associatedElementId: Maybe<Scalars['Int']['output']>;
  /** Whether the redirect is enabled or not. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The number of times this redirect has been hit. */
  hitCount: Maybe<Scalars['Int']['output']>;
  /** A datetime string of when this redirect was last hit. */
  hitLastTime: Maybe<Scalars['String']['output']>;
  /** The id of the redirect. */
  id: Maybe<Scalars['Int']['output']>;
  /** The URL that should be redirected to. */
  redirectDestUrl: Maybe<Scalars['String']['output']>;
  /** The http status code that should be used for the redirect. */
  redirectHttpCode: Maybe<Scalars['Int']['output']>;
  /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
  redirectMatchType: Maybe<Scalars['String']['output']>;
  /** Should the legacy URL be matched by path or by full URL? */
  redirectSrcMatch: Maybe<Scalars['String']['output']>;
  /** The unparsed URL pattern that Retour should match. */
  redirectSrcUrl: Maybe<Scalars['String']['output']>;
  /** The parsed URL pattern that Retour should match. */
  redirectSrcUrlParsed: Maybe<Scalars['String']['output']>;
  /** The site handle of the redirect (or null for all sites). */
  site: Maybe<Scalars['String']['output']>;
  /** The siteId of the redirect (0 or null for all sites). */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
};

/** This entity has all the Retour fields */
export type RetourType = RetourInterface & {
  /** The id of the Element associated with this redirect (unused/vestigial). */
  associatedElementId: Maybe<Scalars['Int']['output']>;
  /** Whether the redirect is enabled or not. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The number of times this redirect has been hit. */
  hitCount: Maybe<Scalars['Int']['output']>;
  /** A datetime string of when this redirect was last hit. */
  hitLastTime: Maybe<Scalars['String']['output']>;
  /** The id of the redirect. */
  id: Maybe<Scalars['Int']['output']>;
  /** The URL that should be redirected to. */
  redirectDestUrl: Maybe<Scalars['String']['output']>;
  /** The http status code that should be used for the redirect. */
  redirectHttpCode: Maybe<Scalars['Int']['output']>;
  /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
  redirectMatchType: Maybe<Scalars['String']['output']>;
  /** Should the legacy URL be matched by path or by full URL? */
  redirectSrcMatch: Maybe<Scalars['String']['output']>;
  /** The unparsed URL pattern that Retour should match. */
  redirectSrcUrl: Maybe<Scalars['String']['output']>;
  /** The parsed URL pattern that Retour should match. */
  redirectSrcUrlParsed: Maybe<Scalars['String']['output']>;
  /** The site handle of the redirect (or null for all sites). */
  site: Maybe<Scalars['String']['output']>;
  /** The siteId of the redirect (0 or null for all sites). */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
};

/** This is the interface implemented by all rows. */
export type RowInterface = {
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The row’s fields. */
  rowFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all rows. */
export type RowInterfaceRowFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};

export type RowType = RowInterface & {
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The row’s fields. */
  rowFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
};


export type RowTypeRowFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};

/** Optional - The SEOmatic environment that should be used */
export enum SeomaticEnvironment {
  /** Live production environment, with indexing enabled */
  Live = 'live',
  /** Local Development environment, with debugging enabled and indexing disabled */
  Local = 'local',
  /** Staging environment, with indexing disabled */
  Staging = 'staging'
}

export enum SeomaticFrontendTemplate {
  /** The ads.txt file */
  Ads = 'ads',
  /** The humans.txt file */
  Humans = 'humans',
  /** The robots.txt file */
  Robots = 'robots',
  /** The security.txt file */
  Security = 'security'
}

/** This is the interface implemented by SEOmatic. */
export type SeomaticInterface = {
  /**
   * The FrontendTemplateContainer SEOmatic container.
   * @deprecated This query is deprecated and will be removed in the future. You should use `frontendTemplates` instead.
   */
  frontendTemplateContainer: Maybe<Scalars['String']['output']>;
  frontendTemplates: Maybe<Array<Maybe<FileContents>>>;
  /** The MetaJsonLdContainer SEOmatic container. */
  metaJsonLdContainer: Maybe<Scalars['String']['output']>;
  /** The MetaLinkContainer SEOmatic container. */
  metaLinkContainer: Maybe<Scalars['String']['output']>;
  /** The MetaScriptContainer SEOmatic container. */
  metaScriptContainer: Maybe<Scalars['String']['output']>;
  /** The MetaSiteVarsContainer SEOmatic container. */
  metaSiteVarsContainer: Maybe<Scalars['String']['output']>;
  /** The MetaTagContainer SEOmatic container. */
  metaTagContainer: Maybe<Scalars['String']['output']>;
  /** The MetaTitleContainer SEOmatic container. */
  metaTitleContainer: Maybe<Scalars['String']['output']>;
  sitemapIndexes: Maybe<Array<Maybe<FileContents>>>;
  sitemapStyles: Maybe<FileContents>;
  sitemaps: Maybe<Array<Maybe<FileContents>>>;
};


/** This is the interface implemented by SEOmatic. */
export type SeomaticInterfaceFrontendTemplatesArgs = {
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  type: InputMaybe<SeomaticFrontendTemplate>;
};


/** This is the interface implemented by SEOmatic. */
export type SeomaticInterfaceSitemapIndexesArgs = {
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
};


/** This is the interface implemented by SEOmatic. */
export type SeomaticInterfaceSitemapsArgs = {
  filename: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  sourceBundleHandle: InputMaybe<Scalars['String']['input']>;
  sourceBundleType: InputMaybe<Scalars['String']['input']>;
};

/** This entity has all the SEOmatic fields */
export type SeomaticType = SeomaticInterface & {
  /**
   * The FrontendTemplateContainer SEOmatic container.
   * @deprecated This query is deprecated and will be removed in the future. You should use `frontendTemplates` instead.
   */
  frontendTemplateContainer: Maybe<Scalars['String']['output']>;
  frontendTemplates: Maybe<Array<Maybe<FileContents>>>;
  /** The MetaJsonLdContainer SEOmatic container. */
  metaJsonLdContainer: Maybe<Scalars['String']['output']>;
  /** The MetaLinkContainer SEOmatic container. */
  metaLinkContainer: Maybe<Scalars['String']['output']>;
  /** The MetaScriptContainer SEOmatic container. */
  metaScriptContainer: Maybe<Scalars['String']['output']>;
  /** The MetaSiteVarsContainer SEOmatic container. */
  metaSiteVarsContainer: Maybe<Scalars['String']['output']>;
  /** The MetaTagContainer SEOmatic container. */
  metaTagContainer: Maybe<Scalars['String']['output']>;
  /** The MetaTitleContainer SEOmatic container. */
  metaTitleContainer: Maybe<Scalars['String']['output']>;
  sitemapIndexes: Maybe<Array<Maybe<FileContents>>>;
  sitemapStyles: Maybe<FileContents>;
  sitemaps: Maybe<Array<Maybe<FileContents>>>;
};


/** This entity has all the SEOmatic fields */
export type SeomaticTypeFrontendTemplatesArgs = {
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  type: InputMaybe<SeomaticFrontendTemplate>;
};


/** This entity has all the SEOmatic fields */
export type SeomaticTypeSitemapIndexesArgs = {
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
};


/** This entity has all the SEOmatic fields */
export type SeomaticTypeSitemapsArgs = {
  filename: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  sourceBundleHandle: InputMaybe<Scalars['String']['input']>;
  sourceBundleType: InputMaybe<Scalars['String']['input']>;
};

/** This is the interface implemented by all submissions. */
export type SubmissionInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The submission’s IP Address. */
  ipAddress: Maybe<Scalars['String']['output']>;
  /** Whether the submission is incomplete. */
  isIncomplete: Maybe<Scalars['Boolean']['output']>;
  /** Whether the submission is spam. */
  isSpam: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The submission’s spam type. */
  spamClass: Maybe<Scalars['String']['output']>;
  /** The submission’s spam reason. */
  spamReason: Maybe<Scalars['String']['output']>;
  /** The submission’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The submission’s status ID. */
  statusId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all submissions. */
export type SubmissionInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type TagCriteriaInput = {
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the tag groups the tags belong to per the group’s handles. */
  group: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the tag groups the tags belong to, per the groups’ IDs. */
  groupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ IDs. */
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Sets the limit for paginated results. */
  limit: InputMaybe<Scalars['Int']['input']>;
  /** Sets the offset for paginated results. */
  offset: InputMaybe<Scalars['Int']['input']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy: InputMaybe<Scalars['String']['input']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on a reference string. */
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is the interface implemented by all tags. */
export type TagInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the group that contains the tag. */
  groupHandle: Scalars['String']['output'];
  /** The ID of the group that contains the tag. */
  groupId: Scalars['Int']['output'];
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all tags. */
export type TagInterface_CountArgs = {
  field: Scalars['String']['input'];
};

export type User = ElementInterface & UserInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The user’s addresses. */
  addresses: Maybe<Array<Maybe<AddressInterface>>>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The user’s email. */
  email: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The user’s first name. */
  firstName: Maybe<Scalars['String']['output']>;
  /** The user’s first name or username. */
  friendlyName: Maybe<Scalars['String']['output']>;
  /** The user’s full name. */
  fullName: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The user’s last name. */
  lastName: Maybe<Scalars['String']['output']>;
  /** The user’s full name or username. */
  name: Scalars['String']['output'];
  /** The user’s photo. */
  photo: Maybe<AssetInterface>;
  /** The user’s preferences. */
  preferences: Scalars['String']['output'];
  /** The user’s preferred language. */
  preferredLanguage: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The username. */
  username: Maybe<Scalars['String']['output']>;
};


export type User_CountArgs = {
  field: Scalars['String']['input'];
};


export type UserAddressesArgs = {
  administrativeArea: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryCode: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  ownerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserCriteriaInput = {
  /** Narrows the query results based on whether the users have uploaded any assets. */
  assetUploaders: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on whether the users are listed as the author of any entries. */
  authors: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the users’ email addresses. */
  email: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the users’ first names. */
  firstName: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the users’ full names. */
  fullName: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the user group the users belong to. */
  group: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the user group the users belong to, per the groups’ IDs. */
  groupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to only users that have (or don’t have) a user photo. */
  hasPhoto: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ IDs. */
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  /** Determines which site(s) the elements should be queried in, based on their language. */
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the users’ last names. */
  lastName: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Sets the limit for paginated results. */
  limit: InputMaybe<Scalars['Int']['input']>;
  /** Sets the offset for paginated results. */
  offset: InputMaybe<Scalars['Int']['input']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy: InputMaybe<Scalars['String']['input']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on a reference string. */
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search: InputMaybe<Scalars['String']['input']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique: InputMaybe<Scalars['Boolean']['input']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Narrows the query results based on the users’ usernames. */
  username: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** This is the interface implemented by all users. */
export type UserInterface = {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The user’s addresses. */
  addresses: Maybe<Array<Maybe<AddressInterface>>>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The user’s email. */
  email: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The user’s first name. */
  firstName: Maybe<Scalars['String']['output']>;
  /** The user’s first name or username. */
  friendlyName: Maybe<Scalars['String']['output']>;
  /** The user’s full name. */
  fullName: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The user’s last name. */
  lastName: Maybe<Scalars['String']['output']>;
  /** The user’s full name or username. */
  name: Scalars['String']['output'];
  /** The user’s photo. */
  photo: Maybe<AssetInterface>;
  /** The user’s preferences. */
  preferences: Scalars['String']['output'];
  /** The user’s preferred language. */
  preferredLanguage: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The username. */
  username: Maybe<Scalars['String']['output']>;
};


/** This is the interface implemented by all users. */
export type UserInterface_CountArgs = {
  field: Scalars['String']['input'];
};


/** This is the interface implemented by all users. */
export type UserInterfaceAddressesArgs = {
  administrativeArea: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  countryCode: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  ownerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AccordionEntriesSectionEntryUnion = AccordionEntries_Default_Entry;

export type AccordionEntries_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type AccordionEntries_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type AccordionEntries_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type AccordionEntries_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type AccordionEntries_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type AccordionEntries_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type AccordionEntries_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type AccordionEntries_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type AccordionEntries_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type AccordionEntries_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type AccreditationsSectionEntryUnion = Accreditations_Default_Entry;

export type Accreditations_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Accreditations_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Accreditations_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Accreditations_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Accreditations_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Accreditations_Default_EntryImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Accreditations_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Accreditations_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Accreditations_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Accreditations_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Accreditations_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type CareersSectionEntryUnion = Careers_Default_Entry;

export type Careers_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Careers_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Careers_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Careers_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Careers_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Careers_Default_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Careers_Default_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Careers_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Careers_Default_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Careers_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Careers_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Careers_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Careers_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type CaseHighlightsSectionEntryUnion = CaseHighlights_Default_Entry;

export type CaseHighlights_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  entry: Array<Maybe<EntryInterface>>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  multiLineText: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type CaseHighlights_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type CaseHighlights_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type CaseHighlights_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type CaseHighlights_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type CaseHighlights_Default_EntryEntryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type CaseHighlights_Default_EntryImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CaseHighlights_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type CaseHighlights_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type CaseHighlights_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type CaseHighlights_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type CaseHighlights_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type ContactForm_Form = ElementInterface & FormInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** A list of captcha values (name and value) to assist with spam protection. */
  captchas: Maybe<Array<Maybe<FormieCaptchaType>>>;
  /** The form’s config as JSON. */
  configJson: Maybe<Scalars['String']['output']>;
  /** A CSRF token (name and value). */
  csrfToken: Maybe<FormieCsrfTokenType>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The form’s fields. */
  formFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The form’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the form is considered available according to user checks, scheduling and more. */
  isAvailable: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The form’s pages. */
  pages: Maybe<Array<Maybe<PageInterface>>>;
  /** The form’s rows. */
  rows: Maybe<Array<Maybe<RowInterface>>>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The form’s settings. */
  settings: Maybe<FormSettingsType>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The form’s endpoint for sending submissions to, if using POST requests. */
  submissionEndpoint: Maybe<Scalars['String']['output']>;
  /** The form’s GQL mutation name for submissions to use. */
  submissionMutationName: Maybe<Scalars['String']['output']>;
  /** The form’s rendered HTML. */
  templateHtml: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type ContactForm_Form_CountArgs = {
  field: Scalars['String']['input'];
};


export type ContactForm_FormFormFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactForm_FormTemplateHtmlArgs = {
  options: InputMaybe<Scalars['String']['input']>;
  populateFormValues: InputMaybe<Scalars['String']['input']>;
};

export type ContactForm_Submission = ElementInterface & SubmissionInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  clickToReceiveMarketingUpdates: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  email: Maybe<Scalars['String']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The submission’s IP Address. */
  ipAddress: Maybe<Scalars['String']['output']>;
  /** Whether the submission is incomplete. */
  isIncomplete: Maybe<Scalars['Boolean']['output']>;
  /** Whether the submission is spam. */
  isSpam: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The submission’s spam type. */
  spamClass: Maybe<Scalars['String']['output']>;
  /** The submission’s spam reason. */
  spamReason: Maybe<Scalars['String']['output']>;
  /** The submission’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The submission’s status ID. */
  statusId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  yourName: Maybe<Scalars['String']['output']>;
};


export type ContactForm_Submission_CountArgs = {
  field: Scalars['String']['input'];
};

export type ContactForm_YourName_FormieNameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CpsTestForm_Form = ElementInterface & FormInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** A list of captcha values (name and value) to assist with spam protection. */
  captchas: Maybe<Array<Maybe<FormieCaptchaType>>>;
  /** The form’s config as JSON. */
  configJson: Maybe<Scalars['String']['output']>;
  /** A CSRF token (name and value). */
  csrfToken: Maybe<FormieCsrfTokenType>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The form’s fields. */
  formFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The form’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the form is considered available according to user checks, scheduling and more. */
  isAvailable: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The form’s pages. */
  pages: Maybe<Array<Maybe<PageInterface>>>;
  /** The form’s rows. */
  rows: Maybe<Array<Maybe<RowInterface>>>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The form’s settings. */
  settings: Maybe<FormSettingsType>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The form’s endpoint for sending submissions to, if using POST requests. */
  submissionEndpoint: Maybe<Scalars['String']['output']>;
  /** The form’s GQL mutation name for submissions to use. */
  submissionMutationName: Maybe<Scalars['String']['output']>;
  /** The form’s rendered HTML. */
  templateHtml: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type CpsTestForm_Form_CountArgs = {
  field: Scalars['String']['input'];
};


export type CpsTestForm_FormFormFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};


export type CpsTestForm_FormTemplateHtmlArgs = {
  options: InputMaybe<Scalars['String']['input']>;
  populateFormValues: InputMaybe<Scalars['String']['input']>;
};

export type CpsTestForm_Submission = ElementInterface & SubmissionInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  address: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  clickToReceiveMarketingUpdates: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  email: Maybe<Scalars['String']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  howDidYouHearAboutUs: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The submission’s IP Address. */
  ipAddress: Maybe<Scalars['String']['output']>;
  /** Whether the submission is incomplete. */
  isIncomplete: Maybe<Scalars['Boolean']['output']>;
  /** Whether the submission is spam. */
  isSpam: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The submission’s spam type. */
  spamClass: Maybe<Scalars['String']['output']>;
  /** The submission’s spam reason. */
  spamReason: Maybe<Scalars['String']['output']>;
  /** The submission’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The submission’s status ID. */
  statusId: Maybe<Scalars['Int']['output']>;
  subject: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  yourName: Maybe<Scalars['String']['output']>;
};


export type CpsTestForm_Submission_CountArgs = {
  field: Scalars['String']['input'];
};


export type CpsTestForm_SubmissionHowDidYouHearAboutUsArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type CpsTestForm_Address_FormieAddressInput = {
  address1: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  zip: InputMaybe<Scalars['String']['input']>;
};

export type CpsTestForm_YourName_FormieNameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  prefix: InputMaybe<Scalars['String']['input']>;
};

export type CtasSectionEntryUnion = Ctas_Default_Entry;

export type Ctas_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Ctas_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Ctas_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Ctas_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Ctas_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Ctas_Default_EntryHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Ctas_Default_EntryImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Ctas_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Ctas_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Ctas_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Ctas_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Ctas_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type DetailedCtasSectionEntryUnion = DetailedCtas_Default_Entry;

export type DetailedCtas_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type DetailedCtas_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type DetailedCtas_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type DetailedCtas_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type DetailedCtas_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type DetailedCtas_Default_EntryHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type DetailedCtas_Default_EntryImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type DetailedCtas_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type DetailedCtas_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type DetailedCtas_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type DetailedCtas_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type DetailedCtas_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryForm_Form = ElementInterface & FormInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** A list of captcha values (name and value) to assist with spam protection. */
  captchas: Maybe<Array<Maybe<FormieCaptchaType>>>;
  /** The form’s config as JSON. */
  configJson: Maybe<Scalars['String']['output']>;
  /** A CSRF token (name and value). */
  csrfToken: Maybe<FormieCsrfTokenType>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The form’s fields. */
  formFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The form’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the form is considered available according to user checks, scheduling and more. */
  isAvailable: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The form’s pages. */
  pages: Maybe<Array<Maybe<PageInterface>>>;
  /** The form’s rows. */
  rows: Maybe<Array<Maybe<RowInterface>>>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The form’s settings. */
  settings: Maybe<FormSettingsType>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The form’s endpoint for sending submissions to, if using POST requests. */
  submissionEndpoint: Maybe<Scalars['String']['output']>;
  /** The form’s GQL mutation name for submissions to use. */
  submissionMutationName: Maybe<Scalars['String']['output']>;
  /** The form’s rendered HTML. */
  templateHtml: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type EnquiryForm_Form_CountArgs = {
  field: Scalars['String']['input'];
};


export type EnquiryForm_FormFormFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};


export type EnquiryForm_FormTemplateHtmlArgs = {
  options: InputMaybe<Scalars['String']['input']>;
  populateFormValues: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryForm_Submission = ElementInterface & SubmissionInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  address: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  clickToReceiveMarketingUpdates: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  email: Maybe<Scalars['String']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  howDidYouHearAboutUs: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The submission’s IP Address. */
  ipAddress: Maybe<Scalars['String']['output']>;
  /** Whether the submission is incomplete. */
  isIncomplete: Maybe<Scalars['Boolean']['output']>;
  /** Whether the submission is spam. */
  isSpam: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The submission’s spam type. */
  spamClass: Maybe<Scalars['String']['output']>;
  /** The submission’s spam reason. */
  spamReason: Maybe<Scalars['String']['output']>;
  /** The submission’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The submission’s status ID. */
  statusId: Maybe<Scalars['Int']['output']>;
  subject: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  yourName: Maybe<Scalars['String']['output']>;
};


export type EnquiryForm_Submission_CountArgs = {
  field: Scalars['String']['input'];
};


export type EnquiryForm_SubmissionHowDidYouHearAboutUsArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnquiryForm_Address_FormieAddressInput = {
  address1: InputMaybe<Scalars['String']['input']>;
  city: InputMaybe<Scalars['String']['input']>;
  country: InputMaybe<Scalars['String']['input']>;
  state: InputMaybe<Scalars['String']['input']>;
  zip: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryForm_YourName_FormieNameInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  prefix: InputMaybe<Scalars['String']['input']>;
};

export type EventsSectionEntryUnion = Events_Default_Entry;

export type Events_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexHideEventDate: Maybe<Scalars['Boolean']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Events_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Events_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Events_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Events_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Events_Default_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Events_Default_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Events_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Events_Default_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Events_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Events_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Events_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Events_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type FooterPrimaryNavigation_Node = ElementInterface & NodeInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The node’s children. Accepts the same arguments as the `nodes` query. */
  children: Maybe<Array<Maybe<NodeInterface>>>;
  /** Any additional classes for the node. */
  classes: Maybe<Scalars['String']['output']>;
  /** Any additional custom attributes for the node. */
  customAttributes: Maybe<Array<Maybe<NodeCustomAttribute>>>;
  /** Any additional data for the node. */
  data: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The element the node links to. */
  element: Maybe<ElementInterface>;
  /** The ID of the element this node is linked to. */
  elementId: Maybe<Scalars['Int']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The handle of the navigation this node belongs to. */
  navHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the navigation this node belongs to. */
  navId: Maybe<Scalars['Int']['output']>;
  /** The name of the navigation this node belongs to. */
  navName: Maybe<Scalars['String']['output']>;
  /** Whether this node should open in a new window. */
  newWindow: Maybe<Scalars['String']['output']>;
  /** The node’s URI */
  nodeUri: Maybe<Scalars['String']['output']>;
  /** The node’s parent. */
  parent: Maybe<NodeInterface>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The type of node this is. */
  type: Maybe<Scalars['String']['output']>;
  /** The display name for the type of node this is. */
  typeLabel: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The node’s full URL */
  url: Maybe<Scalars['String']['output']>;
  /** The URL for this navigation item. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};


export type FooterPrimaryNavigation_Node_CountArgs = {
  field: Scalars['String']['input'];
};


export type FooterPrimaryNavigation_NodeChildrenArgs = {
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nav: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle: InputMaybe<Scalars['String']['input']>;
  navId: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

export type FooterSecondaryNavigation_Node = ElementInterface & NodeInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The node’s children. Accepts the same arguments as the `nodes` query. */
  children: Maybe<Array<Maybe<NodeInterface>>>;
  /** Any additional classes for the node. */
  classes: Maybe<Scalars['String']['output']>;
  /** Any additional custom attributes for the node. */
  customAttributes: Maybe<Array<Maybe<NodeCustomAttribute>>>;
  /** Any additional data for the node. */
  data: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The element the node links to. */
  element: Maybe<ElementInterface>;
  /** The ID of the element this node is linked to. */
  elementId: Maybe<Scalars['Int']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The handle of the navigation this node belongs to. */
  navHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the navigation this node belongs to. */
  navId: Maybe<Scalars['Int']['output']>;
  /** The name of the navigation this node belongs to. */
  navName: Maybe<Scalars['String']['output']>;
  /** Whether this node should open in a new window. */
  newWindow: Maybe<Scalars['String']['output']>;
  /** The node’s URI */
  nodeUri: Maybe<Scalars['String']['output']>;
  /** The node’s parent. */
  parent: Maybe<NodeInterface>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The type of node this is. */
  type: Maybe<Scalars['String']['output']>;
  /** The display name for the type of node this is. */
  typeLabel: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The node’s full URL */
  url: Maybe<Scalars['String']['output']>;
  /** The URL for this navigation item. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};


export type FooterSecondaryNavigation_Node_CountArgs = {
  field: Scalars['String']['input'];
};


export type FooterSecondaryNavigation_NodeChildrenArgs = {
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nav: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle: InputMaybe<Scalars['String']['input']>;
  navId: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

export type GamesInteractiveEntertainment_Form = ElementInterface & FormInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** A list of captcha values (name and value) to assist with spam protection. */
  captchas: Maybe<Array<Maybe<FormieCaptchaType>>>;
  /** The form’s config as JSON. */
  configJson: Maybe<Scalars['String']['output']>;
  /** A CSRF token (name and value). */
  csrfToken: Maybe<FormieCsrfTokenType>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The form’s fields. */
  formFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The form’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the form is considered available according to user checks, scheduling and more. */
  isAvailable: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The form’s pages. */
  pages: Maybe<Array<Maybe<PageInterface>>>;
  /** The form’s rows. */
  rows: Maybe<Array<Maybe<RowInterface>>>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The form’s settings. */
  settings: Maybe<FormSettingsType>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The form’s endpoint for sending submissions to, if using POST requests. */
  submissionEndpoint: Maybe<Scalars['String']['output']>;
  /** The form’s GQL mutation name for submissions to use. */
  submissionMutationName: Maybe<Scalars['String']['output']>;
  /** The form’s rendered HTML. */
  templateHtml: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type GamesInteractiveEntertainment_Form_CountArgs = {
  field: Scalars['String']['input'];
};


export type GamesInteractiveEntertainment_FormFormFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};


export type GamesInteractiveEntertainment_FormTemplateHtmlArgs = {
  options: InputMaybe<Scalars['String']['input']>;
  populateFormValues: InputMaybe<Scalars['String']['input']>;
};

export type GamesInteractiveEntertainment_Submission = ElementInterface & SubmissionInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  areYouHappyForUsToSendYouMarketingUpda: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  emailSubject: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  howDidYouFindUs: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The submission’s IP Address. */
  ipAddress: Maybe<Scalars['String']['output']>;
  /** Whether the submission is incomplete. */
  isIncomplete: Maybe<Scalars['Boolean']['output']>;
  /** Whether the submission is spam. */
  isSpam: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  phoneNumber1: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The submission’s spam type. */
  spamClass: Maybe<Scalars['String']['output']>;
  /** The submission’s spam reason. */
  spamReason: Maybe<Scalars['String']['output']>;
  /** The submission’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The submission’s status ID. */
  statusId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  yourEnquiry: Maybe<Scalars['String']['output']>;
};


export type GamesInteractiveEntertainment_Submission_CountArgs = {
  field: Scalars['String']['input'];
};


export type GamesInteractiveEntertainment_SubmissionAreYouHappyForUsToSendYouMarketingUpdaArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type GamesInteractiveEntertainment_SubmissionHowDidYouFindUsArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type GeneralSectionEntryUnion = General_Default_Entry | General_MirroredEntry_Entry;

export type General_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type General_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type General_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_Default_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type General_Default_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type General_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_Default_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type General_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type General_MirroredEntry_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  mirroredEntry: Array<Maybe<EntryInterface>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type General_MirroredEntry_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type General_MirroredEntry_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_MirroredEntry_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_MirroredEntry_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_MirroredEntry_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_MirroredEntry_EntryMirroredEntryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_MirroredEntry_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_MirroredEntry_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_MirroredEntry_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type General_MirroredEntry_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type Hero_NeoField = Hero_ArticleHeader_BlockType | Hero_BaseHero_BlockType | Hero_FeatureHero_BlockType | Hero_ImageHero_BlockType | Hero_PersonHeader_BlockType | Hero_SplitHero_BlockType | Hero_TextHero_BlockType;

export type Hero_ArticleHeader_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  singleLineText: Maybe<Scalars['String']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Hero_ArticleHeader_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Hero_ArticleHeader_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Hero_BaseHero_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  multiLineText: Maybe<Scalars['String']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  useH1: Maybe<Scalars['Boolean']['output']>;
};


export type Hero_BaseHero_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Hero_BaseHero_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Hero_FeatureHero_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  featureImage: Array<Maybe<AssetInterface>>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  multiLineText: Maybe<Scalars['String']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  useH1: Maybe<Scalars['Boolean']['output']>;
};


export type Hero_FeatureHero_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Hero_FeatureHero_BlockTypeFeatureImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Hero_ImageHero_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  mobileImage: Array<Maybe<AssetInterface>>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  useH1: Maybe<Scalars['Boolean']['output']>;
};


export type Hero_ImageHero_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Hero_ImageHero_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Hero_ImageHero_BlockTypeMobileImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Hero_PersonHeader_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Hero_PersonHeader_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Hero_PersonHeader_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Hero_SplitHero_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  heroColourway: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  multiLineText: Maybe<Scalars['String']['output']>;
  pattern: Maybe<Scalars['String']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  showSocialLinks: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  useH1: Maybe<Scalars['Boolean']['output']>;
};


export type Hero_SplitHero_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Hero_SplitHero_BlockTypeHeroColourwayArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Hero_SplitHero_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Hero_SplitHero_BlockTypePatternArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Hero_TextHero_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  heroAccreditations: Array<Maybe<EntryInterface>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  singleLineText: Maybe<Scalars['String']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  textHeroColourway: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  useH1: Maybe<Scalars['Boolean']['output']>;
  useLogoAsHeading: Maybe<Scalars['Boolean']['output']>;
};


export type Hero_TextHero_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Hero_TextHero_BlockTypeHeroAccreditationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Hero_TextHero_BlockTypeTextHeroColourwayArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type HomeSectionEntryUnion = Home_Home_Entry;

export type Home_Home_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Home_Home_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Home_Home_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Home_Home_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Home_Home_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Home_Home_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Home_Home_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Home_Home_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Home_Home_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Home_Home_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Home_Home_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Home_Home_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Home_Home_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type InsightCategoriesSectionEntryUnion = InsightCategories_Default_Entry;

export type InsightCategories_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type InsightCategories_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type InsightCategories_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightCategories_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightCategories_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightCategories_Default_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type InsightCategories_Default_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type InsightCategories_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightCategories_Default_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type InsightCategories_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightCategories_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightCategories_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightCategories_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type InsightsIndexSectionEntryUnion = InsightsIndex_InsightsIndex_Entry;

export type InsightsIndex_InsightsIndex_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type InsightsIndex_InsightsIndex_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type InsightsIndex_InsightsIndex_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightsIndex_InsightsIndex_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightsIndex_InsightsIndex_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightsIndex_InsightsIndex_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type InsightsIndex_InsightsIndex_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type InsightsIndex_InsightsIndex_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightsIndex_InsightsIndex_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type InsightsIndex_InsightsIndex_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightsIndex_InsightsIndex_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightsIndex_InsightsIndex_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type InsightsIndex_InsightsIndex_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type InsightsSectionEntryUnion = Insights_Default_Entry;

export type Insights_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  enableRelatedInsights: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  insightCategories: Array<Maybe<EntryInterface>>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  people: Array<Maybe<EntryInterface>>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Insights_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Insights_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Insights_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Insights_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Insights_Default_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Insights_Default_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Insights_Default_EntryInsightCategoriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Insights_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Insights_Default_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Insights_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Insights_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Insights_Default_EntryPeopleArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Insights_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Insights_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type JobTitles_Tag = ElementInterface & TagInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the group that contains the tag. */
  groupHandle: Scalars['String']['output'];
  /** The ID of the group that contains the tag. */
  groupId: Scalars['Int']['output'];
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type JobTitles_Tag_CountArgs = {
  field: Scalars['String']['input'];
};

export type MailingList_Form = ElementInterface & FormInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** A list of captcha values (name and value) to assist with spam protection. */
  captchas: Maybe<Array<Maybe<FormieCaptchaType>>>;
  /** The form’s config as JSON. */
  configJson: Maybe<Scalars['String']['output']>;
  /** A CSRF token (name and value). */
  csrfToken: Maybe<FormieCsrfTokenType>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The form’s fields. */
  formFields: Maybe<Array<Maybe<FieldInterface>>>;
  /** The form’s handle. */
  handle: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Whether the form is considered available according to user checks, scheduling and more. */
  isAvailable: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The form’s pages. */
  pages: Maybe<Array<Maybe<PageInterface>>>;
  /** The form’s rows. */
  rows: Maybe<Array<Maybe<RowInterface>>>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The form’s settings. */
  settings: Maybe<FormSettingsType>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The form’s endpoint for sending submissions to, if using POST requests. */
  submissionEndpoint: Maybe<Scalars['String']['output']>;
  /** The form’s GQL mutation name for submissions to use. */
  submissionMutationName: Maybe<Scalars['String']['output']>;
  /** The form’s rendered HTML. */
  templateHtml: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type MailingList_Form_CountArgs = {
  field: Scalars['String']['input'];
};


export type MailingList_FormFormFieldsArgs = {
  includeDisabled: InputMaybe<Scalars['Boolean']['input']>;
};


export type MailingList_FormTemplateHtmlArgs = {
  options: InputMaybe<Scalars['String']['input']>;
  populateFormValues: InputMaybe<Scalars['String']['input']>;
};

export type MailingList_Submission = ElementInterface & SubmissionInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  clickToReceiveMarketingUpdates: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  email: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The submission’s IP Address. */
  ipAddress: Maybe<Scalars['String']['output']>;
  /** Whether the submission is incomplete. */
  isIncomplete: Maybe<Scalars['Boolean']['output']>;
  /** Whether the submission is spam. */
  isSpam: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  name1: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The submission’s spam type. */
  spamClass: Maybe<Scalars['String']['output']>;
  /** The submission’s spam reason. */
  spamReason: Maybe<Scalars['String']['output']>;
  /** The submission’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The submission’s status ID. */
  statusId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type MailingList_Submission_CountArgs = {
  field: Scalars['String']['input'];
};

export type MainNavigation_Node = ElementInterface & NodeInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The node’s children. Accepts the same arguments as the `nodes` query. */
  children: Maybe<Array<Maybe<NodeInterface>>>;
  /** Any additional classes for the node. */
  classes: Maybe<Scalars['String']['output']>;
  /** Any additional custom attributes for the node. */
  customAttributes: Maybe<Array<Maybe<NodeCustomAttribute>>>;
  /** Any additional data for the node. */
  data: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The element the node links to. */
  element: Maybe<ElementInterface>;
  /** The ID of the element this node is linked to. */
  elementId: Maybe<Scalars['Int']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The handle of the navigation this node belongs to. */
  navHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the navigation this node belongs to. */
  navId: Maybe<Scalars['Int']['output']>;
  /** The name of the navigation this node belongs to. */
  navName: Maybe<Scalars['String']['output']>;
  /** Whether this node should open in a new window. */
  newWindow: Maybe<Scalars['String']['output']>;
  /** The node’s URI */
  nodeUri: Maybe<Scalars['String']['output']>;
  /** The node’s parent. */
  parent: Maybe<NodeInterface>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The type of node this is. */
  type: Maybe<Scalars['String']['output']>;
  /** The display name for the type of node this is. */
  typeLabel: Maybe<Scalars['String']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The node’s full URL */
  url: Maybe<Scalars['String']['output']>;
  /** The URL for this navigation item. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};


export type MainNavigation_Node_CountArgs = {
  field: Scalars['String']['input'];
};


export type MainNavigation_NodeChildrenArgs = {
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  nav: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navHandle: InputMaybe<Scalars['String']['input']>;
  navId: InputMaybe<Scalars['Int']['input']>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_NeoField = Main_AboutTheAuthor_BlockType | Main_Accordion_BlockType | Main_Accreditations_BlockType | Main_CaseHighlights_BlockType | Main_CookieDeclaration_BlockType | Main_Cta_BlockType | Main_DetailedCta_BlockType | Main_Events_BlockType | Main_Form_BlockType | Main_FullWidthImage_BlockType | Main_HeadingCards_BlockType | Main_ImageCards_BlockType | Main_Image_BlockType | Main_Insights_BlockType | Main_Map_BlockType | Main_MultiColumnList_BlockType | Main_PeopleSearch_BlockType | Main_People_BlockType | Main_RichText_BlockType | Main_SectionNavigation_BlockType | Main_StandardCards_BlockType | Main_Statistics_BlockType | Main_SummaryCards_BlockType | Main_Testimonials_BlockType | Main_TextImageSplit_BlockType | Main_Timeline_BlockType | Main_Usps_BlockType | Main_Video_BlockType;

export type Main_AboutTheAuthor_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_AboutTheAuthor_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type Main_Accordion_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  accordionEntries: Array<Maybe<EntryInterface>>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Accordion_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Accordion_BlockTypeAccordionEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Accordion_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Accordion_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Accreditations_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  accreditations: Array<Maybe<EntryInterface>>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  enableCarousel: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  showLabels: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Accreditations_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Accreditations_BlockTypeAccreditationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Accreditations_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Accreditations_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_CaseHighlights_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  caseHighlights: Array<Maybe<EntryInterface>>;
  caseHighlightsColourway: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_CaseHighlights_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_CaseHighlights_BlockTypeCaseHighlightsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_CaseHighlights_BlockTypeCaseHighlightsColourwayArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_CaseHighlights_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_CookieDeclaration_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_CookieDeclaration_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_CookieDeclaration_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Cta_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  cta: Array<Maybe<EntryInterface>>;
  ctaColourway: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Cta_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Cta_BlockTypeCtaArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Cta_BlockTypeCtaColourwayArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_DetailedCta_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  detailedCta: Array<Maybe<EntryInterface>>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_DetailedCta_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_DetailedCta_BlockTypeDetailedCtaArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Events_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  events: Array<Maybe<EntryInterface>>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Events_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Events_BlockTypeEventsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Events_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Events_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Form_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  formType: Maybe<Scalars['String']['output']>;
  formieForm: Maybe<Array<Maybe<FormInterface>>>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  hubspotFormId: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Form_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Form_BlockTypeFormTypeArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Form_BlockTypeFormieFormArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  handle: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Main_Form_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Form_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_FullWidthImage_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  mobileImage: Array<Maybe<AssetInterface>>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_FullWidthImage_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_FullWidthImage_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Main_FullWidthImage_BlockTypeMobileImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Main_HeadingCards_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  entryLevel: Maybe<Scalars['Number']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  sectionHandle: Maybe<Scalars['String']['output']>;
  serviceCategory: Maybe<Scalars['String']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_HeadingCards_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_HeadingCards_BlockTypeSectionHandleArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_HeadingCards_BlockTypeServiceCategoryArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_HeadingCards_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_ImageCards_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  imageCardEntries: Array<Maybe<EntryInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_ImageCards_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_ImageCards_BlockTypeImageCardEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Image_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  singleLineText: Maybe<Scalars['String']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Image_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Image_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Main_Image_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Insights_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  enableCarousel: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  insightCategory: Array<Maybe<EntryInterface>>;
  insightSelectionMethod: Maybe<Scalars['String']['output']>;
  insights: Array<Maybe<EntryInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Insights_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Insights_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Insights_BlockTypeInsightCategoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Insights_BlockTypeInsightSelectionMethodArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Insights_BlockTypeInsightsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Insights_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Map_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  marker: Array<Maybe<EntryInterface>>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  zoomLevel: Maybe<Scalars['Number']['output']>;
};


export type Main_Map_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Map_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Map_BlockTypeMarkerArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_MultiColumnList_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  serviceListItems: Array<Maybe<TagInterface>>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_MultiColumnList_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_MultiColumnList_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_MultiColumnList_BlockTypeServiceListItemsArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  group: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Main_MultiColumnList_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_PeopleSearch_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_PeopleSearch_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type Main_People_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  people: Array<Maybe<EntryInterface>>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_People_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_People_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_People_BlockTypePeopleArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_People_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_RichText_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_RichText_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_RichText_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_SectionNavigation_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_SectionNavigation_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type Main_StandardCards_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  entries: Array<Maybe<EntryInterface>>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_StandardCards_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_StandardCards_BlockTypeEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_StandardCards_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_StandardCards_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Statistics_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Statistics_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Statistics_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_SummaryCards_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  entries: Array<Maybe<EntryInterface>>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_SummaryCards_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_SummaryCards_BlockTypeEntriesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_SummaryCards_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Testimonials_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  showIcon: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  testimonials: Array<Maybe<EntryInterface>>;
  testimonialsColourway: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Testimonials_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Testimonials_BlockTypeTestimonialsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Testimonials_BlockTypeTestimonialsColourwayArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_TextImageSplit_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  colourway: Maybe<Scalars['String']['output']>;
  contentPosition: Maybe<Scalars['String']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_TextImageSplit_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_TextImageSplit_BlockTypeColourwayArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_TextImageSplit_BlockTypeContentPositionArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_TextImageSplit_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_TextImageSplit_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Main_Timeline_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  timeline: Array<Maybe<Timeline_MatrixField>>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Main_Timeline_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Timeline_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Timeline_BlockTypeTimelineArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Main_Usps_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  richText: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  usps: Array<Maybe<EntryInterface>>;
  uspsColourway: Maybe<Scalars['String']['output']>;
};


export type Main_Usps_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Usps_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Usps_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Usps_BlockTypeUspsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Usps_BlockTypeUspsColourwayArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Main_Video_BlockType = ElementInterface & NeoBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  anchor: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the Neo block. */
  fieldId: Maybe<Scalars['Int']['output']>;
  heading: Maybe<Scalars['String']['output']>;
  headingElement: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The Neo block’s level. */
  level: Maybe<Scalars['Int']['output']>;
  /** The ID of the primary owner of the Neo block. */
  primaryOwnerId: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the Neo block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  spacing: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the Neo block’s type. */
  typeHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the Neo block’s type. */
  typeId: Maybe<Scalars['Int']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  vimeoId: Maybe<Scalars['String']['output']>;
};


export type Main_Video_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Main_Video_BlockTypeHeadingElementArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};


export type Main_Video_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Main_Video_BlockTypeSpacingArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type MapMarkersSectionEntryUnion = MapMarkers_Default_Entry;

export type MapMarkers_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['Number']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  longitude: Maybe<Scalars['Number']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type MapMarkers_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type MapMarkers_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type MapMarkers_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type MapMarkers_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type MapMarkers_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type MapMarkers_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type MapMarkers_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type MapMarkers_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type MapMarkers_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type OfficesSectionEntryUnion = Offices_Default_Entry;

export type Offices_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  telephone: Maybe<PhoneNumber_PhoneNumber>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Offices_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Offices_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Offices_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Offices_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Offices_Default_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Offices_Default_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Offices_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Offices_Default_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Offices_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Offices_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Offices_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Offices_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type PeopleIndexSectionEntryUnion = PeopleIndex_PeopleIndex_Entry;

export type PeopleIndex_PeopleIndex_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type PeopleIndex_PeopleIndex_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type PeopleIndex_PeopleIndex_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type PeopleIndex_PeopleIndex_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type PeopleIndex_PeopleIndex_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type PeopleIndex_PeopleIndex_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type PeopleIndex_PeopleIndex_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type PeopleIndex_PeopleIndex_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type PeopleIndex_PeopleIndex_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type PeopleIndex_PeopleIndex_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type PeopleIndex_PeopleIndex_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type PeopleIndex_PeopleIndex_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type PeopleIndex_PeopleIndex_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type PeopleSectionEntryUnion = People_Default_Entry;

export type People_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  jobTitles: Array<Maybe<TagInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  linkedin: Maybe<Scalars['String']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  mobile: Maybe<PhoneNumber_PhoneNumber>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  offices: Array<Maybe<EntryInterface>>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  sectors: Array<Maybe<EntryInterface>>;
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  services: Array<Maybe<EntryInterface>>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  specialisms: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  telephone: Maybe<PhoneNumber_PhoneNumber>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  twitter: Maybe<Scalars['String']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type People_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type People_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type People_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type People_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type People_Default_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type People_Default_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type People_Default_EntryJobTitlesArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  group: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type People_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type People_Default_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type People_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type People_Default_EntryOfficesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type People_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type People_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type People_Default_EntrySectorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type People_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};


export type People_Default_EntryServicesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};

/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumber = {
  carrierName: Maybe<Scalars['String']['output']>;
  countryCode: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  extension: Maybe<Scalars['String']['output']>;
  format: Maybe<Scalars['String']['output']>;
  formatForCountry: Maybe<Scalars['String']['output']>;
  formatForMobileDialing: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
  regionCode: Maybe<Scalars['String']['output']>;
  timezones: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: Maybe<Scalars['Int']['output']>;
};


/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberCarrierNameArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
};


/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberDescriptionArgs = {
  locale: InputMaybe<Scalars['String']['input']>;
  region: InputMaybe<Scalars['String']['input']>;
};


/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberFormatArgs = {
  format: InputMaybe<Scalars['String']['input']>;
};


/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberFormatForCountryArgs = {
  region: InputMaybe<Scalars['String']['input']>;
};


/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumberFormatForMobileDialingArgs = {
  format: InputMaybe<Scalars['Boolean']['input']>;
  region: InputMaybe<Scalars['String']['input']>;
};

export type SectorsIndexSectionEntryUnion = SectorsIndex_SectorsIndex_Entry;

export type SectorsIndex_SectorsIndex_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type SectorsIndex_SectorsIndex_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type SectorsIndex_SectorsIndex_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SectorsIndex_SectorsIndex_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SectorsIndex_SectorsIndex_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SectorsIndex_SectorsIndex_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type SectorsIndex_SectorsIndex_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type SectorsIndex_SectorsIndex_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SectorsIndex_SectorsIndex_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type SectorsIndex_SectorsIndex_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SectorsIndex_SectorsIndex_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SectorsIndex_SectorsIndex_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SectorsIndex_SectorsIndex_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type SectorsSectionEntryUnion = Sectors_Default_Entry | Sectors_MirroredEntry_Entry;

export type Sectors_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  hideFromSectionNav: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  parentSectors: Array<Maybe<EntryInterface>>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Sectors_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Sectors_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_Default_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Sectors_Default_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Sectors_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_Default_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Sectors_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_Default_EntryParentSectorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type Sectors_MirroredEntry_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  mirroredEntry: Array<Maybe<EntryInterface>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Sectors_MirroredEntry_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Sectors_MirroredEntry_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_MirroredEntry_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_MirroredEntry_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_MirroredEntry_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_MirroredEntry_EntryMirroredEntryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_MirroredEntry_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_MirroredEntry_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_MirroredEntry_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Sectors_MirroredEntry_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type ServdFeatureImage_Asset = AssetInterface & ElementInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Alternative text for the asset. */
  alt: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the asset file was last modified. */
  dateModified: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The file extension for the asset file. */
  extension: Scalars['String']['output'];
  /** The filename of the asset file. */
  filename: Scalars['String']['output'];
  /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
  focalPoint: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** The ID of the folder that the asset belongs to. */
  folderId: Scalars['Int']['output'];
  /** Returns the file’s format. */
  format: Maybe<Scalars['String']['output']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint: Scalars['Boolean']['output'];
  /** The height in pixels or null if it’s not an image. */
  height: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** An `<img>` tag based on this asset. */
  img: Maybe<Scalars['String']['output']>;
  /** The file kind. */
  kind: Scalars['String']['output'];
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<AssetInterface>;
  /** The asset’s path in the volume. */
  path: Scalars['String']['output'];
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<AssetInterface>;
  relatedFeatureAssetParts: Array<Maybe<AssetInterface>>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The file size in bytes. */
  size: Maybe<Scalars['String']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url: Maybe<Scalars['String']['output']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId: Maybe<Scalars['Int']['output']>;
  /** The width in pixels or null if it’s not an image. */
  width: Maybe<Scalars['Int']['output']>;
};


export type ServdFeatureImage_Asset_CountArgs = {
  field: Scalars['String']['input'];
};


export type ServdFeatureImage_AssetFormatArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type ServdFeatureImage_AssetHeightArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type ServdFeatureImage_AssetNextArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServdFeatureImage_AssetPrevArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServdFeatureImage_AssetRelatedFeatureAssetPartsArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServdFeatureImage_AssetSrcsetArgs = {
  sizes: Array<Scalars['String']['input']>;
};


export type ServdFeatureImage_AssetUrlArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type ServdFeatureImage_AssetWidthArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type ServdPrivate_Asset = AssetInterface & ElementInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Alternative text for the asset. */
  alt: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the asset file was last modified. */
  dateModified: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The file extension for the asset file. */
  extension: Scalars['String']['output'];
  /** The filename of the asset file. */
  filename: Scalars['String']['output'];
  /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
  focalPoint: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** The ID of the folder that the asset belongs to. */
  folderId: Scalars['Int']['output'];
  /** Returns the file’s format. */
  format: Maybe<Scalars['String']['output']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint: Scalars['Boolean']['output'];
  /** The height in pixels or null if it’s not an image. */
  height: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** An `<img>` tag based on this asset. */
  img: Maybe<Scalars['String']['output']>;
  /** The file kind. */
  kind: Scalars['String']['output'];
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<AssetInterface>;
  /** The asset’s path in the volume. */
  path: Scalars['String']['output'];
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<AssetInterface>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The file size in bytes. */
  size: Maybe<Scalars['String']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url: Maybe<Scalars['String']['output']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId: Maybe<Scalars['Int']['output']>;
  /** The width in pixels or null if it’s not an image. */
  width: Maybe<Scalars['Int']['output']>;
};


export type ServdPrivate_Asset_CountArgs = {
  field: Scalars['String']['input'];
};


export type ServdPrivate_AssetFormatArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type ServdPrivate_AssetHeightArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type ServdPrivate_AssetNextArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServdPrivate_AssetPrevArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServdPrivate_AssetSrcsetArgs = {
  sizes: Array<Scalars['String']['input']>;
};


export type ServdPrivate_AssetUrlArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type ServdPrivate_AssetWidthArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type ServdTemp_Asset = AssetInterface & ElementInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Alternative text for the asset. */
  alt: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the asset file was last modified. */
  dateModified: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The file extension for the asset file. */
  extension: Scalars['String']['output'];
  /** The filename of the asset file. */
  filename: Scalars['String']['output'];
  /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
  focalPoint: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** The ID of the folder that the asset belongs to. */
  folderId: Scalars['Int']['output'];
  /** Returns the file’s format. */
  format: Maybe<Scalars['String']['output']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint: Scalars['Boolean']['output'];
  /** The height in pixels or null if it’s not an image. */
  height: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** An `<img>` tag based on this asset. */
  img: Maybe<Scalars['String']['output']>;
  /** The file kind. */
  kind: Scalars['String']['output'];
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<AssetInterface>;
  /** The asset’s path in the volume. */
  path: Scalars['String']['output'];
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<AssetInterface>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The file size in bytes. */
  size: Maybe<Scalars['String']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url: Maybe<Scalars['String']['output']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId: Maybe<Scalars['Int']['output']>;
  /** The width in pixels or null if it’s not an image. */
  width: Maybe<Scalars['Int']['output']>;
};


export type ServdTemp_Asset_CountArgs = {
  field: Scalars['String']['input'];
};


export type ServdTemp_AssetFormatArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type ServdTemp_AssetHeightArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type ServdTemp_AssetNextArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServdTemp_AssetPrevArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServdTemp_AssetSrcsetArgs = {
  sizes: Array<Scalars['String']['input']>;
};


export type ServdTemp_AssetUrlArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type ServdTemp_AssetWidthArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type Servd_Asset = AssetInterface & ElementInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Alternative text for the asset. */
  alt: Maybe<Scalars['String']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the asset file was last modified. */
  dateModified: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The file extension for the asset file. */
  extension: Scalars['String']['output'];
  /** The filename of the asset file. */
  filename: Scalars['String']['output'];
  /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
  focalPoint: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  /** The ID of the folder that the asset belongs to. */
  folderId: Scalars['Int']['output'];
  /** Returns the file’s format. */
  format: Maybe<Scalars['String']['output']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint: Scalars['Boolean']['output'];
  /** The height in pixels or null if it’s not an image. */
  height: Maybe<Scalars['Int']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** An `<img>` tag based on this asset. */
  img: Maybe<Scalars['String']['output']>;
  /** The file kind. */
  kind: Scalars['String']['output'];
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<AssetInterface>;
  /** The asset’s path in the volume. */
  path: Scalars['String']['output'];
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<AssetInterface>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The file size in bytes. */
  size: Maybe<Scalars['String']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url: Maybe<Scalars['String']['output']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId: Maybe<Scalars['Int']['output']>;
  /** The width in pixels or null if it’s not an image. */
  width: Maybe<Scalars['Int']['output']>;
};


export type Servd_Asset_CountArgs = {
  field: Scalars['String']['input'];
};


export type Servd_AssetFormatArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type Servd_AssetHeightArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type Servd_AssetNextArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Servd_AssetPrevArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Servd_AssetSrcsetArgs = {
  sizes: Array<Scalars['String']['input']>;
};


export type Servd_AssetUrlArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};


export type Servd_AssetWidthArgs = {
  format: InputMaybe<Scalars['String']['input']>;
  handle: InputMaybe<Scalars['String']['input']>;
  height: InputMaybe<Scalars['Int']['input']>;
  immediately: InputMaybe<Scalars['Boolean']['input']>;
  interlace: InputMaybe<Scalars['String']['input']>;
  mode: InputMaybe<Scalars['String']['input']>;
  position: InputMaybe<Scalars['String']['input']>;
  quality: InputMaybe<Scalars['Int']['input']>;
  transform: InputMaybe<Scalars['String']['input']>;
  width: InputMaybe<Scalars['Int']['input']>;
};

export type ServiceListItems_Tag = ElementInterface & TagInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the group that contains the tag. */
  groupHandle: Scalars['String']['output'];
  /** The ID of the group that contains the tag. */
  groupId: Scalars['Int']['output'];
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type ServiceListItems_Tag_CountArgs = {
  field: Scalars['String']['input'];
};

export type ServicesIndexSectionEntryUnion = ServicesIndex_ServicesIndex_Entry;

export type ServicesIndex_ServicesIndex_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type ServicesIndex_ServicesIndex_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type ServicesIndex_ServicesIndex_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type ServicesIndex_ServicesIndex_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type ServicesIndex_ServicesIndex_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type ServicesIndex_ServicesIndex_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServicesIndex_ServicesIndex_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServicesIndex_ServicesIndex_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type ServicesIndex_ServicesIndex_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ServicesIndex_ServicesIndex_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type ServicesIndex_ServicesIndex_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type ServicesIndex_ServicesIndex_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type ServicesIndex_ServicesIndex_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type ServicesSectionEntryUnion = Services_Default_Entry | Services_MirroredEntry_Entry;

export type Services_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  hero: Maybe<Array<Maybe<Hero_NeoField>>>;
  hideFromSectionNav: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  indexHeading: Maybe<Scalars['String']['output']>;
  indexImage: Array<Maybe<AssetInterface>>;
  indexSummary: Maybe<Scalars['String']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  main: Maybe<Array<Maybe<Main_NeoField>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  parentServices: Array<Maybe<EntryInterface>>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  seo: Maybe<Scalars['String']['output']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  serviceCategory: Maybe<Scalars['String']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Services_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Services_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_Default_EntryHeroArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Services_Default_EntryIndexImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Services_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_Default_EntryMainArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level: InputMaybe<Scalars['QueryArgument']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Services_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_Default_EntryParentServicesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};


export type Services_Default_EntryServiceCategoryArgs = {
  label: InputMaybe<Scalars['Boolean']['input']>;
};

export type Services_MirroredEntry_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  mirroredEntry: Array<Maybe<EntryInterface>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Services_MirroredEntry_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Services_MirroredEntry_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_MirroredEntry_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_MirroredEntry_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_MirroredEntry_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_MirroredEntry_EntryMirroredEntryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_MirroredEntry_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_MirroredEntry_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_MirroredEntry_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Services_MirroredEntry_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type SiteNoticeSectionEntryUnion = SiteNotice_SiteNotice_Entry;

export type SiteNotice_SiteNotice_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  minimalRichText: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  typedLink: Array<Maybe<HyperLinkInterface>>;
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type SiteNotice_SiteNotice_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type SiteNotice_SiteNotice_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SiteNotice_SiteNotice_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SiteNotice_SiteNotice_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SiteNotice_SiteNotice_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SiteNotice_SiteNotice_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SiteNotice_SiteNotice_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SiteNotice_SiteNotice_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type SiteNotice_SiteNotice_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type StatisticsSectionEntryUnion = Statistics_Statistics_Entry;

export type Statistics_MatrixField = Statistics_Statistic_BlockType;

export type Statistics_Statistic_BlockType = ElementInterface & MatrixBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the matrix block. */
  fieldId: Scalars['Int']['output'];
  figure: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The ID of the primary owner of the Matrix block. */
  primaryOwnerId: Scalars['Int']['output'];
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the matrix block’s type. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the matrix block’s type. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Statistics_Statistic_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};

export type Statistics_Statistics_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  statistics: Array<Maybe<Statistics_MatrixField>>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
  vectorImage: Array<Maybe<AssetInterface>>;
};


export type Statistics_Statistics_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Statistics_Statistics_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Statistics_Statistics_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Statistics_Statistics_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Statistics_Statistics_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Statistics_Statistics_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Statistics_Statistics_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Statistics_Statistics_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Statistics_Statistics_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};


export type Statistics_Statistics_EntryStatisticsArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fieldId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  primaryOwnerId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Statistics_Statistics_EntryVectorImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TestimonialsSectionEntryUnion = Testimonials_Default_Entry;

export type Testimonials_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  multiLineText: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  singleLineText: Maybe<Scalars['String']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Testimonials_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Testimonials_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Testimonials_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Testimonials_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Testimonials_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Testimonials_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Testimonials_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Testimonials_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Testimonials_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

export type Timeline_MatrixField = Timeline_Card_BlockType;

export type Timeline_Card_BlockType = ElementInterface & MatrixBlockInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  date: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the field that owns the matrix block. */
  fieldId: Scalars['Int']['output'];
  heading: Maybe<Scalars['String']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The ID of the primary owner of the Matrix block. */
  primaryOwnerId: Scalars['Int']['output'];
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder: Maybe<Scalars['Int']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['String']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the matrix block’s type. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the matrix block’s type. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
};


export type Timeline_Card_BlockType_CountArgs = {
  field: Scalars['String']['input'];
};


export type Timeline_Card_BlockTypeImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TypedLink_Asset_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_Category_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_Custom_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_Email_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_Embed_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_Entry_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_Form_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_Phone_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_Site_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_Url_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type TypedLink_User_LinkType = HyperLinkInterface & {
  /** The `aria-label` attribute for the link. */
  ariaLabel: Maybe<Scalars['String']['output']>;
  /** The `class` attribute for the link. */
  classes: Maybe<Scalars['String']['output']>;
  /** The element (if provided) for the link. */
  element: Maybe<ElementInterface>;
  /** Whether the chosen link value is an element. */
  isElement: Maybe<Scalars['Boolean']['output']>;
  /** Whether a link has been set for the field. */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  /** The HTML output for a `<a>` element. */
  link: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  linkText: Maybe<Scalars['String']['output']>;
  /** The uri for the link (if an element-based link). */
  linkUri: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  linkUrl: Maybe<Scalars['String']['output']>;
  /** The raw link data. */
  linkValue: Maybe<Scalars['String']['output']>;
  /** Whether the link should open in a new window. */
  newWindow: Maybe<Scalars['Boolean']['output']>;
  /** The `target` attribute for the link. */
  target: Maybe<Scalars['String']['output']>;
  /** The text for the link. */
  text: Maybe<Scalars['String']['output']>;
  /** The `title` attribute for the link. */
  title: Maybe<Scalars['String']['output']>;
  /** The link type. */
  type: Maybe<Scalars['String']['output']>;
  /** The url for the link. */
  url: Maybe<Scalars['String']['output']>;
  /** The url prefix for the link. */
  urlPrefix: Maybe<Scalars['String']['output']>;
  /** The url suffix for the link. */
  urlSuffix: Maybe<Scalars['String']['output']>;
};

export type UspsSectionEntryUnion = Usps_Default_Entry;

export type Usps_Default_Entry = ElementInterface & EntryInterface & {
  /** Return a number of related elements for a field. */
  _count: Maybe<Scalars['Int']['output']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors: Array<EntryInterface>;
  /** Whether the element is archived. */
  archived: Maybe<Scalars['Boolean']['output']>;
  /** Returns the entry’s canonical ID. */
  canonicalId: Maybe<Scalars['Int']['output']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid: Maybe<Scalars['String']['output']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children: Array<EntryInterface>;
  /** The date the element was created. */
  dateCreated: Maybe<Scalars['DateTime']['output']>;
  /** The date the element was last updated. */
  dateUpdated: Maybe<Scalars['DateTime']['output']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants: Array<EntryInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId: Maybe<Scalars['Int']['output']>;
  /** The name of the draft. */
  draftName: Maybe<Scalars['String']['output']>;
  /** The notes for the draft. */
  draftNotes: Maybe<Scalars['String']['output']>;
  /** Whether the element is enabled. */
  enabled: Maybe<Scalars['Boolean']['output']>;
  /** Whether the element is enabled for the site. */
  enabledForSite: Maybe<Scalars['Boolean']['output']>;
  /** The expiry date of the entry. */
  expiryDate: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the entity */
  id: Maybe<Scalars['ID']['output']>;
  image: Array<Maybe<AssetInterface>>;
  /** Returns whether this is a draft. */
  isDraft: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is a revision. */
  isRevision: Maybe<Scalars['Boolean']['output']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft: Maybe<Scalars['Boolean']['output']>;
  /** The language of the site element is associated with. */
  language: Maybe<Scalars['String']['output']>;
  /** The element’s level within its structure */
  level: Maybe<Scalars['Int']['output']>;
  /** The element’s left position within its structure. */
  lft: Maybe<Scalars['Int']['output']>;
  /** The same element in other locales. */
  localized: Array<EntryInterface>;
  multiLineText: Maybe<Scalars['String']['output']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent: Maybe<EntryInterface>;
  /** The entry’s post date. */
  postDate: Maybe<Scalars['DateTime']['output']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev: Maybe<EntryInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId: Maybe<Scalars['Int']['output']>;
  /** The revision notes (from the `revisions` table). */
  revisionNotes: Maybe<Scalars['String']['output']>;
  /** The element’s right position within its structure. */
  rgt: Maybe<Scalars['Int']['output']>;
  /** The element’s structure’s root ID */
  root: Maybe<Scalars['Int']['output']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore: Maybe<Scalars['Int']['output']>;
  /** The handle of the section that contains the entry. */
  sectionHandle: Scalars['String']['output'];
  /** The ID of the section that contains the entry. */
  sectionId: Scalars['Int']['output'];
  /** This query is used to query for SEOmatic meta data. */
  seomatic: Maybe<SeomaticInterface>;
  /** The handle of the site the element is associated with. */
  siteHandle: Maybe<Scalars['String']['output']>;
  /** The ID of the site the element is associated with. */
  siteId: Maybe<Scalars['Int']['output']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId: Maybe<Scalars['ID']['output']>;
  /** The element’s slug. */
  slug: Maybe<Scalars['String']['output']>;
  /**
   * Returns the entry’s canonical ID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
   */
  sourceId: Maybe<Scalars['Int']['output']>;
  /**
   * Returns the entry’s canonical UUID.
   * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
   */
  sourceUid: Maybe<Scalars['String']['output']>;
  /** The element’s status. */
  status: Maybe<Scalars['String']['output']>;
  /** The element’s structure ID. */
  structureId: Maybe<Scalars['Int']['output']>;
  /** The element’s title. */
  title: Maybe<Scalars['String']['output']>;
  /** Whether the element has been soft-deleted. */
  trashed: Maybe<Scalars['Boolean']['output']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle: Scalars['String']['output'];
  /** The ID of the entry type that contains the entry. */
  typeId: Scalars['Int']['output'];
  /** The UID of the entity */
  uid: Maybe<Scalars['String']['output']>;
  /** The element’s URI. */
  uri: Maybe<Scalars['String']['output']>;
  /** The element’s full URL */
  url: Maybe<Scalars['String']['output']>;
};


export type Usps_Default_Entry_CountArgs = {
  field: Scalars['String']['input'];
};


export type Usps_Default_EntryAncestorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Usps_Default_EntryChildrenArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Usps_Default_EntryDescendantsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Usps_Default_EntryImageArgs = {
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateModified: InputMaybe<Scalars['String']['input']>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  folderId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hasAlt: InputMaybe<Scalars['Boolean']['input']>;
  height: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  includeSubfolders: InputMaybe<Scalars['Boolean']['input']>;
  kind: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedFeatureAssetParts: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  search: InputMaybe<Scalars['String']['input']>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  size: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uploader: InputMaybe<Scalars['QueryArgument']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volume: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  volumeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  width: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  withTransforms: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type Usps_Default_EntryLocalizedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Usps_Default_EntryNextArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Usps_Default_EntryParentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Usps_Default_EntryPrevArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  ancestorDist: InputMaybe<Scalars['Int']['input']>;
  ancestorOf: InputMaybe<Scalars['Int']['input']>;
  authorGroup: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  authorGroupId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  authorId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  before: InputMaybe<Scalars['String']['input']>;
  dateCreated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dateUpdated: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  descendantDist: InputMaybe<Scalars['Int']['input']>;
  descendantOf: InputMaybe<Scalars['Int']['input']>;
  editable: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  enableRelatedInsights: InputMaybe<Scalars['Boolean']['input']>;
  entry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  expiryDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fixedOrder: InputMaybe<Scalars['Boolean']['input']>;
  hasDescendants: InputMaybe<Scalars['Boolean']['input']>;
  heading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  headingElement: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  hideFromSectionNav: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  image: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  inReverse: InputMaybe<Scalars['Boolean']['input']>;
  indexHeading: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexHideEventDate: InputMaybe<Scalars['Boolean']['input']>;
  indexImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  indexSummary: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  insightCategories: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  jobTitles: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  language: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  leaves: InputMaybe<Scalars['Boolean']['input']>;
  level: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  linkedin: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  longitude: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  minimalRichText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mirroredEntry: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  mobile: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  multiLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  nextSiblingOf: InputMaybe<Scalars['Int']['input']>;
  offices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Scalars['String']['input']>;
  parentSectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  parentServices: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  people: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  positionedAfter: InputMaybe<Scalars['Int']['input']>;
  positionedBefore: InputMaybe<Scalars['Int']['input']>;
  postDate: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  preferSites: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  prevSiblingOf: InputMaybe<Scalars['Int']['input']>;
  ref: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedTo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAll: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  relatedToAssets: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
  relatedToCategories: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
  relatedToEntries: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
  relatedToTags: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
  relatedToUsers: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
  richText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  search: InputMaybe<Scalars['String']['input']>;
  section: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sectionId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  sectors: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  seo: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  services: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  singleLineText: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  site: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  siteId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  siteSettingsId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  slug: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  specialisms: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  structureId: InputMaybe<Scalars['Int']['input']>;
  telephone: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  title: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  twitter: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  type: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  typeId: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  typedLink: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  uid: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  unique: InputMaybe<Scalars['Boolean']['input']>;
  uri: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vectorImage: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>>>;
  withStructure: InputMaybe<Scalars['Boolean']['input']>;
};


export type Usps_Default_EntrySeomaticArgs = {
  asArray: InputMaybe<Scalars['Boolean']['input']>;
  environment: InputMaybe<SeomaticEnvironment>;
  site: InputMaybe<Scalars['String']['input']>;
  siteId: InputMaybe<Scalars['Int']['input']>;
  uri: InputMaybe<Scalars['String']['input']>;
};

type Field_Fragment_Field_Address_Fragment = (
  { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Address' }
);

type Field_Fragment_Field_Agree_Fragment = (
  { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Agree' }
);

type Field_Fragment_Field_Checkboxes_Field_Radio_Fragment = (
  { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
);

type Field_Fragment_IBs22p71C0Hb1CcibBq4Tytx2dG0Bqo0TofjknlJi_Fragment = (
  { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
);

type Field_Fragment_Field_Dropdown_Fragment = (
  { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Dropdown' }
);

type Field_Fragment_Field_Entries_Fragment = (
  { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Entries' }
);

type Field_Fragment_Field_Heading_Fragment = (
  { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Heading' }
);

type Field_Fragment_Field_Html_Fragment = (
  { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Html' }
);

type Field_Fragment_Field_MultiLineText_Field_SingleLineText_Fragment = (
  { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
);

type Field_Fragment_Field_Name_Fragment = (
  { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Name' }
);

type Field_Fragment_Field_Number_Fragment = (
  { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Number' }
);

type Field_Fragment_Field_Section_Fragment = (
  { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
  & { __typename: 'Field_Section' }
);

export type Field_FragmentFragment = Field_Fragment_Field_Address_Fragment | Field_Fragment_Field_Agree_Fragment | Field_Fragment_Field_Checkboxes_Field_Radio_Fragment | Field_Fragment_IBs22p71C0Hb1CcibBq4Tytx2dG0Bqo0TofjknlJi_Fragment | Field_Fragment_Field_Dropdown_Fragment | Field_Fragment_Field_Entries_Fragment | Field_Fragment_Field_Heading_Fragment | Field_Fragment_Field_Html_Fragment | Field_Fragment_Field_MultiLineText_Field_SingleLineText_Fragment | Field_Fragment_Field_Name_Fragment | Field_Fragment_Field_Number_Fragment | Field_Fragment_Field_Section_Fragment;

export type Form_FragmentFragment = { id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
        { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Address' }
      ) | (
        { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Agree' }
      ) | (
        { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
      ) | (
        { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
      ) | (
        { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Dropdown' }
      ) | (
        { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Entries' }
      ) | (
        { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
          { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Address' }
        ) | (
          { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Agree' }
        ) | (
          { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
        ) | (
          { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
        ) | (
          { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Dropdown' }
        ) | (
          { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Entries' }
        ) | (
          { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Heading' }
        ) | (
          { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Html' }
        ) | (
          { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
        ) | (
          { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Name' }
        ) | (
          { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Number' }
        ) | (
          { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Section' }
        )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Group' }
      ) | (
        { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Heading' }
      ) | (
        { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Html' }
      ) | (
        { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
      ) | (
        { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Name' }
      ) | (
        { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Number' }
      ) | (
        { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
        & { __typename: 'Field_Section' }
      )> }> }> };

export type FeatureImage_FragmentFragment = { width: number, height: number, alt: string, title: string, url: string };

export type FeatureSrcSetAll_FragmentFragment = { srcset: string };

type HeroNeo_Fragment_Hero_ArticleHeader_BlockType_Fragment = (
  { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
  & { __typename: 'hero_articleHeader_BlockType' }
);

type HeroNeo_Fragment_Hero_BaseHero_BlockType_Fragment = (
  { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
  & { __typename: 'hero_baseHero_BlockType' }
);

type HeroNeo_Fragment_Hero_FeatureHero_BlockType_Fragment = (
  { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
  & { __typename: 'hero_featureHero_BlockType' }
);

type HeroNeo_Fragment_Hero_ImageHero_BlockType_Fragment = (
  { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
  & { __typename: 'hero_imageHero_BlockType' }
);

type HeroNeo_Fragment_Hero_PersonHeader_BlockType_Fragment = (
  { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
  & { __typename: 'hero_personHeader_BlockType' }
);

type HeroNeo_Fragment_Hero_SplitHero_BlockType_Fragment = (
  { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
  & { __typename: 'hero_splitHero_BlockType' }
);

type HeroNeo_Fragment_Hero_TextHero_BlockType_Fragment = (
  { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
  & { __typename: 'hero_textHero_BlockType' }
);

type HeroNeo_Fragment_6WjFy02qpHQvQvEh5nTmq7nelQXlrZ9ElVisdxef2fE_Fragment = (
  { id: string }
  & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_accordion_BlockType' | 'main_accreditations_BlockType' | 'main_caseHighlights_BlockType' | 'main_cookieDeclaration_BlockType' | 'main_cta_BlockType' | 'main_detailedCta_BlockType' | 'main_events_BlockType' | 'main_form_BlockType' | 'main_fullWidthImage_BlockType' | 'main_headingCards_BlockType' | 'main_imageCards_BlockType' | 'main_image_BlockType' | 'main_insights_BlockType' | 'main_map_BlockType' | 'main_multiColumnList_BlockType' | 'main_peopleSearch_BlockType' | 'main_people_BlockType' | 'main_richText_BlockType' | 'main_sectionNavigation_BlockType' }
);

type HeroNeo_Fragment_CvR9J7Z3zDcz3dfB0egWe0rMmsYIocDyqgFrouGnK8_Fragment = (
  { id: string }
  & { __typename: 'main_standardCards_BlockType' | 'main_statistics_BlockType' | 'main_summaryCards_BlockType' | 'main_testimonials_BlockType' | 'main_textImageSplit_BlockType' | 'main_timeline_BlockType' | 'main_usps_BlockType' | 'main_video_BlockType' }
);

export type HeroNeo_FragmentFragment = HeroNeo_Fragment_Hero_ArticleHeader_BlockType_Fragment | HeroNeo_Fragment_Hero_BaseHero_BlockType_Fragment | HeroNeo_Fragment_Hero_FeatureHero_BlockType_Fragment | HeroNeo_Fragment_Hero_ImageHero_BlockType_Fragment | HeroNeo_Fragment_Hero_PersonHeader_BlockType_Fragment | HeroNeo_Fragment_Hero_SplitHero_BlockType_Fragment | HeroNeo_Fragment_Hero_TextHero_BlockType_Fragment | HeroNeo_Fragment_6WjFy02qpHQvQvEh5nTmq7nelQXlrZ9ElVisdxef2fE_Fragment | HeroNeo_Fragment_CvR9J7Z3zDcz3dfB0egWe0rMmsYIocDyqgFrouGnK8_Fragment;

export type Image_FragmentFragment = { width: number, height: number, alt: string, title: string, url: string };

export type SrcSetAll_FragmentFragment = { srcset: string };

export type SrcSet1x_FragmentFragment = { srcset: string };

type ImageCardsImage_Fragment_FdsAxNsnMHdjKx5xENmdBcq4eYksmDnt7W4Aw1Og_Fragment = {};

type ImageCardsImage_Fragment_Edd0aXvBi0cut4uctjHtqLIrf9etb8qKttKf1TYqDk_Fragment = { fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> };

export type ImageCardsImage_FragmentFragment = ImageCardsImage_Fragment_FdsAxNsnMHdjKx5xENmdBcq4eYksmDnt7W4Aw1Og_Fragment | ImageCardsImage_Fragment_Edd0aXvBi0cut4uctjHtqLIrf9etb8qKttKf1TYqDk_Fragment;

type IndexHeading_Fragment_FdsAxNsnMHdjKx5xENmdBcq4eYksmDnt7W4Aw1Og_Fragment = {};

type IndexHeading_Fragment_Edd0aXvBi0cut4uctjHtqLIrf9etb8qKttKf1TYqDk_Fragment = { indexHeading: string };

export type IndexHeading_FragmentFragment = IndexHeading_Fragment_FdsAxNsnMHdjKx5xENmdBcq4eYksmDnt7W4Aw1Og_Fragment | IndexHeading_Fragment_Edd0aXvBi0cut4uctjHtqLIrf9etb8qKttKf1TYqDk_Fragment;

type IndexSummary_Fragment_FdsAxNsnMHdjKx5xENmdBcq4eYksmDnt7W4Aw1Og_Fragment = {};

type IndexSummary_Fragment_Edd0aXvBi0cut4uctjHtqLIrf9etb8qKttKf1TYqDk_Fragment = { indexSummary: string };

export type IndexSummary_FragmentFragment = IndexSummary_Fragment_FdsAxNsnMHdjKx5xENmdBcq4eYksmDnt7W4Aw1Og_Fragment | IndexSummary_Fragment_Edd0aXvBi0cut4uctjHtqLIrf9etb8qKttKf1TYqDk_Fragment;

type MainNeo_Fragment_AT83zGjuDjW62dlyT1X4WwGGo5rdqxsBqdWJyfm4nM_Fragment = (
  { id: string }
  & { __typename: 'hero_articleHeader_BlockType' | 'hero_baseHero_BlockType' | 'hero_featureHero_BlockType' | 'hero_imageHero_BlockType' | 'hero_personHeader_BlockType' | 'hero_splitHero_BlockType' | 'hero_textHero_BlockType' }
);

type MainNeo_Fragment_Main_AboutTheAuthor_BlockType_Main_PeopleSearch_BlockType_Fragment = (
  { anchor: string, id: string }
  & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
);

type MainNeo_Fragment_Main_Accordion_BlockType_Fragment = (
  { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
  & { __typename: 'main_accordion_BlockType' }
);

type MainNeo_Fragment_Main_Accreditations_BlockType_Fragment = (
  { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
  & { __typename: 'main_accreditations_BlockType' }
);

type MainNeo_Fragment_Main_CaseHighlights_BlockType_Fragment = (
  { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
  & { __typename: 'main_caseHighlights_BlockType' }
);

type MainNeo_Fragment_Main_CookieDeclaration_BlockType_Fragment = (
  { anchor: string, spacing: string, id: string }
  & { __typename: 'main_cookieDeclaration_BlockType' }
);

type MainNeo_Fragment_Main_Cta_BlockType_Fragment = (
  { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
  & { __typename: 'main_cta_BlockType' }
);

type MainNeo_Fragment_Main_DetailedCta_BlockType_Fragment = (
  { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
  & { __typename: 'main_detailedCta_BlockType' }
);

type MainNeo_Fragment_Main_Events_BlockType_Fragment = (
  { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
  & { __typename: 'main_events_BlockType' }
);

type MainNeo_Fragment_Main_Form_BlockType_Fragment = (
  { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
          { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Address' }
        ) | (
          { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Agree' }
        ) | (
          { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
        ) | (
          { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
        ) | (
          { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Dropdown' }
        ) | (
          { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Entries' }
        ) | (
          { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
            { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Address' }
          ) | (
            { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Agree' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
          ) | (
            { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Dropdown' }
          ) | (
            { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Entries' }
          ) | (
            { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Heading' }
          ) | (
            { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Html' }
          ) | (
            { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
          ) | (
            { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Name' }
          ) | (
            { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Number' }
          ) | (
            { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Section' }
          )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Group' }
        ) | (
          { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Heading' }
        ) | (
          { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Html' }
        ) | (
          { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
        ) | (
          { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Name' }
        ) | (
          { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Number' }
        ) | (
          { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
          & { __typename: 'Field_Section' }
        )> }> }> }> }
  & { __typename: 'main_form_BlockType' }
);

type MainNeo_Fragment_Main_FullWidthImage_BlockType_Fragment = (
  { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
  & { __typename: 'main_fullWidthImage_BlockType' }
);

type MainNeo_Fragment_Main_HeadingCards_BlockType_Fragment = (
  { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
  & { __typename: 'main_headingCards_BlockType' }
);

type MainNeo_Fragment_Main_ImageCards_BlockType_Fragment = (
  { anchor: string, id: string, imageCardEntries: Array<(
    { id: string, title: string, url: string }
    & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
  ) | (
    { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
  )> }
  & { __typename: 'main_imageCards_BlockType' }
);

type MainNeo_Fragment_Main_Image_BlockType_Fragment = (
  { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
  & { __typename: 'main_image_BlockType' }
);

type MainNeo_Fragment_Main_Insights_BlockType_Fragment = (
  { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
  & { __typename: 'main_insights_BlockType' }
);

type MainNeo_Fragment_Main_Map_BlockType_Fragment = (
  { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
  & { __typename: 'main_map_BlockType' }
);

type MainNeo_Fragment_Main_MultiColumnList_BlockType_Fragment = (
  { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
  & { __typename: 'main_multiColumnList_BlockType' }
);

type MainNeo_Fragment_Main_People_BlockType_Fragment = (
  { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
  & { __typename: 'main_people_BlockType' }
);

type MainNeo_Fragment_Main_RichText_BlockType_Fragment = (
  { richText: string, spacing: string, anchor: string, id: string }
  & { __typename: 'main_richText_BlockType' }
);

type MainNeo_Fragment_Main_SectionNavigation_BlockType_Fragment = (
  { richText: string, anchor: string, id: string }
  & { __typename: 'main_sectionNavigation_BlockType' }
);

type MainNeo_Fragment_Main_StandardCards_BlockType_Fragment = (
  { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
    { id: string, title: string, url: string }
    & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
  ) | (
    { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
  )> }
  & { __typename: 'main_standardCards_BlockType' }
);

type MainNeo_Fragment_Main_Statistics_BlockType_Fragment = (
  { spacing: string, anchor: string, id: string }
  & { __typename: 'main_statistics_BlockType' }
);

type MainNeo_Fragment_Main_SummaryCards_BlockType_Fragment = (
  { spacing: string, anchor: string, id: string, entries: Array<(
    { id: string, title: string, url: string }
    & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
  ) | (
    { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
    & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
  )> }
  & { __typename: 'main_summaryCards_BlockType' }
);

type MainNeo_Fragment_Main_Testimonials_BlockType_Fragment = (
  { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
  & { __typename: 'main_testimonials_BlockType' }
);

type MainNeo_Fragment_Main_TextImageSplit_BlockType_Fragment = (
  { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
  & { __typename: 'main_textImageSplit_BlockType' }
);

type MainNeo_Fragment_Main_Timeline_BlockType_Fragment = (
  { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
  & { __typename: 'main_timeline_BlockType' }
);

type MainNeo_Fragment_Main_Usps_BlockType_Fragment = (
  { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
  & { __typename: 'main_usps_BlockType' }
);

type MainNeo_Fragment_Main_Video_BlockType_Fragment = (
  { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
  & { __typename: 'main_video_BlockType' }
);

export type MainNeo_FragmentFragment = MainNeo_Fragment_AT83zGjuDjW62dlyT1X4WwGGo5rdqxsBqdWJyfm4nM_Fragment | MainNeo_Fragment_Main_AboutTheAuthor_BlockType_Main_PeopleSearch_BlockType_Fragment | MainNeo_Fragment_Main_Accordion_BlockType_Fragment | MainNeo_Fragment_Main_Accreditations_BlockType_Fragment | MainNeo_Fragment_Main_CaseHighlights_BlockType_Fragment | MainNeo_Fragment_Main_CookieDeclaration_BlockType_Fragment | MainNeo_Fragment_Main_Cta_BlockType_Fragment | MainNeo_Fragment_Main_DetailedCta_BlockType_Fragment | MainNeo_Fragment_Main_Events_BlockType_Fragment | MainNeo_Fragment_Main_Form_BlockType_Fragment | MainNeo_Fragment_Main_FullWidthImage_BlockType_Fragment | MainNeo_Fragment_Main_HeadingCards_BlockType_Fragment | MainNeo_Fragment_Main_ImageCards_BlockType_Fragment | MainNeo_Fragment_Main_Image_BlockType_Fragment | MainNeo_Fragment_Main_Insights_BlockType_Fragment | MainNeo_Fragment_Main_Map_BlockType_Fragment | MainNeo_Fragment_Main_MultiColumnList_BlockType_Fragment | MainNeo_Fragment_Main_People_BlockType_Fragment | MainNeo_Fragment_Main_RichText_BlockType_Fragment | MainNeo_Fragment_Main_SectionNavigation_BlockType_Fragment | MainNeo_Fragment_Main_StandardCards_BlockType_Fragment | MainNeo_Fragment_Main_Statistics_BlockType_Fragment | MainNeo_Fragment_Main_SummaryCards_BlockType_Fragment | MainNeo_Fragment_Main_Testimonials_BlockType_Fragment | MainNeo_Fragment_Main_TextImageSplit_BlockType_Fragment | MainNeo_Fragment_Main_Timeline_BlockType_Fragment | MainNeo_Fragment_Main_Usps_BlockType_Fragment | MainNeo_Fragment_Main_Video_BlockType_Fragment;

type Page_Fragment_BbAvfhxk7LPkmSqXiDs2LoGzJfoq6FqXzDTc9vsm69c_Fragment = (
  { id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, seomatic: { metaJsonLdContainer: string } }
  & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'mapMarkers_default_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
);

type Page_Fragment_36C6x50ovF85nvFp22cPBotBnYdeUiIfVip82gXEo4_Fragment = (
  { id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, hero: Array<(
    { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
    & { __typename: 'hero_articleHeader_BlockType' }
  ) | (
    { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'hero_baseHero_BlockType' }
  ) | (
    { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
    & { __typename: 'hero_featureHero_BlockType' }
  ) | (
    { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
    & { __typename: 'hero_imageHero_BlockType' }
  ) | (
    { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'hero_personHeader_BlockType' }
  ) | (
    { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'hero_splitHero_BlockType' }
  ) | (
    { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'hero_textHero_BlockType' }
  )>, main: Array<(
    { anchor: string, id: string }
    & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
    & { __typename: 'main_accordion_BlockType' }
  ) | (
    { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'main_accreditations_BlockType' }
  ) | (
    { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_caseHighlights_BlockType' }
  ) | (
    { anchor: string, spacing: string, id: string }
    & { __typename: 'main_cookieDeclaration_BlockType' }
  ) | (
    { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_cta_BlockType' }
  ) | (
    { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_detailedCta_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_events_BlockType' }
  ) | (
    { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
            { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Address' }
          ) | (
            { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Agree' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
          ) | (
            { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Dropdown' }
          ) | (
            { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Entries' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Group' }
          ) | (
            { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Heading' }
          ) | (
            { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Html' }
          ) | (
            { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
          ) | (
            { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Name' }
          ) | (
            { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Number' }
          ) | (
            { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Section' }
          )> }> }> }> }
    & { __typename: 'main_form_BlockType' }
  ) | (
    { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
    & { __typename: 'main_fullWidthImage_BlockType' }
  ) | (
    { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
    & { __typename: 'main_headingCards_BlockType' }
  ) | (
    { anchor: string, id: string, imageCardEntries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_imageCards_BlockType' }
  ) | (
    { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'main_image_BlockType' }
  ) | (
    { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_insights_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
    & { __typename: 'main_map_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
    & { __typename: 'main_multiColumnList_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_people_BlockType' }
  ) | (
    { richText: string, spacing: string, anchor: string, id: string }
    & { __typename: 'main_richText_BlockType' }
  ) | (
    { richText: string, anchor: string, id: string }
    & { __typename: 'main_sectionNavigation_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_standardCards_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string }
    & { __typename: 'main_statistics_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string, entries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_summaryCards_BlockType' }
  ) | (
    { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
    & { __typename: 'main_testimonials_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'main_textImageSplit_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_timeline_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'main_usps_BlockType' }
  ) | (
    { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'main_video_BlockType' }
  )>, seomatic: { metaJsonLdContainer: string } }
  & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'peopleIndex_peopleIndex_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
);

type Page_Fragment_General_MirroredEntry_Entry_Sectors_MirroredEntry_Entry_Services_MirroredEntry_Entry_Fragment = (
  { id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, mirroredEntry: Array<{ uri: string, siteHandle: string } | { uri: string, siteHandle: string }>, seomatic: { metaJsonLdContainer: string } }
  & { __typename: 'general_mirroredEntry_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' }
);

type Page_Fragment_Insights_Default_Entry_Fragment = (
  { url: string, postDate: any, enableRelatedInsights: boolean, id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, postDateFormatted: any, hero: Array<(
    { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
    & { __typename: 'hero_articleHeader_BlockType' }
  ) | (
    { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'hero_baseHero_BlockType' }
  ) | (
    { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
    & { __typename: 'hero_featureHero_BlockType' }
  ) | (
    { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
    & { __typename: 'hero_imageHero_BlockType' }
  ) | (
    { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'hero_personHeader_BlockType' }
  ) | (
    { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'hero_splitHero_BlockType' }
  ) | (
    { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'hero_textHero_BlockType' }
  )>, main: Array<(
    { anchor: string, id: string }
    & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
    & { __typename: 'main_accordion_BlockType' }
  ) | (
    { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'main_accreditations_BlockType' }
  ) | (
    { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_caseHighlights_BlockType' }
  ) | (
    { anchor: string, spacing: string, id: string }
    & { __typename: 'main_cookieDeclaration_BlockType' }
  ) | (
    { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_cta_BlockType' }
  ) | (
    { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_detailedCta_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_events_BlockType' }
  ) | (
    { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
            { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Address' }
          ) | (
            { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Agree' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
          ) | (
            { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Dropdown' }
          ) | (
            { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Entries' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Group' }
          ) | (
            { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Heading' }
          ) | (
            { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Html' }
          ) | (
            { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
          ) | (
            { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Name' }
          ) | (
            { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Number' }
          ) | (
            { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Section' }
          )> }> }> }> }
    & { __typename: 'main_form_BlockType' }
  ) | (
    { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
    & { __typename: 'main_fullWidthImage_BlockType' }
  ) | (
    { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
    & { __typename: 'main_headingCards_BlockType' }
  ) | (
    { anchor: string, id: string, imageCardEntries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_imageCards_BlockType' }
  ) | (
    { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'main_image_BlockType' }
  ) | (
    { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_insights_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
    & { __typename: 'main_map_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
    & { __typename: 'main_multiColumnList_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_people_BlockType' }
  ) | (
    { richText: string, spacing: string, anchor: string, id: string }
    & { __typename: 'main_richText_BlockType' }
  ) | (
    { richText: string, anchor: string, id: string }
    & { __typename: 'main_sectionNavigation_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_standardCards_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string }
    & { __typename: 'main_statistics_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string, entries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_summaryCards_BlockType' }
  ) | (
    { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
    & { __typename: 'main_testimonials_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'main_textImageSplit_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_timeline_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'main_usps_BlockType' }
  ) | (
    { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'main_video_BlockType' }
  )>, insightCategories: Array<{ id: string, title: string, url: string } | { id: string, title: string, url: string }>, people: Array<{ id: string, title: string, url: string } | { id: string, title: string, url: string }>, seomatic: { metaJsonLdContainer: string } }
  & { __typename: 'insights_default_Entry' }
);

type Page_Fragment_Offices_Default_Entry_Fragment = (
  { email: string, id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, hero: Array<(
    { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
    & { __typename: 'hero_articleHeader_BlockType' }
  ) | (
    { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'hero_baseHero_BlockType' }
  ) | (
    { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
    & { __typename: 'hero_featureHero_BlockType' }
  ) | (
    { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
    & { __typename: 'hero_imageHero_BlockType' }
  ) | (
    { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'hero_personHeader_BlockType' }
  ) | (
    { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'hero_splitHero_BlockType' }
  ) | (
    { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'hero_textHero_BlockType' }
  )>, main: Array<(
    { anchor: string, id: string }
    & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
    & { __typename: 'main_accordion_BlockType' }
  ) | (
    { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'main_accreditations_BlockType' }
  ) | (
    { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_caseHighlights_BlockType' }
  ) | (
    { anchor: string, spacing: string, id: string }
    & { __typename: 'main_cookieDeclaration_BlockType' }
  ) | (
    { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_cta_BlockType' }
  ) | (
    { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_detailedCta_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_events_BlockType' }
  ) | (
    { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
            { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Address' }
          ) | (
            { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Agree' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
          ) | (
            { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Dropdown' }
          ) | (
            { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Entries' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Group' }
          ) | (
            { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Heading' }
          ) | (
            { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Html' }
          ) | (
            { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
          ) | (
            { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Name' }
          ) | (
            { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Number' }
          ) | (
            { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Section' }
          )> }> }> }> }
    & { __typename: 'main_form_BlockType' }
  ) | (
    { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
    & { __typename: 'main_fullWidthImage_BlockType' }
  ) | (
    { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
    & { __typename: 'main_headingCards_BlockType' }
  ) | (
    { anchor: string, id: string, imageCardEntries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_imageCards_BlockType' }
  ) | (
    { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'main_image_BlockType' }
  ) | (
    { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_insights_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
    & { __typename: 'main_map_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
    & { __typename: 'main_multiColumnList_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_people_BlockType' }
  ) | (
    { richText: string, spacing: string, anchor: string, id: string }
    & { __typename: 'main_richText_BlockType' }
  ) | (
    { richText: string, anchor: string, id: string }
    & { __typename: 'main_sectionNavigation_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_standardCards_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string }
    & { __typename: 'main_statistics_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string, entries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_summaryCards_BlockType' }
  ) | (
    { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
    & { __typename: 'main_testimonials_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'main_textImageSplit_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_timeline_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'main_usps_BlockType' }
  ) | (
    { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'main_video_BlockType' }
  )>, telephone: { number: string }, seomatic: { metaJsonLdContainer: string } }
  & { __typename: 'offices_default_Entry' }
);

type Page_Fragment_People_Default_Entry_Fragment = (
  { email: string, specialisms: string, linkedin: string, twitter: string, id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, hero: Array<(
    { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
    & { __typename: 'hero_articleHeader_BlockType' }
  ) | (
    { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'hero_baseHero_BlockType' }
  ) | (
    { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
    & { __typename: 'hero_featureHero_BlockType' }
  ) | (
    { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
    & { __typename: 'hero_imageHero_BlockType' }
  ) | (
    { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'hero_personHeader_BlockType' }
  ) | (
    { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'hero_splitHero_BlockType' }
  ) | (
    { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'hero_textHero_BlockType' }
  )>, main: Array<(
    { anchor: string, id: string }
    & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
    & { __typename: 'main_accordion_BlockType' }
  ) | (
    { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'main_accreditations_BlockType' }
  ) | (
    { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_caseHighlights_BlockType' }
  ) | (
    { anchor: string, spacing: string, id: string }
    & { __typename: 'main_cookieDeclaration_BlockType' }
  ) | (
    { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_cta_BlockType' }
  ) | (
    { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_detailedCta_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_events_BlockType' }
  ) | (
    { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
            { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Address' }
          ) | (
            { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Agree' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
          ) | (
            { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Dropdown' }
          ) | (
            { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Entries' }
          ) | (
            { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Group' }
          ) | (
            { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Heading' }
          ) | (
            { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Html' }
          ) | (
            { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
          ) | (
            { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Name' }
          ) | (
            { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Number' }
          ) | (
            { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
            & { __typename: 'Field_Section' }
          )> }> }> }> }
    & { __typename: 'main_form_BlockType' }
  ) | (
    { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
    & { __typename: 'main_fullWidthImage_BlockType' }
  ) | (
    { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
    & { __typename: 'main_headingCards_BlockType' }
  ) | (
    { anchor: string, id: string, imageCardEntries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_imageCards_BlockType' }
  ) | (
    { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'main_image_BlockType' }
  ) | (
    { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_insights_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
    & { __typename: 'main_map_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
    & { __typename: 'main_multiColumnList_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
    & { __typename: 'main_people_BlockType' }
  ) | (
    { richText: string, spacing: string, anchor: string, id: string }
    & { __typename: 'main_richText_BlockType' }
  ) | (
    { richText: string, anchor: string, id: string }
    & { __typename: 'main_sectionNavigation_BlockType' }
  ) | (
    { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_standardCards_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string }
    & { __typename: 'main_statistics_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string, entries: Array<(
      { id: string, title: string, url: string }
      & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
    ) | (
      { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
      & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
    )> }
    & { __typename: 'main_summaryCards_BlockType' }
  ) | (
    { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
    & { __typename: 'main_testimonials_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
    & { __typename: 'main_textImageSplit_BlockType' }
  ) | (
    { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
    & { __typename: 'main_timeline_BlockType' }
  ) | (
    { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
    & { __typename: 'main_usps_BlockType' }
  ) | (
    { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
    & { __typename: 'main_video_BlockType' }
  )>, telephone: { number: string }, mobile: { number: string }, jobTitles: Array<{ title: string }>, seomatic: { metaJsonLdContainer: string } }
  & { __typename: 'people_default_Entry' }
);

export type Page_FragmentFragment = Page_Fragment_BbAvfhxk7LPkmSqXiDs2LoGzJfoq6FqXzDTc9vsm69c_Fragment | Page_Fragment_36C6x50ovF85nvFp22cPBotBnYdeUiIfVip82gXEo4_Fragment | Page_Fragment_General_MirroredEntry_Entry_Sectors_MirroredEntry_Entry_Services_MirroredEntry_Entry_Fragment | Page_Fragment_Insights_Default_Entry_Fragment | Page_Fragment_Offices_Default_Entry_Fragment | Page_Fragment_People_Default_Entry_Fragment;

type RelatedInsight_Fragment_8MfRzKbRqxbDrdBsKinPfhuNaNi4kyxGmdL90NaQug_Fragment = {};

type RelatedInsight_Fragment_K0IosW2YfTfAlvv9UonY9aKtU6tmDdMkmIexlTmtdcM_Fragment = {};

type RelatedInsight_Fragment_Insights_Default_Entry_Fragment = { id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> };

export type RelatedInsight_FragmentFragment = RelatedInsight_Fragment_8MfRzKbRqxbDrdBsKinPfhuNaNi4kyxGmdL90NaQug_Fragment | RelatedInsight_Fragment_K0IosW2YfTfAlvv9UonY9aKtU6tmDdMkmIexlTmtdcM_Fragment | RelatedInsight_Fragment_Insights_Default_Entry_Fragment;

export type Seomatic_FragmentFragment = { metaTitleContainer: string, metaTagContainer: string, metaLinkContainer: string, metaScriptContainer: string, metaSiteVarsContainer: string };

type StandardCardsImage_Fragment_FdsAxNsnMHdjKx5xENmdBcq4eYksmDnt7W4Aw1Og_Fragment = {};

type StandardCardsImage_Fragment_Edd0aXvBi0cut4uctjHtqLIrf9etb8qKttKf1TYqDk_Fragment = { fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> };

export type StandardCardsImage_FragmentFragment = StandardCardsImage_Fragment_FdsAxNsnMHdjKx5xENmdBcq4eYksmDnt7W4Aw1Og_Fragment | StandardCardsImage_Fragment_Edd0aXvBi0cut4uctjHtqLIrf9etb8qKttKf1TYqDk_Fragment;

export type GetAllOfficesQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllOfficesQuery = { offices: Array<{ id: string, title: string, url: string, telephone: { number: string } }> };

export type GetAllPageUrisQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPageUrisQuery = { pages: Array<{ siteHandle: string, uri: string } | { siteHandle: string, uri: string }> };

export type GetFooterNavQueryVariables = Exact<{
  navHandle: InputMaybe<Scalars['String']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetFooterNavQuery = { nodes: Array<{ id: string, title: string, url: string, newWindow: string, customAttributes: Array<{ attribute: string, value: string }> }> };

export type GetHeaderNavQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetHeaderNavQuery = { nodes: Array<{ id: string, title: string, url: string, newWindow: string, element: { id: string } | { id: string } | { id: string } | { sectionHandle: string, id: string } | { sectionHandle: string, id: string }, customAttributes: Array<{ attribute: string, value: string }>, children: Array<{ id: string, title: string, url: string, newWindow: string, element: { id: string } | { id: string } | { id: string } | { sectionHandle: string, id: string } | { sectionHandle: string, id: string }, customAttributes: Array<{ attribute: string, value: string }>, children: Array<{ id: string, title: string, url: string, newWindow: string, customAttributes: Array<{ attribute: string, value: string }>, element: { id: string } | { id: string } | { id: string } | { sectionHandle: string, id: string } | { sectionHandle: string, id: string } }> }> }> };

export type GetInsightSeoWithSlugAndCategoryQueryVariables = Exact<{
  insightSlug: InputMaybe<Scalars['String']['input']>;
  categorySlug: InputMaybe<Scalars['String']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetInsightSeoWithSlugAndCategoryQuery = { page: (
    { uri: string, seomatic: { metaTitleContainer: string, metaTagContainer: string, metaLinkContainer: string, metaScriptContainer: string, metaSiteVarsContainer: string } }
    & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'careers_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'general_mirroredEntry_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'mapMarkers_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'sectors_mirroredEntry_Entry' }
  ) | (
    { uri: string, seomatic: { metaTitleContainer: string, metaTagContainer: string, metaLinkContainer: string, metaScriptContainer: string, metaSiteVarsContainer: string } }
    & { __typename: 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
  ) };

export type GetInsightWithSlugAndCategoryQueryVariables = Exact<{
  insightSlug: InputMaybe<Scalars['String']['input']>;
  categorySlug: InputMaybe<Scalars['String']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetInsightWithSlugAndCategoryQuery = { page: (
    { id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'mapMarkers_default_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
  ) | (
    { id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, hero: Array<(
      { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
      & { __typename: 'hero_articleHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_baseHero_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_featureHero_BlockType' }
    ) | (
      { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_imageHero_BlockType' }
    ) | (
      { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'hero_personHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_splitHero_BlockType' }
    ) | (
      { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'hero_textHero_BlockType' }
    )>, main: Array<(
      { anchor: string, id: string }
      & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
      & { __typename: 'main_accordion_BlockType' }
    ) | (
      { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_accreditations_BlockType' }
    ) | (
      { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_caseHighlights_BlockType' }
    ) | (
      { anchor: string, spacing: string, id: string }
      & { __typename: 'main_cookieDeclaration_BlockType' }
    ) | (
      { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_cta_BlockType' }
    ) | (
      { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_detailedCta_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_events_BlockType' }
    ) | (
      { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
                { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Address' }
              ) | (
                { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Agree' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
              ) | (
                { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Dropdown' }
              ) | (
                { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Entries' }
              ) | (
                { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Heading' }
              ) | (
                { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Html' }
              ) | (
                { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
              ) | (
                { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Name' }
              ) | (
                { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Number' }
              ) | (
                { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Section' }
              )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Group' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )> }> }> }> }
      & { __typename: 'main_form_BlockType' }
    ) | (
      { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'main_fullWidthImage_BlockType' }
    ) | (
      { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
      & { __typename: 'main_headingCards_BlockType' }
    ) | (
      { anchor: string, id: string, imageCardEntries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_imageCards_BlockType' }
    ) | (
      { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_image_BlockType' }
    ) | (
      { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_insights_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
      & { __typename: 'main_map_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
      & { __typename: 'main_multiColumnList_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_people_BlockType' }
    ) | (
      { richText: string, spacing: string, anchor: string, id: string }
      & { __typename: 'main_richText_BlockType' }
    ) | (
      { richText: string, anchor: string, id: string }
      & { __typename: 'main_sectionNavigation_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_standardCards_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string }
      & { __typename: 'main_statistics_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_summaryCards_BlockType' }
    ) | (
      { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
      & { __typename: 'main_testimonials_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'main_textImageSplit_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_timeline_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_usps_BlockType' }
    ) | (
      { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_video_BlockType' }
    )>, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'peopleIndex_peopleIndex_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
  ) | (
    { id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, mirroredEntry: Array<{ uri: string, siteHandle: string } | { uri: string, siteHandle: string }>, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'general_mirroredEntry_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' }
  ) | (
    { url: string, postDate: any, enableRelatedInsights: boolean, id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, postDateFormatted: any, hero: Array<(
      { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
      & { __typename: 'hero_articleHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_baseHero_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_featureHero_BlockType' }
    ) | (
      { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_imageHero_BlockType' }
    ) | (
      { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'hero_personHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_splitHero_BlockType' }
    ) | (
      { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'hero_textHero_BlockType' }
    )>, main: Array<(
      { anchor: string, id: string }
      & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
      & { __typename: 'main_accordion_BlockType' }
    ) | (
      { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_accreditations_BlockType' }
    ) | (
      { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_caseHighlights_BlockType' }
    ) | (
      { anchor: string, spacing: string, id: string }
      & { __typename: 'main_cookieDeclaration_BlockType' }
    ) | (
      { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_cta_BlockType' }
    ) | (
      { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_detailedCta_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_events_BlockType' }
    ) | (
      { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
                { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Address' }
              ) | (
                { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Agree' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
              ) | (
                { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Dropdown' }
              ) | (
                { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Entries' }
              ) | (
                { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Heading' }
              ) | (
                { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Html' }
              ) | (
                { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
              ) | (
                { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Name' }
              ) | (
                { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Number' }
              ) | (
                { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Section' }
              )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Group' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )> }> }> }> }
      & { __typename: 'main_form_BlockType' }
    ) | (
      { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'main_fullWidthImage_BlockType' }
    ) | (
      { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
      & { __typename: 'main_headingCards_BlockType' }
    ) | (
      { anchor: string, id: string, imageCardEntries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_imageCards_BlockType' }
    ) | (
      { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_image_BlockType' }
    ) | (
      { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_insights_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
      & { __typename: 'main_map_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
      & { __typename: 'main_multiColumnList_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_people_BlockType' }
    ) | (
      { richText: string, spacing: string, anchor: string, id: string }
      & { __typename: 'main_richText_BlockType' }
    ) | (
      { richText: string, anchor: string, id: string }
      & { __typename: 'main_sectionNavigation_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_standardCards_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string }
      & { __typename: 'main_statistics_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_summaryCards_BlockType' }
    ) | (
      { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
      & { __typename: 'main_testimonials_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'main_textImageSplit_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_timeline_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_usps_BlockType' }
    ) | (
      { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_video_BlockType' }
    )>, insightCategories: Array<{ id: string, title: string, url: string } | { id: string, title: string, url: string }>, people: Array<{ id: string, title: string, url: string } | { id: string, title: string, url: string }>, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'insights_default_Entry' }
  ) | (
    { email: string, id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, hero: Array<(
      { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
      & { __typename: 'hero_articleHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_baseHero_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_featureHero_BlockType' }
    ) | (
      { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_imageHero_BlockType' }
    ) | (
      { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'hero_personHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_splitHero_BlockType' }
    ) | (
      { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'hero_textHero_BlockType' }
    )>, main: Array<(
      { anchor: string, id: string }
      & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
      & { __typename: 'main_accordion_BlockType' }
    ) | (
      { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_accreditations_BlockType' }
    ) | (
      { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_caseHighlights_BlockType' }
    ) | (
      { anchor: string, spacing: string, id: string }
      & { __typename: 'main_cookieDeclaration_BlockType' }
    ) | (
      { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_cta_BlockType' }
    ) | (
      { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_detailedCta_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_events_BlockType' }
    ) | (
      { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
                { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Address' }
              ) | (
                { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Agree' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
              ) | (
                { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Dropdown' }
              ) | (
                { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Entries' }
              ) | (
                { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Heading' }
              ) | (
                { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Html' }
              ) | (
                { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
              ) | (
                { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Name' }
              ) | (
                { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Number' }
              ) | (
                { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Section' }
              )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Group' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )> }> }> }> }
      & { __typename: 'main_form_BlockType' }
    ) | (
      { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'main_fullWidthImage_BlockType' }
    ) | (
      { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
      & { __typename: 'main_headingCards_BlockType' }
    ) | (
      { anchor: string, id: string, imageCardEntries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_imageCards_BlockType' }
    ) | (
      { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_image_BlockType' }
    ) | (
      { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_insights_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
      & { __typename: 'main_map_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
      & { __typename: 'main_multiColumnList_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_people_BlockType' }
    ) | (
      { richText: string, spacing: string, anchor: string, id: string }
      & { __typename: 'main_richText_BlockType' }
    ) | (
      { richText: string, anchor: string, id: string }
      & { __typename: 'main_sectionNavigation_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_standardCards_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string }
      & { __typename: 'main_statistics_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_summaryCards_BlockType' }
    ) | (
      { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
      & { __typename: 'main_testimonials_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'main_textImageSplit_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_timeline_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_usps_BlockType' }
    ) | (
      { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_video_BlockType' }
    )>, telephone: { number: string }, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'offices_default_Entry' }
  ) | (
    { email: string, specialisms: string, linkedin: string, twitter: string, id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, hero: Array<(
      { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
      & { __typename: 'hero_articleHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_baseHero_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_featureHero_BlockType' }
    ) | (
      { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_imageHero_BlockType' }
    ) | (
      { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'hero_personHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_splitHero_BlockType' }
    ) | (
      { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'hero_textHero_BlockType' }
    )>, main: Array<(
      { anchor: string, id: string }
      & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
      & { __typename: 'main_accordion_BlockType' }
    ) | (
      { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_accreditations_BlockType' }
    ) | (
      { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_caseHighlights_BlockType' }
    ) | (
      { anchor: string, spacing: string, id: string }
      & { __typename: 'main_cookieDeclaration_BlockType' }
    ) | (
      { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_cta_BlockType' }
    ) | (
      { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_detailedCta_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_events_BlockType' }
    ) | (
      { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
                { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Address' }
              ) | (
                { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Agree' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
              ) | (
                { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Dropdown' }
              ) | (
                { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Entries' }
              ) | (
                { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Heading' }
              ) | (
                { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Html' }
              ) | (
                { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
              ) | (
                { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Name' }
              ) | (
                { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Number' }
              ) | (
                { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Section' }
              )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Group' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )> }> }> }> }
      & { __typename: 'main_form_BlockType' }
    ) | (
      { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'main_fullWidthImage_BlockType' }
    ) | (
      { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
      & { __typename: 'main_headingCards_BlockType' }
    ) | (
      { anchor: string, id: string, imageCardEntries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_imageCards_BlockType' }
    ) | (
      { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_image_BlockType' }
    ) | (
      { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_insights_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
      & { __typename: 'main_map_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
      & { __typename: 'main_multiColumnList_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_people_BlockType' }
    ) | (
      { richText: string, spacing: string, anchor: string, id: string }
      & { __typename: 'main_richText_BlockType' }
    ) | (
      { richText: string, anchor: string, id: string }
      & { __typename: 'main_sectionNavigation_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_standardCards_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string }
      & { __typename: 'main_statistics_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_summaryCards_BlockType' }
    ) | (
      { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
      & { __typename: 'main_testimonials_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'main_textImageSplit_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_timeline_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_usps_BlockType' }
    ) | (
      { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_video_BlockType' }
    )>, telephone: { number: string }, mobile: { number: string }, jobTitles: Array<{ title: string }>, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'people_default_Entry' }
  ) };

export type GetLatestInsightsQueryVariables = Exact<{
  limit: InputMaybe<Scalars['Int']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetLatestInsightsQuery = { insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> };

export type GetPageSeoWithUriQueryVariables = Exact<{
  uri: InputMaybe<Scalars['String']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPageSeoWithUriQuery = { page: (
    { uri: string, seomatic: { metaTitleContainer: string, metaTagContainer: string, metaLinkContainer: string, metaScriptContainer: string, metaSiteVarsContainer: string } }
    & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'careers_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'mapMarkers_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
  ) | (
    { uri: string, seomatic: { metaTitleContainer: string, metaTagContainer: string, metaLinkContainer: string, metaScriptContainer: string, metaSiteVarsContainer: string } }
    & { __typename: 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
  ) | (
    { uri: string, mirroredEntry: Array<{ uri: string, siteHandle: string } | { uri: string, siteHandle: string }>, seomatic: { metaTitleContainer: string, metaTagContainer: string, metaLinkContainer: string, metaScriptContainer: string, metaSiteVarsContainer: string } }
    & { __typename: 'general_mirroredEntry_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' }
  ) };

export type GetPageTitlesWithUrisQueryVariables = Exact<{
  uris: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPageTitlesWithUrisQuery = { pages: Array<{ title: string, uri: string } | { title: string, uri: string }> };

export type GetPageWithUriQueryVariables = Exact<{
  uri: InputMaybe<Scalars['String']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPageWithUriQuery = { page: (
    { id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'mapMarkers_default_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
  ) | (
    { id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, hero: Array<(
      { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
      & { __typename: 'hero_articleHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_baseHero_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_featureHero_BlockType' }
    ) | (
      { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_imageHero_BlockType' }
    ) | (
      { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'hero_personHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_splitHero_BlockType' }
    ) | (
      { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'hero_textHero_BlockType' }
    )>, main: Array<(
      { anchor: string, id: string }
      & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
      & { __typename: 'main_accordion_BlockType' }
    ) | (
      { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_accreditations_BlockType' }
    ) | (
      { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_caseHighlights_BlockType' }
    ) | (
      { anchor: string, spacing: string, id: string }
      & { __typename: 'main_cookieDeclaration_BlockType' }
    ) | (
      { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_cta_BlockType' }
    ) | (
      { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_detailedCta_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_events_BlockType' }
    ) | (
      { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
                { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Address' }
              ) | (
                { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Agree' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
              ) | (
                { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Dropdown' }
              ) | (
                { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Entries' }
              ) | (
                { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Heading' }
              ) | (
                { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Html' }
              ) | (
                { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
              ) | (
                { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Name' }
              ) | (
                { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Number' }
              ) | (
                { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Section' }
              )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Group' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )> }> }> }> }
      & { __typename: 'main_form_BlockType' }
    ) | (
      { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'main_fullWidthImage_BlockType' }
    ) | (
      { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
      & { __typename: 'main_headingCards_BlockType' }
    ) | (
      { anchor: string, id: string, imageCardEntries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_imageCards_BlockType' }
    ) | (
      { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_image_BlockType' }
    ) | (
      { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_insights_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
      & { __typename: 'main_map_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
      & { __typename: 'main_multiColumnList_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_people_BlockType' }
    ) | (
      { richText: string, spacing: string, anchor: string, id: string }
      & { __typename: 'main_richText_BlockType' }
    ) | (
      { richText: string, anchor: string, id: string }
      & { __typename: 'main_sectionNavigation_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_standardCards_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string }
      & { __typename: 'main_statistics_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_summaryCards_BlockType' }
    ) | (
      { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
      & { __typename: 'main_testimonials_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'main_textImageSplit_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_timeline_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_usps_BlockType' }
    ) | (
      { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_video_BlockType' }
    )>, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'peopleIndex_peopleIndex_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
  ) | (
    { id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, mirroredEntry: Array<{ uri: string, siteHandle: string } | { uri: string, siteHandle: string }>, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'general_mirroredEntry_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' }
  ) | (
    { url: string, postDate: any, enableRelatedInsights: boolean, id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, postDateFormatted: any, hero: Array<(
      { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
      & { __typename: 'hero_articleHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_baseHero_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_featureHero_BlockType' }
    ) | (
      { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_imageHero_BlockType' }
    ) | (
      { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'hero_personHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_splitHero_BlockType' }
    ) | (
      { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'hero_textHero_BlockType' }
    )>, main: Array<(
      { anchor: string, id: string }
      & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
      & { __typename: 'main_accordion_BlockType' }
    ) | (
      { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_accreditations_BlockType' }
    ) | (
      { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_caseHighlights_BlockType' }
    ) | (
      { anchor: string, spacing: string, id: string }
      & { __typename: 'main_cookieDeclaration_BlockType' }
    ) | (
      { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_cta_BlockType' }
    ) | (
      { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_detailedCta_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_events_BlockType' }
    ) | (
      { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
                { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Address' }
              ) | (
                { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Agree' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
              ) | (
                { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Dropdown' }
              ) | (
                { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Entries' }
              ) | (
                { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Heading' }
              ) | (
                { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Html' }
              ) | (
                { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
              ) | (
                { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Name' }
              ) | (
                { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Number' }
              ) | (
                { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Section' }
              )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Group' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )> }> }> }> }
      & { __typename: 'main_form_BlockType' }
    ) | (
      { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'main_fullWidthImage_BlockType' }
    ) | (
      { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
      & { __typename: 'main_headingCards_BlockType' }
    ) | (
      { anchor: string, id: string, imageCardEntries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_imageCards_BlockType' }
    ) | (
      { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_image_BlockType' }
    ) | (
      { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_insights_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
      & { __typename: 'main_map_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
      & { __typename: 'main_multiColumnList_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_people_BlockType' }
    ) | (
      { richText: string, spacing: string, anchor: string, id: string }
      & { __typename: 'main_richText_BlockType' }
    ) | (
      { richText: string, anchor: string, id: string }
      & { __typename: 'main_sectionNavigation_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_standardCards_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string }
      & { __typename: 'main_statistics_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_summaryCards_BlockType' }
    ) | (
      { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
      & { __typename: 'main_testimonials_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'main_textImageSplit_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_timeline_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_usps_BlockType' }
    ) | (
      { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_video_BlockType' }
    )>, insightCategories: Array<{ id: string, title: string, url: string } | { id: string, title: string, url: string }>, people: Array<{ id: string, title: string, url: string } | { id: string, title: string, url: string }>, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'insights_default_Entry' }
  ) | (
    { email: string, id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, hero: Array<(
      { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
      & { __typename: 'hero_articleHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_baseHero_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_featureHero_BlockType' }
    ) | (
      { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_imageHero_BlockType' }
    ) | (
      { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'hero_personHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_splitHero_BlockType' }
    ) | (
      { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'hero_textHero_BlockType' }
    )>, main: Array<(
      { anchor: string, id: string }
      & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
      & { __typename: 'main_accordion_BlockType' }
    ) | (
      { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_accreditations_BlockType' }
    ) | (
      { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_caseHighlights_BlockType' }
    ) | (
      { anchor: string, spacing: string, id: string }
      & { __typename: 'main_cookieDeclaration_BlockType' }
    ) | (
      { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_cta_BlockType' }
    ) | (
      { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_detailedCta_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_events_BlockType' }
    ) | (
      { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
                { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Address' }
              ) | (
                { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Agree' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
              ) | (
                { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Dropdown' }
              ) | (
                { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Entries' }
              ) | (
                { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Heading' }
              ) | (
                { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Html' }
              ) | (
                { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
              ) | (
                { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Name' }
              ) | (
                { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Number' }
              ) | (
                { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Section' }
              )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Group' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )> }> }> }> }
      & { __typename: 'main_form_BlockType' }
    ) | (
      { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'main_fullWidthImage_BlockType' }
    ) | (
      { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
      & { __typename: 'main_headingCards_BlockType' }
    ) | (
      { anchor: string, id: string, imageCardEntries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_imageCards_BlockType' }
    ) | (
      { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_image_BlockType' }
    ) | (
      { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_insights_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
      & { __typename: 'main_map_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
      & { __typename: 'main_multiColumnList_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_people_BlockType' }
    ) | (
      { richText: string, spacing: string, anchor: string, id: string }
      & { __typename: 'main_richText_BlockType' }
    ) | (
      { richText: string, anchor: string, id: string }
      & { __typename: 'main_sectionNavigation_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_standardCards_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string }
      & { __typename: 'main_statistics_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_summaryCards_BlockType' }
    ) | (
      { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
      & { __typename: 'main_testimonials_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'main_textImageSplit_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_timeline_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_usps_BlockType' }
    ) | (
      { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_video_BlockType' }
    )>, telephone: { number: string }, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'offices_default_Entry' }
  ) | (
    { email: string, specialisms: string, linkedin: string, twitter: string, id: string, title: string, uri: string, slug: string, level: number, siteHandle: string, hero: Array<(
      { id: string, caption: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }> }
      & { __typename: 'hero_articleHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_baseHero_BlockType' }
    ) | (
      { heading: string, useH1: boolean, multiLineText: string, id: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, featureImage: Array<{ title: string }>, fallbackHealthImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_health_webp_xs: Array<{ srcset: string }>, img_health_webp_sm: Array<{ srcset: string }>, img_health_webp_md: Array<{ srcset: string }>, fallbackTechnologyImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_technology_webp_xs: Array<{ srcset: string }>, img_technology_webp_sm: Array<{ srcset: string }>, img_technology_webp_md: Array<{ srcset: string }>, fallbackSportsImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_sports_webp_xs: Array<{ srcset: string }>, fallbackConstructionImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_construction_webp_xs: Array<{ srcset: string }>, img_construction_webp_sm: Array<{ srcset: string }>, img_construction_webp_md: Array<{ srcset: string }>, fallbackRetailImage: Array<{ width: number, height: number, alt: string, title: string, url: string, relatedFeatureAssetParts: Array<{ width: number, height: number, alt: string, title: string, url: string }> }>, img_retail_webp_xs: Array<{ srcset: string }>, img_retail_webp_sm: Array<{ srcset: string }>, img_retail_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_featureHero_BlockType' }
    ) | (
      { useH1: boolean, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'hero_imageHero_BlockType' }
    ) | (
      { richText: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'hero_personHeader_BlockType' }
    ) | (
      { heading: string, useH1: boolean, heroColourway: string, multiLineText: string, showSocialLinks: boolean, pattern: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'hero_splitHero_BlockType' }
    ) | (
      { useLogoAsHeading: boolean, heading: string, useH1: boolean, textHeroColourway: string, id: string, subHeading: string, button: Array<{ ariaLabel: string, target: string, text: string, url: string }>, heroAccreditations: Array<{ id: string, title: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'hero_textHero_BlockType' }
    )>, main: Array<(
      { anchor: string, id: string }
      & { __typename: 'main_aboutTheAuthor_BlockType' | 'main_peopleSearch_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, accordionEntries: Array<{ id: string, heading: string, richText: string }> }
      & { __typename: 'main_accordion_BlockType' }
    ) | (
      { heading: string, headingElement: string, showLabels: boolean, enableCarousel: boolean, spacing: string, anchor: string, id: string, accreditations: Array<{ id: string, title: string, slug: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_accreditations_BlockType' }
    ) | (
      { caseHighlightsColourway: string, heading: string, headingElement: string, anchor: string, id: string, caseHighlights: Array<{ id: string, heading: string, multiLineText: string, entry: Array<{ id: string, url: string } | { id: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_caseHighlights_BlockType' }
    ) | (
      { anchor: string, spacing: string, id: string }
      & { __typename: 'main_cookieDeclaration_BlockType' }
    ) | (
      { ctaColourway: string, anchor: string, id: string, cta: Array<{ id: string, heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_cta_BlockType' }
    ) | (
      { anchor: string, id: string, detailedCta: Array<{ heading: string, headingElement: string, richText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_detailedCta_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, events: Array<{ id: string, title: string, url: string, postDate: any, indexHeading: string, indexSummary: string, indexHideEventDate: boolean, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_events_BlockType' }
    ) | (
      { heading: string, headingElement: string, formType: string, hubspotFormId: string, spacing: string, anchor: string, id: string, formieForm: Array<{ id: string, title: string, handle: string, captchas: Array<{ handle: string, name: string, value: string }>, settings: { displayFormTitle: boolean, displayPageTabs: boolean, displayCurrentPageTitle: boolean, displayPageProgress: boolean, submitAction: string, submitActionMessageHtml: string, errorMessageHtml: string }, pages: Array<{ name: string, id: string, settings: { submitButtonLabel: string, backButtonLabel: string, showBackButton: boolean, buttonsPosition: string }, rows: Array<{ rowFields: Array<(
              { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Address' }
            ) | (
              { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Agree' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
            ) | (
              { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Dropdown' }
            ) | (
              { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Entries' }
            ) | (
              { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, fields: Array<(
                { subfieldLabelPosition: string, address1Label: string, address1Enabled: boolean, address1Hidden: boolean, address1Required: boolean, address1Placeholder: string, address1DefaultValue: string, address2Label: string, address2Enabled: boolean, address2Hidden: boolean, address2Required: boolean, address2Placeholder: string, address2DefaultValue: string, address3Label: string, address3Enabled: boolean, address3Hidden: boolean, address3Required: boolean, address3Placeholder: string, address3DefaultValue: string, cityLabel: string, cityEnabled: boolean, cityHidden: boolean, cityRequired: boolean, cityPlaceholder: string, cityDefaultValue: string, stateLabel: string, stateEnabled: boolean, stateHidden: boolean, stateRequired: boolean, statePlaceholder: string, stateDefaultValue: string, zipLabel: string, zipEnabled: boolean, zipHidden: boolean, zipRequired: boolean, zipPlaceholder: string, zipDefaultValue: string, countryLabel: string, countryEnabled: boolean, countryHidden: boolean, countryRequired: boolean, countryPlaceholder: string, countryDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, countryOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Address' }
              ) | (
                { defaultState: boolean, checkedValue: string, uncheckedValue: string, descriptionHtml: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Agree' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Checkboxes' | 'Field_Radio' }
              ) | (
                { id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Date' | 'Field_Email' | 'Field_FileUpload' | 'Field_Group' | 'Field_Hidden' | 'Field_Password' | 'Field_Phone' | 'Field_Recipients' | 'Field_Repeater' | 'Field_Table' }
              ) | (
                { multi: boolean, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, options: Array<{ isDefault: boolean, label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Dropdown' }
              ) | (
                { displayType: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, entries: Array<{ id: string, title: string } | { id: string, title: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Entries' }
              ) | (
                { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Heading' }
              ) | (
                { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Html' }
              ) | (
                { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
              ) | (
                { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Name' }
              ) | (
                { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Number' }
              ) | (
                { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
                & { __typename: 'Field_Section' }
              )>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Group' }
            ) | (
              { headingSize: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Heading' }
            ) | (
              { htmlContent: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Html' }
            ) | (
              { limit: boolean, maxType: string, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_MultiLineText' | 'Field_SingleLineText' }
            ) | (
              { useMultipleFields: boolean, subfieldLabelPosition: string, prefixLabel: string, prefixEnabled: boolean, prefixRequired: boolean, prefixPlaceholder: string, prefixDefaultValue: string, firstNameLabel: string, firstNameEnabled: boolean, firstNameRequired: boolean, firstNamePlaceholder: string, firstNameDefaultValue: string, middleNameLabel: string, middleNameEnabled: boolean, middleNameRequired: boolean, middleNamePlaceholder: string, middleNameDefaultValue: string, lastNameLabel: string, lastNameEnabled: boolean, lastNameRequired: boolean, lastNamePlaceholder: string, lastNameDefaultValue: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, prefixOptions: Array<{ label: string, value: string }>, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Name' }
            ) | (
              { min: number, max: number, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Number' }
            ) | (
              { borderStyle: string, borderWidth: number, borderColor: string, id: string, name: string, handle: string, instructions: string, required: boolean, displayName: string, inputTypeName: string, placeholder: string, labelPosition: string, instructionsPosition: string, cssClasses: string, visibility: string, defaultValue: string, label: string, containerAttributes: Array<{ label: string, value: string }>, inputAttributes: Array<{ label: string, value: string }> }
              & { __typename: 'Field_Section' }
            )> }> }> }> }
      & { __typename: 'main_form_BlockType' }
    ) | (
      { anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }>, fallbackMobileImage: Array<{ id: string }>, img_mobile_webp_xs: Array<{ srcset: string }>, img_mobile_webp_sm: Array<{ srcset: string }>, img_mobile_webp_md: Array<{ srcset: string }> }
      & { __typename: 'main_fullWidthImage_BlockType' }
    ) | (
      { sectionHandle: string, serviceCategory: string, entryLevel: any, spacing: string, anchor: string, id: string }
      & { __typename: 'main_headingCards_BlockType' }
    ) | (
      { anchor: string, id: string, imageCardEntries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_imageCards_BlockType' }
    ) | (
      { singleLineText: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_image_BlockType' }
    ) | (
      { heading: string, headingElement: string, insightSelectionMethod: string, enableCarousel: boolean, spacing: string, anchor: string, id: string, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, insightCategory: Array<{ id: string, uri: string } | { id: string, uri: string }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_insights_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, zoomLevel: any, anchor: string, id: string, marker: Array<{ latitude: any, longitude: any }> }
      & { __typename: 'main_map_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, spacing: string, anchor: string, id: string, serviceListItems: Array<{ id: string, title: string }> }
      & { __typename: 'main_multiColumnList_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, people: Array<{ id: string, title: string, url: string, jobTitles: Array<{ title: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }>, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }
      & { __typename: 'main_people_BlockType' }
    ) | (
      { richText: string, spacing: string, anchor: string, id: string }
      & { __typename: 'main_richText_BlockType' }
    ) | (
      { richText: string, anchor: string, id: string }
      & { __typename: 'main_sectionNavigation_BlockType' }
    ) | (
      { heading: string, headingElement: string, spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_standardCards_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string }
      & { __typename: 'main_statistics_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, entries: Array<(
        { id: string, title: string, url: string }
        & { __typename: 'accordionEntries_default_Entry' | 'accreditations_default_Entry' | 'caseHighlights_default_Entry' | 'ctas_default_Entry' | 'detailedCtas_default_Entry' | 'general_mirroredEntry_Entry' | 'mapMarkers_default_Entry' | 'sectors_mirroredEntry_Entry' | 'services_mirroredEntry_Entry' | 'siteNotice_siteNotice_Entry' | 'statistics_statistics_Entry' | 'testimonials_default_Entry' | 'usps_default_Entry' }
      ) | (
        { id: string, title: string, url: string, indexHeading: string, indexSummary: string }
        & { __typename: 'careers_default_Entry' | 'events_default_Entry' | 'general_default_Entry' | 'home_home_Entry' | 'insightCategories_default_Entry' | 'insightsIndex_insightsIndex_Entry' | 'insights_default_Entry' | 'offices_default_Entry' | 'peopleIndex_peopleIndex_Entry' | 'people_default_Entry' | 'sectorsIndex_sectorsIndex_Entry' | 'sectors_default_Entry' | 'servicesIndex_servicesIndex_Entry' | 'services_default_Entry' }
      )> }
      & { __typename: 'main_summaryCards_BlockType' }
    ) | (
      { testimonialsColourway: string, showIcon: boolean, anchor: string, id: string, testimonials: Array<{ id: string, quote: string, author: string }> }
      & { __typename: 'main_testimonials_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, colourway: string, contentPosition: string, anchor: string, id: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }>, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }>, img_image_webp_3xl: Array<{ srcset: string }> }
      & { __typename: 'main_textImageSplit_BlockType' }
    ) | (
      { spacing: string, anchor: string, id: string, timeline: Array<{ id: string, heading: string, text: string, date: any, dateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> }
      & { __typename: 'main_timeline_BlockType' }
    ) | (
      { heading: string, headingElement: string, richText: string, uspsColourway: string, spacing: string, anchor: string, id: string, usps: Array<{ id: string, title: string, multiLineText: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }> }> }
      & { __typename: 'main_usps_BlockType' }
    ) | (
      { heading: string, headingElement: string, vimeoId: string, spacing: string, anchor: string, id: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }
      & { __typename: 'main_video_BlockType' }
    )>, telephone: { number: string }, mobile: { number: string }, jobTitles: Array<{ title: string }>, seomatic: { metaJsonLdContainer: string } }
    & { __typename: 'people_default_Entry' }
  ) };

export type GetPaginatedInsightsQueryVariables = Exact<{
  entryIds: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>> | InputMaybe<Scalars['QueryArgument']['input']>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetPaginatedInsightsQuery = { numResults: number, insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> };

export type GetPriorityPageUrisQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPriorityPageUrisQuery = { pages: Array<{ siteHandle: string, uri: string } | { siteHandle: string, uri: string }> };

export type GetRedirectWithUriQueryVariables = Exact<{
  uri: InputMaybe<Scalars['String']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetRedirectWithUriQuery = { redirect: { id: number, redirectMatchType: string, redirectSrcUrl: string, redirectDestUrl: string, redirectHttpCode: number } };

export type GetRelatedInsightsQueryVariables = Exact<{
  entryIds: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>> | InputMaybe<Scalars['QueryArgument']['input']>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetRelatedInsightsQuery = { insights: Array<{ id: string, title: string, url: string, slug: string, postDate: any, indexHeading: string, indexSummary: string, postDateFormatted: any, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }>, img_image_webp_2xl: Array<{ srcset: string }> }> };

export type GetRelatedPeopleQueryVariables = Exact<{
  insightId: InputMaybe<Scalars['QueryArgument']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetRelatedPeopleQuery = { people: Array<{ id: string, title: string, url: string, indexSummary: string, linkedin: string, fallbackImage: Array<{ width: number, height: number, alt: string, title: string, url: string }>, img_image_webp_xs: Array<{ srcset: string }>, img_image_webp_sm: Array<{ srcset: string }>, img_image_webp_md: Array<{ srcset: string }>, img_image_webp_lg: Array<{ srcset: string }>, img_image_webp_xl: Array<{ srcset: string }> }> };

export type GetRootSectorsQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetRootSectorsQuery = { sectors: Array<{ id: string, title: string, url: string, sectionHandle: string, indexSummary: string } | { id: string, url: string, sectionHandle: string, mirroredEntry: Array<{ title: string } | { title: string } | { indexSummary: string, title: string }> }> };

export type GetRootServicesQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetRootServicesQuery = { businessServices: Array<{ id: string, title: string, url: string, sectionHandle: string }>, personalServices: Array<{ id: string, title: string, url: string, sectionHandle: string }> };

export type GetSectionHeadingsQueryVariables = Exact<{
  sectionHandle: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  serviceCategory: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']['input']>> | InputMaybe<Scalars['QueryArgument']['input']>>;
  level: InputMaybe<Scalars['Int']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSectionHeadingsQuery = { headings: Array<{ id: string, title: string, url: string } | { id: string, title: string, url: string }> };

export type GetSectionNavEntriesQueryVariables = Exact<{
  elementId: InputMaybe<Scalars['QueryArgument']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSectionNavEntriesQuery = { entry: { parent: { id: string, title: string, url: string } | { id: string, title: string, url: string }, descendants: Array<{ id: string, title: string, url: string, level: number, ancestors: Array<{ id: string, level: number } | { id: string, level: number }> } | { id: string, title: string, url: string, level: number, ancestors: Array<{ id: string, level: number } | { id: string, level: number }> } | { hideFromSectionNav: boolean, id: string, title: string, url: string, level: number, ancestors: Array<{ id: string, level: number } | { id: string, level: number }> }> } | { parent: { id: string, title: string, url: string } | { id: string, title: string, url: string }, descendants: Array<{ id: string, title: string, url: string, level: number, ancestors: Array<{ id: string, level: number } | { id: string, level: number }> } | { id: string, title: string, url: string, level: number, ancestors: Array<{ id: string, level: number } | { id: string, level: number }> } | { hideFromSectionNav: boolean, id: string, title: string, url: string, level: number, ancestors: Array<{ id: string, level: number } | { id: string, level: number }> }> }, mirroringEntries: Array<{ parent: { id: string, title: string, url: string } | { id: string, title: string, url: string } } | { parent: { id: string, title: string, url: string } | { id: string, title: string, url: string } }> };

export type GetSeoQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSeoQuery = { seomatic: { metaTitleContainer: string, metaTagContainer: string, metaLinkContainer: string, metaScriptContainer: string, metaSiteVarsContainer: string } };

export type GetSeoHumansQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSeoHumansQuery = { seomatic: { frontendTemplates: Array<{ contents: string }> } };

export type GetSiteNoticeQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSiteNoticeQuery = { entries: Array<{ minimalRichText: string, link: Array<{ ariaLabel: string, target: string, text: string, url: string }> }> };

export type GetSitemapIndexesQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSitemapIndexesQuery = { seomatic: { sitemapIndexes: Array<{ contents: string }> } };

export type GetSitemapStyleQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSitemapStyleQuery = { seomatic: { sitemapStyles: { contents: string } } };

export type GetSitemapWithFilenameQueryVariables = Exact<{
  filename: InputMaybe<Scalars['String']['input']>;
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSitemapWithFilenameQuery = { seomatic: { sitemaps: Array<{ contents: string }> } };

export type GetStatisticsQueryVariables = Exact<{
  siteHandle: InputMaybe<Scalars['String']['input']>;
}>;


export type GetStatisticsQuery = { entries: Array<{ vectorImage: Array<{ alt: string, title: string, url: string }>, statistics: Array<{ id: string, figure: string, description: string }> }> };

export const SrcSet1x_FragmentFragmentDoc = gql`
    fragment SrcSet1x_Fragment on servd_Asset {
  srcset(sizes: ["1x"])
}
    `;
export const Image_FragmentFragmentDoc = gql`
    fragment Image_Fragment on servd_Asset {
  width
  height
  alt
  title
  url
}
    `;
export const SrcSetAll_FragmentFragmentDoc = gql`
    fragment SrcSetAll_Fragment on servd_Asset {
  srcset(sizes: ["1x", "1.5x", "1.75x", "2x"])
}
    `;
export const FeatureImage_FragmentFragmentDoc = gql`
    fragment FeatureImage_Fragment on servdFeatureImage_Asset {
  width
  height
  alt
  title
  url
}
    `;
export const FeatureSrcSetAll_FragmentFragmentDoc = gql`
    fragment FeatureSrcSetAll_Fragment on servdFeatureImage_Asset {
  srcset(sizes: ["1x", "1.5x", "1.75x", "2x"])
}
    `;
export const HeroNeo_FragmentFragmentDoc = gql`
    fragment HeroNeo_Fragment on NeoBlockInterface {
  __typename
  id
  ... on hero_articleHeader_BlockType {
    caption: singleLineText
    fallbackImage: image(limit: 1) @transform(width: 800, height: 450) {
      ...Image_Fragment
    }
    img_image_webp_xs: image(limit: 1) @transform(width: 344, height: 194, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: image(limit: 1) @transform(width: 559, height: 314, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: image(limit: 1) @transform(width: 687, height: 386, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: image(limit: 1) @transform(width: 800, height: 450, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on hero_baseHero_BlockType {
    heading
    useH1
    multiLineText
    button: typedLink {
      ariaLabel
      target
      text
      url
    }
    fallbackImage: image(limit: 1) @transform(width: 1919, height: 800) {
      ...Image_Fragment
    }
    img_image_webp_xs: image(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: image(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: image(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: image(limit: 1) @transform(width: 1023, height: 426, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: image(limit: 1) @transform(width: 1279, height: 533, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: image(limit: 1) @transform(width: 1535, height: 640, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: image(limit: 1) @transform(width: 1919, height: 800, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on hero_featureHero_BlockType {
    heading
    useH1
    multiLineText
    button: typedLink {
      ariaLabel
      target
      text
      url
    }
    featureImage {
      title
    }
    fallbackHealthImage: featureImage(limit: 1, title: "Health") @transform(width: 310, height: 406, mode: "fit") {
      ... on servdFeatureImage_Asset {
        ...FeatureImage_Fragment
        relatedFeatureAssetParts {
          ...FeatureImage_Fragment
        }
      }
    }
    img_health_webp_xs: featureImage(limit: 1, title: "Health") @transform(width: 263, height: 344, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    img_health_webp_sm: featureImage(limit: 1, title: "Health") @transform(width: 263, height: 344, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    img_health_webp_md: featureImage(limit: 1, title: "Health") @transform(width: 310, height: 406, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    fallbackTechnologyImage: featureImage(limit: 1, title: "Technology") @transform(width: 409, height: 281, mode: "fit") {
      ... on servdFeatureImage_Asset {
        ...FeatureImage_Fragment
        relatedFeatureAssetParts @transform(width: 409, height: 281, mode: "fit") {
          ...FeatureImage_Fragment
        }
      }
    }
    img_technology_webp_xs: featureImage(limit: 1, title: "Technology") @transform(width: 295, height: 202, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    img_technology_webp_sm: featureImage(limit: 1, title: "Technology") @transform(width: 295, height: 202, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    img_technology_webp_md: featureImage(limit: 1, title: "Technology") @transform(width: 409, height: 281, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    fallbackSportsImage: featureImage(limit: 1, title: "Sports") @transform(width: 256, height: 390, mode: "fit") {
      ... on servdFeatureImage_Asset {
        ...FeatureImage_Fragment
        relatedFeatureAssetParts @transform(width: 256, height: 390, mode: "fit") {
          ...FeatureImage_Fragment
        }
      }
    }
    img_sports_webp_xs: featureImage(limit: 1, title: "Sports") @transform(width: 256, height: 390, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    fallbackConstructionImage: featureImage(limit: 1, title: "Construction") @transform(width: 326, height: 265, mode: "fit") {
      ... on servdFeatureImage_Asset {
        ...FeatureImage_Fragment
        relatedFeatureAssetParts @transform(width: 326, height: 265, mode: "fit") {
          ...FeatureImage_Fragment
        }
      }
    }
    img_construction_webp_xs: featureImage(limit: 1, title: "Construction") @transform(width: 295, height: 240, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    img_construction_webp_sm: featureImage(limit: 1, title: "Construction") @transform(width: 295, height: 240, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    img_construction_webp_md: featureImage(limit: 1, title: "Construction") @transform(width: 326, height: 265, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    fallbackRetailImage: featureImage(limit: 1, title: "Retail") @transform(width: 263, height: 363, mode: "fit") {
      ... on servdFeatureImage_Asset {
        ...FeatureImage_Fragment
        relatedFeatureAssetParts @transform(width: 263, height: 363, mode: "fit") {
          ...FeatureImage_Fragment
        }
      }
    }
    img_retail_webp_xs: featureImage(limit: 1, title: "Retail") @transform(width: 210, height: 290, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    img_retail_webp_sm: featureImage(limit: 1, title: "Retail") @transform(width: 210, height: 290, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
    img_retail_webp_md: featureImage(limit: 1, title: "Retail") @transform(width: 263, height: 363, mode: "fit", format: "webp") {
      ...FeatureSrcSetAll_Fragment
    }
  }
  ... on hero_imageHero_BlockType {
    useH1
    fallbackImage: image(limit: 1) @transform(width: 1919, height: 800) {
      ...Image_Fragment
    }
    img_image_webp_xs: image(limit: 1) @transform(width: 424, height: 495, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: image(limit: 1) @transform(width: 639, height: 746, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: image(limit: 1) @transform(width: 767, height: 895, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: image(limit: 1) @transform(width: 1023, height: 426, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: image(limit: 1) @transform(width: 1279, height: 533, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: image(limit: 1) @transform(width: 1535, height: 640, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: image(limit: 1) @transform(width: 1919, height: 800, format: "webp") {
      ...SrcSetAll_Fragment
    }
    fallbackMobileImage: mobileImage {
      id
    }
    img_mobile_webp_xs: mobileImage(limit: 1) @transform(width: 424, height: 452, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_mobile_webp_sm: mobileImage(limit: 1) @transform(width: 639, height: 682, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_mobile_webp_md: mobileImage(limit: 1) @transform(width: 767, height: 818, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on hero_personHeader_BlockType {
    richText
    fallbackImage: image(limit: 1) @transform(width: 767, height: 639) {
      ...Image_Fragment
    }
    img_image_webp_xs: image(limit: 1) @transform(width: 424, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: image(limit: 1) @transform(width: 639, height: 500, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: image(limit: 1) @transform(width: 767, height: 500, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: image(limit: 1) @transform(width: 1024, height: 500, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: image(limit: 1) @transform(width: 720, height: 1090, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: image(limit: 1) @transform(width: 720, height: 850, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on hero_splitHero_BlockType {
    heading
    useH1
    heroColourway
    multiLineText
    showSocialLinks
    pattern
    fallbackImage: image(limit: 1) @transform(width: 960, height: 1055) {
      ...Image_Fragment
    }
    img_image_webp_xs: image(limit: 1) @transform(width: 424, height: 452, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: image(limit: 1) @transform(width: 639, height: 682, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: image(limit: 1) @transform(width: 767, height: 818, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: image(limit: 1) @transform(width: 512, height: 546, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: image(limit: 1) @transform(width: 640, height: 703, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: image(limit: 1) @transform(width: 768, height: 844, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: image(limit: 1) @transform(width: 960, height: 1055, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on hero_textHero_BlockType {
    useLogoAsHeading
    heading
    useH1
    textHeroColourway
    subHeading: singleLineText
    button: typedLink {
      ariaLabel
      target
      text
      url
    }
    heroAccreditations(site: "*") {
      ... on accreditations_default_Entry {
        id
        title
        fallbackImage: image(limit: 1) @transform(height: 70, mode: "fit") {
          ...Image_Fragment
        }
        img_image_webp_xs: image(limit: 1) @transform(height: 70, mode: "fit", format: "webp") {
          ...SrcSetAll_Fragment
        }
      }
    }
  }
}
    ${Image_FragmentFragmentDoc}
${SrcSetAll_FragmentFragmentDoc}
${FeatureImage_FragmentFragmentDoc}
${FeatureSrcSetAll_FragmentFragmentDoc}`;
export const Field_FragmentFragmentDoc = gql`
    fragment Field_Fragment on FieldInterface {
  __typename
  id
  name
  label: name
  handle
  instructions
  required
  displayName
  inputTypeName
  placeholder
  labelPosition
  instructionsPosition
  cssClasses
  visibility
  defaultValue
  containerAttributes {
    label
    value
  }
  inputAttributes {
    label
    value
  }
  ... on Field_Address {
    subfieldLabelPosition
    address1Label
    address1Enabled
    address1Hidden
    address1Required
    address1Placeholder
    address1DefaultValue
    address2Label
    address2Enabled
    address2Hidden
    address2Required
    address2Placeholder
    address2DefaultValue
    address3Label
    address3Enabled
    address3Hidden
    address3Required
    address3Placeholder
    address3DefaultValue
    cityLabel
    cityEnabled
    cityHidden
    cityRequired
    cityPlaceholder
    cityDefaultValue
    stateLabel
    stateEnabled
    stateHidden
    stateRequired
    statePlaceholder
    stateDefaultValue
    zipLabel
    zipEnabled
    zipHidden
    zipRequired
    zipPlaceholder
    zipDefaultValue
    countryLabel
    countryEnabled
    countryHidden
    countryRequired
    countryPlaceholder
    countryDefaultValue
    countryOptions {
      label
      value
    }
  }
  ... on Field_Agree {
    defaultState
    checkedValue
    uncheckedValue
    descriptionHtml
  }
  ... on Field_Checkboxes {
    options {
      label
      value
    }
  }
  ... on Field_Date {
    id
  }
  ... on Field_Dropdown {
    multi
    options {
      isDefault
      label
      value
    }
  }
  ... on Field_Entries {
    displayType
    entries {
      id
      title
    }
  }
  ... on Field_FileUpload {
    id
  }
  ... on Field_Heading {
    headingSize
  }
  ... on Field_Html {
    htmlContent
  }
  ... on Field_MultiLineText {
    limit
    maxType
    max
  }
  ... on Field_Name {
    useMultipleFields
    subfieldLabelPosition
    prefixLabel
    prefixEnabled
    prefixRequired
    prefixPlaceholder
    prefixDefaultValue
    prefixOptions {
      label
      value
    }
    firstNameLabel
    firstNameEnabled
    firstNameRequired
    firstNamePlaceholder
    firstNameDefaultValue
    middleNameLabel
    middleNameEnabled
    middleNameRequired
    middleNamePlaceholder
    middleNameDefaultValue
    lastNameLabel
    lastNameEnabled
    lastNameRequired
    lastNamePlaceholder
    lastNameDefaultValue
  }
  ... on Field_Number {
    min
    max
  }
  ... on Field_Phone {
    id
  }
  ... on Field_Radio {
    options {
      label
      value
    }
  }
  ... on Field_Section {
    borderStyle
    borderWidth
    borderColor
  }
  ... on Field_SingleLineText {
    limit
    maxType
    max
  }
}
    `;
export const Form_FragmentFragmentDoc = gql`
    fragment Form_Fragment on FormInterface {
  id
  title
  handle
  captchas {
    handle
    name
    value
  }
  settings {
    displayFormTitle
    displayPageTabs
    displayCurrentPageTitle
    displayPageProgress
    submitAction
    submitActionMessageHtml
    errorMessageHtml
  }
  pages {
    name
    id
    settings {
      submitButtonLabel
      backButtonLabel
      showBackButton
      buttonsPosition
    }
    rows {
      rowFields {
        ...Field_Fragment
        ... on Field_Group {
          fields {
            ...Field_Fragment
          }
        }
      }
    }
  }
}
    ${Field_FragmentFragmentDoc}`;
export const ImageCardsImage_FragmentFragmentDoc = gql`
    fragment ImageCardsImage_Fragment on EntryInterface {
  ... on careers_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on events_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on general_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on home_home_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on insights_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on insightCategories_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on insightsIndex_insightsIndex_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on offices_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on people_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on peopleIndex_peopleIndex_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on sectors_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on sectorsIndex_sectorsIndex_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on services_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on servicesIndex_servicesIndex_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 1008, height: 1008) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 424, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 639, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 767, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 512, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 672, height: 672, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 807, height: 807, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: indexImage(limit: 1) @transform(width: 1008, height: 1008, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
}
    ${Image_FragmentFragmentDoc}
${SrcSetAll_FragmentFragmentDoc}`;
export const IndexHeading_FragmentFragmentDoc = gql`
    fragment IndexHeading_Fragment on EntryInterface {
  ... on careers_default_Entry {
    indexHeading
  }
  ... on events_default_Entry {
    indexHeading
  }
  ... on general_default_Entry {
    indexHeading
  }
  ... on home_home_Entry {
    indexHeading
  }
  ... on insights_default_Entry {
    indexHeading
  }
  ... on insightCategories_default_Entry {
    indexHeading
  }
  ... on insightsIndex_insightsIndex_Entry {
    indexHeading
  }
  ... on offices_default_Entry {
    indexHeading
  }
  ... on people_default_Entry {
    indexHeading
  }
  ... on peopleIndex_peopleIndex_Entry {
    indexHeading
  }
  ... on sectors_default_Entry {
    indexHeading
  }
  ... on sectorsIndex_sectorsIndex_Entry {
    indexHeading
  }
  ... on services_default_Entry {
    indexHeading
  }
  ... on servicesIndex_servicesIndex_Entry {
    indexHeading
  }
}
    `;
export const IndexSummary_FragmentFragmentDoc = gql`
    fragment IndexSummary_Fragment on EntryInterface {
  ... on careers_default_Entry {
    indexSummary
  }
  ... on events_default_Entry {
    indexSummary
  }
  ... on general_default_Entry {
    indexSummary
  }
  ... on home_home_Entry {
    indexSummary
  }
  ... on insights_default_Entry {
    indexSummary
  }
  ... on insightCategories_default_Entry {
    indexSummary
  }
  ... on insightsIndex_insightsIndex_Entry {
    indexSummary
  }
  ... on offices_default_Entry {
    indexSummary
  }
  ... on people_default_Entry {
    indexSummary
  }
  ... on peopleIndex_peopleIndex_Entry {
    indexSummary
  }
  ... on sectors_default_Entry {
    indexSummary
  }
  ... on sectorsIndex_sectorsIndex_Entry {
    indexSummary
  }
  ... on services_default_Entry {
    indexSummary
  }
  ... on servicesIndex_servicesIndex_Entry {
    indexSummary
  }
}
    `;
export const RelatedInsight_FragmentFragmentDoc = gql`
    fragment RelatedInsight_Fragment on EntryInterface {
  ... on insights_default_Entry {
    id
    title
    url
    slug
    postDate @formatDateTime(format: "Y-m-d")
    postDateFormatted: postDate @formatDateTime(format: "j F Y")
    indexHeading
    indexSummary
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
}
    ${Image_FragmentFragmentDoc}
${SrcSetAll_FragmentFragmentDoc}`;
export const StandardCardsImage_FragmentFragmentDoc = gql`
    fragment StandardCardsImage_Fragment on EntryInterface {
  ... on careers_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on events_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on general_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on home_home_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on insights_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on insightCategories_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on insightsIndex_insightsIndex_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on offices_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on peopleIndex_peopleIndex_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on people_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on sectorsIndex_sectorsIndex_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on sectors_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on services_default_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on servicesIndex_servicesIndex_Entry {
    fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
      ...Image_Fragment
    }
    img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
}
    ${Image_FragmentFragmentDoc}
${SrcSetAll_FragmentFragmentDoc}`;
export const MainNeo_FragmentFragmentDoc = gql`
    fragment MainNeo_Fragment on NeoBlockInterface {
  __typename
  id
  ... on main_aboutTheAuthor_BlockType {
    anchor
  }
  ... on main_accordion_BlockType {
    heading
    headingElement
    accordionEntries(site: "*") {
      ... on accordionEntries_default_Entry {
        id
        heading
        richText
      }
    }
    spacing
    anchor
  }
  ... on main_accreditations_BlockType {
    heading
    headingElement
    showLabels
    accreditations(site: "*") {
      ... on accreditations_default_Entry {
        id
        title
        slug
        fallbackImage: image(limit: 1) @transform(width: 320, height: 160, mode: "fit") {
          ...Image_Fragment
        }
        img_image_webp_xs: image(limit: 1) @transform(width: 320, height: 160, mode: "fit", format: "webp") {
          ...SrcSetAll_Fragment
        }
      }
    }
    enableCarousel
    spacing
    anchor
  }
  ... on main_caseHighlights_BlockType {
    caseHighlightsColourway
    heading
    headingElement
    caseHighlights(site: "*") {
      ... on caseHighlights_default_Entry {
        id
        heading
        multiLineText
        entry(site: "*") {
          id
          url
        }
        fallbackImage: image(limit: 1) @transform(width: 767, height: 671) {
          ...Image_Fragment
        }
        img_image_webp_xs: image(limit: 1) @transform(width: 424, height: 371, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_sm: image(limit: 1) @transform(width: 639, height: 559, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_md: image(limit: 1) @transform(width: 767, height: 671, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_lg: image(limit: 1) @transform(width: 312, height: 273, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_xl: image(limit: 1) @transform(width: 440, height: 385, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_2xl: image(limit: 1) @transform(width: 568, height: 497, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_3xl: image(limit: 1) @transform(width: 760, height: 665, format: "webp") {
          ...SrcSetAll_Fragment
        }
      }
    }
    anchor
  }
  ... on main_cookieDeclaration_BlockType {
    anchor
    spacing
  }
  ... on main_cta_BlockType {
    cta(site: "*") {
      ... on ctas_default_Entry {
        id
        heading
        headingElement
        richText
        link: typedLink {
          ariaLabel
          target
          text
          url
        }
        fallbackImage: image(limit: 1) @transform(width: 1919, height: 800) {
          ...Image_Fragment
        }
        img_image_webp_xs: image(limit: 1) @transform(width: 424, height: 424, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_sm: image(limit: 1) @transform(width: 639, height: 639, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_md: image(limit: 1) @transform(width: 767, height: 767, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_lg: image(limit: 1) @transform(width: 1023, height: 426, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_xl: image(limit: 1) @transform(width: 1279, height: 533, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_2xl: image(limit: 1) @transform(width: 1535, height: 640, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_3xl: image(limit: 1) @transform(width: 1919, height: 800, format: "webp") {
          ...SrcSetAll_Fragment
        }
      }
    }
    ctaColourway
    anchor
  }
  ... on main_detailedCta_BlockType {
    detailedCta(site: "*") {
      ... on detailedCtas_default_Entry {
        heading
        headingElement
        richText
        link: typedLink {
          ariaLabel
          target
          text
          url
        }
        fallbackImage: image(limit: 1) @transform(width: 479, height: 479, mode: "fit") {
          ...Image_Fragment
        }
        img_image_webp_xs: image(limit: 1) @transform(width: 360, height: 360, mode: "fit", format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_2xl: image(limit: 1) @transform(width: 479, height: 479, mode: "fit", format: "webp") {
          ...SrcSetAll_Fragment
        }
      }
    }
    anchor
  }
  ... on main_events_BlockType {
    heading
    headingElement
    events(site: "*") {
      ... on events_default_Entry {
        id
        title
        url
        postDate @formatDateTime(format: "Y-m-d")
        postDateFormatted: postDate @formatDateTime(format: "j F Y")
        indexHeading
        indexSummary
        indexHideEventDate
        fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
          ...Image_Fragment
        }
        img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_md: indexImage(limit: 1) @transform(width: 324, height: 324, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_lg: indexImage(limit: 1) @transform(width: 277, height: 277, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_xl: indexImage(limit: 1) @transform(width: 353, height: 353, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_2xl: indexImage(limit: 1) @transform(width: 382, height: 382, format: "webp") {
          ...SrcSetAll_Fragment
        }
      }
    }
    link: typedLink {
      ariaLabel
      target
      text
      url
    }
    spacing
    anchor
  }
  ... on main_form_BlockType {
    heading
    headingElement
    formType
    formieForm(limit: 1) {
      ...Form_Fragment
    }
    hubspotFormId
    spacing
    anchor
  }
  ... on main_fullWidthImage_BlockType {
    anchor
    fallbackImage: image(limit: 1) @transform(width: 1919, height: 800) {
      ...Image_Fragment
    }
    img_image_webp_xs: image(limit: 1) @transform(width: 424, height: 495, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: image(limit: 1) @transform(width: 639, height: 746, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: image(limit: 1) @transform(width: 767, height: 895, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: image(limit: 1) @transform(width: 1023, height: 426, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: image(limit: 1) @transform(width: 1279, height: 533, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: image(limit: 1) @transform(width: 1535, height: 640, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: image(limit: 1) @transform(width: 1919, height: 800, format: "webp") {
      ...SrcSetAll_Fragment
    }
    fallbackMobileImage: mobileImage {
      id
    }
    img_mobile_webp_xs: mobileImage(limit: 1) @transform(width: 424, height: 452, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_mobile_webp_sm: mobileImage(limit: 1) @transform(width: 639, height: 682, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_mobile_webp_md: mobileImage(limit: 1) @transform(width: 767, height: 818, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on main_headingCards_BlockType {
    sectionHandle
    serviceCategory
    entryLevel
    spacing
    anchor
  }
  ... on main_imageCards_BlockType {
    imageCardEntries(site: "*") {
      __typename
      id
      title
      url
      ...ImageCardsImage_Fragment
      ...IndexHeading_Fragment
      ...IndexSummary_Fragment
    }
    anchor
  }
  ... on main_image_BlockType {
    singleLineText
    spacing
    anchor
    fallbackImage: image(limit: 1) @transform(width: 800, mode: "fit") {
      ...Image_Fragment
    }
    img_image_webp_xs: image(limit: 1) @transform(width: 344, mode: "fit", format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: image(limit: 1) @transform(width: 559, mode: "fit", format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: image(limit: 1) @transform(width: 687, mode: "fit", format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: image(limit: 1) @transform(width: 720, mode: "fit", format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: image(limit: 1) @transform(width: 800, mode: "fit", format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on main_insights_BlockType {
    heading
    headingElement
    insightSelectionMethod
    insights(site: "*") {
      ...RelatedInsight_Fragment
    }
    insightCategory(site: "*") {
      id
      uri
    }
    link: typedLink {
      ariaLabel
      target
      text
      url
    }
    enableCarousel
    spacing
    anchor
  }
  ... on main_map_BlockType {
    heading
    headingElement
    richText
    marker(limit: 1, site: "*") {
      ... on mapMarkers_default_Entry {
        latitude
        longitude
      }
    }
    zoomLevel
    anchor
  }
  ... on main_multiColumnList_BlockType {
    heading
    headingElement
    richText
    serviceListItems(site: "*") {
      ... on serviceListItems_Tag {
        id
        title
      }
    }
    spacing
    anchor
  }
  ... on main_people_BlockType {
    heading
    headingElement
    people(site: "*") {
      ... on people_default_Entry {
        id
        title
        url
        jobTitles {
          ... on jobTitles_Tag {
            title
          }
        }
        fallbackImage: indexImage(limit: 1) @transform(width: 559, height: 559) {
          ...Image_Fragment
        }
        img_image_webp_xs: indexImage(limit: 1) @transform(width: 344, height: 344, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_sm: indexImage(limit: 1) @transform(width: 559, height: 559, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_md: indexImage(limit: 1) @transform(width: 316, height: 316, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_lg: indexImage(limit: 1) @transform(width: 240, height: 240, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_xl: indexImage(limit: 1) @transform(width: 314, height: 314, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_2xl: indexImage(limit: 1) @transform(width: 342, height: 342, format: "webp") {
          ...SrcSetAll_Fragment
        }
      }
    }
    link: typedLink {
      ariaLabel
      target
      text
      url
    }
    spacing
    anchor
  }
  ... on main_peopleSearch_BlockType {
    anchor
  }
  ... on main_richText_BlockType {
    richText
    spacing
    anchor
  }
  ... on main_sectionNavigation_BlockType {
    richText
    anchor
  }
  ... on main_standardCards_BlockType {
    heading
    headingElement
    entries(site: "*") {
      __typename
      id
      title
      url
      ...StandardCardsImage_Fragment
      ...IndexHeading_Fragment
      ...IndexSummary_Fragment
    }
    spacing
    anchor
  }
  ... on main_statistics_BlockType {
    spacing
    anchor
  }
  ... on main_summaryCards_BlockType {
    entries(limit: 3, site: "*") {
      __typename
      id
      title
      url
      ...IndexHeading_Fragment
      ...IndexSummary_Fragment
    }
    spacing
    anchor
  }
  ... on main_testimonials_BlockType {
    testimonialsColourway
    showIcon
    testimonials(site: "*") {
      ... on testimonials_default_Entry {
        id
        quote: multiLineText
        author: singleLineText
      }
    }
    anchor
  }
  ... on main_timeline_BlockType {
    timeline {
      ... on timeline_card_BlockType {
        id
        heading
        text
        date @formatDateTime(format: "Y-m-d")
        dateFormatted: date @formatDateTime(format: "j F Y")
        fallbackImage: image(limit: 1) @transform(width: 687, height: 305) {
          ...Image_Fragment
        }
        img_image_webp_xs: image(limit: 1) @transform(width: 344, height: 254, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_sm: image(limit: 1) @transform(width: 559, height: 413, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_md: image(limit: 1) @transform(width: 687, height: 305, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_xl: image(limit: 1) @transform(width: 456, height: 203, format: "webp") {
          ...SrcSetAll_Fragment
        }
        img_image_webp_2xl: image(limit: 1) @transform(width: 496, height: 220, format: "webp") {
          ...SrcSetAll_Fragment
        }
      }
    }
    spacing
    anchor
  }
  ... on main_textImageSplit_BlockType {
    heading
    headingElement
    richText
    colourway
    contentPosition
    link: typedLink {
      ariaLabel
      target
      text
      url
    }
    anchor
    fallbackImage: image(limit: 1) @transform(width: 798, height: 939) {
      ...Image_Fragment
    }
    img_image_webp_xs: image(limit: 1) @transform(width: 424, height: 499, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: image(limit: 1) @transform(width: 639, height: 752, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: image(limit: 1) @transform(width: 767, height: 902, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: image(limit: 1) @transform(width: 312, height: 366, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_xl: image(limit: 1) @transform(width: 462, height: 543, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: image(limit: 1) @transform(width: 597, height: 702, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_3xl: image(limit: 1) @transform(width: 798, height: 939, format: "webp") {
      ...SrcSetAll_Fragment
    }
  }
  ... on main_usps_BlockType {
    heading
    headingElement
    richText
    uspsColourway
    usps(site: "*") {
      ... on usps_default_Entry {
        id
        title
        multiLineText
        fallbackImage: image(limit: 1) @transform(height: 105, mode: "fit") {
          ...Image_Fragment
        }
        img_image_webp_xs: image(limit: 1) @transform(height: 105, mode: "fit", format: "webp") {
          ...SrcSetAll_Fragment
        }
      }
    }
    spacing
    anchor
  }
  ... on main_video_BlockType {
    heading
    headingElement
    vimeoId
    spacing
    fallbackImage: image(limit: 1) @transform(width: 800, height: 450) {
      ...Image_Fragment
    }
    img_image_webp_xs: image(limit: 1) @transform(width: 344, height: 194, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_sm: image(limit: 1) @transform(width: 559, height: 314, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_md: image(limit: 1) @transform(width: 687, height: 386, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_lg: image(limit: 1) @transform(width: 720, height: 405, format: "webp") {
      ...SrcSetAll_Fragment
    }
    img_image_webp_2xl: image(limit: 1) @transform(width: 800, height: 450, format: "webp") {
      ...SrcSetAll_Fragment
    }
    anchor
  }
}
    ${Image_FragmentFragmentDoc}
${SrcSetAll_FragmentFragmentDoc}
${Form_FragmentFragmentDoc}
${ImageCardsImage_FragmentFragmentDoc}
${IndexHeading_FragmentFragmentDoc}
${IndexSummary_FragmentFragmentDoc}
${RelatedInsight_FragmentFragmentDoc}
${StandardCardsImage_FragmentFragmentDoc}`;
export const Page_FragmentFragmentDoc = gql`
    fragment Page_Fragment on EntryInterface {
  __typename
  id
  title
  uri
  slug
  level
  siteHandle
  seomatic(asArray: false) {
    metaJsonLdContainer
  }
  ... on careers_default_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on events_default_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on general_default_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on home_home_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on insights_default_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
    url
    postDate @formatDateTime(format: "Y-m-d")
    postDateFormatted: postDate @formatDateTime(format: "j F Y")
    enableRelatedInsights
    insightCategories(site: "*") {
      id
      title
      url
    }
    people(site: "*") {
      id
      title
      url
    }
  }
  ... on insightCategories_default_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on insightsIndex_insightsIndex_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on offices_default_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
    email
    telephone {
      number
    }
  }
  ... on people_default_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
    email
    telephone {
      number
    }
    mobile {
      number
    }
    jobTitles {
      title
    }
    specialisms
    linkedin
    twitter
  }
  ... on peopleIndex_peopleIndex_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on sectors_default_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on sectorsIndex_sectorsIndex_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on services_default_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on servicesIndex_servicesIndex_Entry {
    hero {
      ...HeroNeo_Fragment
    }
    main {
      ...MainNeo_Fragment
    }
  }
  ... on general_mirroredEntry_Entry {
    mirroredEntry {
      uri
      siteHandle
    }
  }
  ... on sectors_mirroredEntry_Entry {
    mirroredEntry {
      uri
      siteHandle
    }
  }
  ... on services_mirroredEntry_Entry {
    mirroredEntry {
      uri
      siteHandle
    }
  }
}
    ${HeroNeo_FragmentFragmentDoc}
${MainNeo_FragmentFragmentDoc}`;
export const Seomatic_FragmentFragmentDoc = gql`
    fragment Seomatic_Fragment on SeomaticInterface {
  metaTitleContainer
  metaTagContainer
  metaLinkContainer
  metaScriptContainer
  metaSiteVarsContainer
}
    `;
export const GetAllOfficesDocument = gql`
    query GetAllOffices($siteHandle: String) {
  offices: officesEntries(level: 1, site: [$siteHandle]) {
    ... on offices_default_Entry {
      id
      title
      url
      telephone {
        number
      }
    }
  }
}
    `;
export const GetAllPageUrisDocument = gql`
    query GetAllPageUris {
  pages: entries(uri: ":notempty:", site: "*") {
    siteHandle
    uri
  }
}
    `;
export const GetFooterNavDocument = gql`
    query GetFooterNav($navHandle: String, $siteHandle: String) {
  nodes: navigationNodes(navHandle: $navHandle, level: 1, site: [$siteHandle]) {
    id
    title
    url
    newWindow
    customAttributes {
      attribute
      value
    }
  }
}
    `;
export const GetHeaderNavDocument = gql`
    query GetHeaderNav($siteHandle: String) {
  nodes: navigationNodes(
    navHandle: "mainNavigation"
    level: 1
    site: [$siteHandle]
  ) {
    id
    title
    url
    newWindow
    element {
      id
      ... on EntryInterface {
        sectionHandle
      }
    }
    customAttributes {
      attribute
      value
    }
    children {
      id
      title
      url
      newWindow
      element {
        id
        ... on EntryInterface {
          sectionHandle
        }
      }
      customAttributes {
        attribute
        value
      }
      children {
        id
        title
        url
        newWindow
        customAttributes {
          attribute
          value
        }
        element {
          id
          ... on EntryInterface {
            sectionHandle
          }
        }
      }
    }
  }
}
    `;
export const GetInsightSeoWithSlugAndCategoryDocument = gql`
    query GetInsightSeoWithSlugAndCategory($insightSlug: String, $categorySlug: String, $siteHandle: String) {
  page: entry(
    section: "insights"
    slug: [$insightSlug]
    relatedToEntries: {section: "insightCategories", slug: [$categorySlug]}
    site: [$siteHandle]
  ) {
    __typename
    uri
    seomatic(asArray: true) {
      ...Seomatic_Fragment
    }
  }
}
    ${Seomatic_FragmentFragmentDoc}`;
export const GetInsightWithSlugAndCategoryDocument = gql`
    query GetInsightWithSlugAndCategory($insightSlug: String, $categorySlug: String, $siteHandle: String) {
  page: entry(
    section: "insights"
    slug: [$insightSlug]
    relatedToEntries: {section: "insightCategories", slug: [$categorySlug]}
    site: [$siteHandle]
  ) {
    ...Page_Fragment
  }
}
    ${Page_FragmentFragmentDoc}`;
export const GetLatestInsightsDocument = gql`
    query GetLatestInsights($limit: Int, $siteHandle: String) {
  insights: insightsEntries(
    limit: $limit
    orderBy: "postDate DESC"
    site: [$siteHandle]
  ) {
    ... on insights_default_Entry {
      ...RelatedInsight_Fragment
    }
  }
}
    ${RelatedInsight_FragmentFragmentDoc}`;
export const GetPageSeoWithUriDocument = gql`
    query GetPageSeoWithUri($uri: String, $siteHandle: String) {
  page: entry(uri: [$uri], site: [$siteHandle]) {
    __typename
    uri
    seomatic(asArray: true) {
      ...Seomatic_Fragment
    }
    ... on general_mirroredEntry_Entry {
      mirroredEntry {
        uri
        siteHandle
      }
    }
    ... on sectors_mirroredEntry_Entry {
      mirroredEntry {
        uri
        siteHandle
      }
    }
    ... on services_mirroredEntry_Entry {
      mirroredEntry {
        uri
        siteHandle
      }
    }
  }
}
    ${Seomatic_FragmentFragmentDoc}`;
export const GetPageTitlesWithUrisDocument = gql`
    query GetPageTitlesWithUris($uris: [String], $siteHandle: String) {
  pages: entries(uri: $uris, site: [$siteHandle]) {
    title
    uri
  }
}
    `;
export const GetPageWithUriDocument = gql`
    query GetPageWithUri($uri: String, $siteHandle: String) {
  page: entry(uri: [$uri], site: [$siteHandle]) {
    ...Page_Fragment
  }
}
    ${Page_FragmentFragmentDoc}`;
export const GetPaginatedInsightsDocument = gql`
    query GetPaginatedInsights($entryIds: [QueryArgument], $limit: Int, $offset: Int, $siteHandle: String) {
  insights: insightsEntries(
    relatedTo: $entryIds
    limit: $limit
    offset: $offset
    site: [$siteHandle]
  ) {
    ... on insights_default_Entry {
      ...RelatedInsight_Fragment
    }
  }
  numResults: entryCount(section: "insights", relatedTo: $entryIds)
}
    ${RelatedInsight_FragmentFragmentDoc}`;
export const GetPriorityPageUrisDocument = gql`
    query GetPriorityPageUris {
  pages: entries(
    site: "*"
    uri: ":notempty:"
    section: ["general", "home", "insightsIndex", "offices", "peopleIndex", "sectors", "sectorsIndex", "services", "servicesIndex"]
  ) {
    siteHandle
    uri
  }
}
    `;
export const GetRedirectWithUriDocument = gql`
    query GetRedirectWithUri($uri: String, $siteHandle: String) {
  redirect: retourResolveRedirect(uri: $uri, site: $siteHandle) {
    id
    redirectMatchType
    redirectSrcUrl
    redirectDestUrl
    redirectHttpCode
  }
}
    `;
export const GetRelatedInsightsDocument = gql`
    query GetRelatedInsights($entryIds: [QueryArgument], $limit: Int, $siteHandle: String) {
  insights: insightsEntries(
    relatedTo: $entryIds
    limit: $limit
    site: [$siteHandle]
  ) {
    ... on insights_default_Entry {
      ...RelatedInsight_Fragment
    }
  }
}
    ${RelatedInsight_FragmentFragmentDoc}`;
export const GetRelatedPeopleDocument = gql`
    query GetRelatedPeople($insightId: QueryArgument, $siteHandle: String) {
  people: peopleEntries(relatedTo: [$insightId], site: [$siteHandle]) {
    ... on people_default_Entry {
      id
      title
      url
      indexSummary
      linkedin
      fallbackImage: indexImage(limit: 1) @transform(width: 960, height: 1055) {
        ...Image_Fragment
      }
      img_image_webp_xs: indexImage(limit: 1) @transform(width: 424, height: 371, format: "webp") {
        ...SrcSetAll_Fragment
      }
      img_image_webp_sm: indexImage(limit: 1) @transform(width: 639, height: 559, format: "webp") {
        ...SrcSetAll_Fragment
      }
      img_image_webp_md: indexImage(limit: 1) @transform(width: 767, height: 671, format: "webp") {
        ...SrcSetAll_Fragment
      }
      img_image_webp_lg: indexImage(limit: 1) @transform(width: 512, height: 448, format: "webp") {
        ...SrcSetAll_Fragment
      }
      img_image_webp_xl: indexImage(limit: 1) @transform(width: 600, height: 525, format: "webp") {
        ...SrcSetAll_Fragment
      }
    }
  }
}
    ${Image_FragmentFragmentDoc}
${SrcSetAll_FragmentFragmentDoc}`;
export const GetRootSectorsDocument = gql`
    query GetRootSectors($siteHandle: String) {
  sectors: sectorsEntries(level: 1, site: [$siteHandle]) {
    ... on sectors_default_Entry {
      id
      title
      url
      sectionHandle
      indexSummary
    }
    ... on sectors_mirroredEntry_Entry {
      id
      url
      sectionHandle
      mirroredEntry {
        title
        ... on sectors_default_Entry {
          indexSummary
        }
      }
    }
  }
}
    `;
export const GetRootServicesDocument = gql`
    query GetRootServices($siteHandle: String) {
  businessServices: servicesEntries(
    level: 1
    serviceCategory: "business"
    site: [$siteHandle]
  ) {
    ... on services_default_Entry {
      id
      title
      url
      sectionHandle
    }
  }
  personalServices: servicesEntries(
    level: 1
    serviceCategory: "personal"
    site: [$siteHandle]
  ) {
    ... on services_default_Entry {
      id
      title
      url
      sectionHandle
    }
  }
}
    `;
export const GetSectionHeadingsDocument = gql`
    query GetSectionHeadings($sectionHandle: [String], $serviceCategory: [QueryArgument], $level: Int, $siteHandle: String) {
  headings: entries(
    section: $sectionHandle
    uri: ":notempty:"
    serviceCategory: $serviceCategory
    level: $level
    site: [$siteHandle]
  ) {
    id
    title
    url
  }
}
    `;
export const GetSectionNavEntriesDocument = gql`
    query GetSectionNavEntries($elementId: QueryArgument, $siteHandle: String) {
  entry(id: [$elementId], site: [$siteHandle]) {
    parent {
      id
      title
      url
    }
    descendants {
      id
      title
      url
      level
      ancestors {
        id
        level
      }
      ... on services_default_Entry {
        hideFromSectionNav
      }
    }
  }
  mirroringEntries: entries(mirroredEntry: [$elementId], site: [$siteHandle]) {
    parent {
      id
      title
      url
    }
  }
}
    `;
export const GetSeoDocument = gql`
    query GetSeo($siteHandle: String) {
  seomatic(asArray: true, site: $siteHandle) {
    ...Seomatic_Fragment
  }
}
    ${Seomatic_FragmentFragmentDoc}`;
export const GetSeoHumansDocument = gql`
    query GetSeoHumans($siteHandle: String) {
  seomatic {
    frontendTemplates(type: humans, site: $siteHandle) {
      contents
    }
  }
}
    `;
export const GetSiteNoticeDocument = gql`
    query GetSiteNotice($siteHandle: String) {
  entries: siteNoticeEntries(limit: 1, site: [$siteHandle]) {
    ... on siteNotice_siteNotice_Entry {
      minimalRichText
      link: typedLink {
        ariaLabel
        target
        text
        url
      }
    }
  }
}
    `;
export const GetSitemapIndexesDocument = gql`
    query GetSitemapIndexes($siteHandle: String) {
  seomatic {
    sitemapIndexes(site: $siteHandle) {
      contents
    }
  }
}
    `;
export const GetSitemapStyleDocument = gql`
    query GetSitemapStyle($siteHandle: String) {
  seomatic(site: $siteHandle) {
    sitemapStyles {
      contents
    }
  }
}
    `;
export const GetSitemapWithFilenameDocument = gql`
    query GetSitemapWithFilename($filename: String, $siteHandle: String) {
  seomatic {
    sitemaps(filename: $filename, site: $siteHandle) {
      contents
    }
  }
}
    `;
export const GetStatisticsDocument = gql`
    query GetStatistics($siteHandle: String) {
  entries: statisticsEntries(limit: 1, site: [$siteHandle]) {
    ... on statistics_statistics_Entry {
      vectorImage(limit: 1) {
        ... on servd_Asset {
          alt
          title
          url
        }
      }
      statistics {
        ... on statistics_statistic_BlockType {
          id
          figure
          description
        }
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetAllOffices(variables?: GetAllOfficesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAllOfficesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAllOfficesQuery>(GetAllOfficesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAllOffices', 'query', variables);
    },
    GetAllPageUris(variables?: GetAllPageUrisQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAllPageUrisQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAllPageUrisQuery>(GetAllPageUrisDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAllPageUris', 'query', variables);
    },
    GetFooterNav(variables?: GetFooterNavQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetFooterNavQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetFooterNavQuery>(GetFooterNavDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetFooterNav', 'query', variables);
    },
    GetHeaderNav(variables?: GetHeaderNavQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetHeaderNavQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetHeaderNavQuery>(GetHeaderNavDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetHeaderNav', 'query', variables);
    },
    GetInsightSeoWithSlugAndCategory(variables?: GetInsightSeoWithSlugAndCategoryQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetInsightSeoWithSlugAndCategoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetInsightSeoWithSlugAndCategoryQuery>(GetInsightSeoWithSlugAndCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetInsightSeoWithSlugAndCategory', 'query', variables);
    },
    GetInsightWithSlugAndCategory(variables?: GetInsightWithSlugAndCategoryQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetInsightWithSlugAndCategoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetInsightWithSlugAndCategoryQuery>(GetInsightWithSlugAndCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetInsightWithSlugAndCategory', 'query', variables);
    },
    GetLatestInsights(variables?: GetLatestInsightsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetLatestInsightsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLatestInsightsQuery>(GetLatestInsightsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetLatestInsights', 'query', variables);
    },
    GetPageSeoWithUri(variables?: GetPageSeoWithUriQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPageSeoWithUriQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPageSeoWithUriQuery>(GetPageSeoWithUriDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPageSeoWithUri', 'query', variables);
    },
    GetPageTitlesWithUris(variables?: GetPageTitlesWithUrisQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPageTitlesWithUrisQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPageTitlesWithUrisQuery>(GetPageTitlesWithUrisDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPageTitlesWithUris', 'query', variables);
    },
    GetPageWithUri(variables?: GetPageWithUriQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPageWithUriQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPageWithUriQuery>(GetPageWithUriDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPageWithUri', 'query', variables);
    },
    GetPaginatedInsights(variables?: GetPaginatedInsightsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPaginatedInsightsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPaginatedInsightsQuery>(GetPaginatedInsightsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPaginatedInsights', 'query', variables);
    },
    GetPriorityPageUris(variables?: GetPriorityPageUrisQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetPriorityPageUrisQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetPriorityPageUrisQuery>(GetPriorityPageUrisDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetPriorityPageUris', 'query', variables);
    },
    GetRedirectWithUri(variables?: GetRedirectWithUriQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRedirectWithUriQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRedirectWithUriQuery>(GetRedirectWithUriDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRedirectWithUri', 'query', variables);
    },
    GetRelatedInsights(variables?: GetRelatedInsightsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRelatedInsightsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRelatedInsightsQuery>(GetRelatedInsightsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRelatedInsights', 'query', variables);
    },
    GetRelatedPeople(variables?: GetRelatedPeopleQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRelatedPeopleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRelatedPeopleQuery>(GetRelatedPeopleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRelatedPeople', 'query', variables);
    },
    GetRootSectors(variables?: GetRootSectorsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRootSectorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRootSectorsQuery>(GetRootSectorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRootSectors', 'query', variables);
    },
    GetRootServices(variables?: GetRootServicesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRootServicesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRootServicesQuery>(GetRootServicesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetRootServices', 'query', variables);
    },
    GetSectionHeadings(variables?: GetSectionHeadingsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSectionHeadingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSectionHeadingsQuery>(GetSectionHeadingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSectionHeadings', 'query', variables);
    },
    GetSectionNavEntries(variables?: GetSectionNavEntriesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSectionNavEntriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSectionNavEntriesQuery>(GetSectionNavEntriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSectionNavEntries', 'query', variables);
    },
    GetSeo(variables?: GetSeoQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSeoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSeoQuery>(GetSeoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSeo', 'query', variables);
    },
    GetSeoHumans(variables?: GetSeoHumansQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSeoHumansQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSeoHumansQuery>(GetSeoHumansDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSeoHumans', 'query', variables);
    },
    GetSiteNotice(variables?: GetSiteNoticeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSiteNoticeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSiteNoticeQuery>(GetSiteNoticeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSiteNotice', 'query', variables);
    },
    GetSitemapIndexes(variables?: GetSitemapIndexesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSitemapIndexesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSitemapIndexesQuery>(GetSitemapIndexesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSitemapIndexes', 'query', variables);
    },
    GetSitemapStyle(variables?: GetSitemapStyleQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSitemapStyleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSitemapStyleQuery>(GetSitemapStyleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSitemapStyle', 'query', variables);
    },
    GetSitemapWithFilename(variables?: GetSitemapWithFilenameQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSitemapWithFilenameQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSitemapWithFilenameQuery>(GetSitemapWithFilenameDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSitemapWithFilename', 'query', variables);
    },
    GetStatistics(variables?: GetStatisticsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetStatisticsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetStatisticsQuery>(GetStatisticsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetStatistics', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;