/**
 * Link component - a wrapper around Next's `<Link>` component that adds rel="noopener nofollow"
 */

import NextLink from 'next/link';

type LinkProps = Omit<React.ComponentPropsWithoutRef<'a'>, 'href'> & {
  href: string;
};

export default function Link({ children, href, rel = '', ...attributes }: LinkProps) {
  // External URL
  const relOptions = rel ? rel.split(' ') : [];
  if (attributes.target === '_blank') {
    if (!relOptions.includes('noopener')) {
      relOptions.push('noopener');
    }
    if (!relOptions.includes('nofollow')) {
      relOptions.push('nofollow');
    }
  }

  return (
    /* @ts-expect-error caused by exactOptionalPropertyTypes: true */
    <NextLink
      href={href}
      rel={relOptions.length ? relOptions.join(' ') : undefined}
      {...attributes}
    >
      {children}
    </NextLink>
  );
}
