'use client';

import type { Cookiebot } from '@/types/cookiebot';
import { useEffect, useState } from 'react';

/**
 * A React hook wrapper for Cookiebot consent. Provides reactivity for when a user accepts or
 * declines cookies, either by submitting their preferences or from a previous visit.
 */
export default function useConsent() {
  const [consent, setConsent] = useState<Cookiebot['consent'] | null>(null);

  // Update consent when the user changes their preferences
  useEffect(() => {
    const listener = () => {
      const Cookiebot = window.Cookiebot as Cookiebot;
      setConsent(Cookiebot.consent);
    };

    // CookiebotOnConsentReady is fired when the user changes their preferences and on page load if
    // they've previously responded. Docs aren't clear on the diff between CookiebotOnLoad and
    // CookiebotOnConsentReady but they're both triggered at the same points. Their articles
    // reference CookiebotOnConsentReady more though so using that.
    window.addEventListener('CookiebotOnConsentReady', listener);

    return () => window.removeEventListener('CookiebotOnConsentReady', listener);
  }, []);

  return consent;
}
