'use client';

import { useState } from 'react';
import NextLink from 'next/link';
import { type Colourway } from '@/lib/theme';
import MobileNav from './nav/mobile/MobileNav';
import HeaderSearch from './search/HeaderSearch';
import Logo from '@/components/icons/Logo.svg';
import MobileTrigger from '@/components/header/nav/mobile/MobileTrigger';

type HeaderProps = {
  colourway: Colourway;
  headerNav: React.ReactNode;
  mobileNavInner: React.ReactNode;
};

export default function HeaderInner({ colourway, headerNav, mobileNavInner }: HeaderProps) {
  const [mobileNavIsEnabled, setMobileNavIsEnabled] = useState(false);

  return (
    <>
      <div className="mx-auto flex items-center justify-end px-7 md:container">
        <NextLink href="/" aria-label="Home" className="mr-auto">
          {/* Hardcode the role to img pending a better solution - https://github.com/gregberge/svgr/issues/961 */}
          <Logo width={137} height={22} className="text-brabners-heading" role="img" />
        </NextLink>
        <MobileTrigger
          className="py-6 md:hidden"
          colourway={colourway}
          isEnabled={mobileNavIsEnabled}
          setIsEnabled={setMobileNavIsEnabled}
        />
        {headerNav}
        <HeaderSearch className="ml-2 hidden md:block" colourway={colourway} />
      </div>
      <MobileNav colourway={colourway} isEnabled={mobileNavIsEnabled}>
        {mobileNavInner}
      </MobileNav>
    </>
  );
}
