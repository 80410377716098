import type { Block_Insights } from '@/types/fragments/mainNeo';
import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { getImageSources } from '@/lib/craftImage';

export default function InsightCard({
  id,
  title,
  url,
  slug,
  postDate,
  postDateFormatted,
  fallbackImage: fallbackImages,
  indexHeading,
  indexSummary,
  ...rawImageSources
}: Block_Insights['insights'][number]) {
  const fallbackImage = fallbackImages[0];
  return (
    <article className="group">
      <div className="flex flex-col">
        <p className="mb-5">
          <time dateTime={postDate}>{postDateFormatted}</time>
        </p>
        <Link href={url} className="mb-5 block">
          <h3 className="heading text-2xl">{indexHeading || title}</h3>
        </Link>
        {fallbackImage && (
          // Same aspect ratio for all screen sizes
          <Link href={url} className="order-first mb-5 overflow-hidden md:mb-7">
            <Picture
              sources={getImageSources('image', rawImageSources)}
              imageProps={{
                src: fallbackImage.url,
                alt: fallbackImage.alt || fallbackImage.title,
                className:
                  'aspect-square w-full h-full object-cover transition-transform duration-700 lg:motion-safe:group-has-[a:hover]:scale-105',
                width: fallbackImage.width,
                height: fallbackImage.height,
              }}
            />
          </Link>
        )}
      </div>
      {indexSummary && <p className="mb-7">{indexSummary}</p>}
      <Link href={url} className="mt-7 flex items-center font-bold text-brabners-copper-600">
        Read more
        <div className="ml-3.5 overflow-hidden">
          <svg
            width="56"
            height="14"
            viewBox="0 0 56 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="presentation"
            className="-translate-x-1/3 transition-transform duration-300 motion-safe:group-has-[a:hover]:translate-x-0"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M51.5391 6.28345L47.1387 2.00002L48.5338 0.566895L55.4336 7.28345L48.5338 14L47.1387 12.5669L51.5391 8.28345H0V6.28345H51.5391Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </Link>
    </article>
  );
}
