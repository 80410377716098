'use client';

import { useEffect, useRef, useState } from 'react';
import Script from 'next/script';
import useConsent from '@/lib/hooks/useConsent';
import useLocalStorage from '@/lib/hooks/useLocalStorage';

type HubspotEmbedProps = { formId: string };

let identifiedEmail: string | null = null;

export default function HubspotEmbed({ formId }: HubspotEmbedProps) {
  // Reactive hooks
  const [isLoaded, setIsLoaded] = useState(false);
  const [emailAddress, setEmailAddress] = useLocalStorage<string | null>(
    'brabners-hubspot-email',
    null,
  ); // Use localStorage to persist email address across requests
  const consent = useConsent();
  const hasConsentedToTracking = consent?.marketing ?? false;

  // Non-reactive refs to avoid re-rendering
  const setEmailAddressRef = useRef(setEmailAddress); // setEmailAddress is not expected to change
  const formIdRef = useRef(formId); // formId is not expected to change
  const idRef = useRef(`hbspt-form-${getHtmlId(formIdRef.current)}`);

  // Initialise form when Hubspot has loaded
  useEffect(() => {
    if (!isLoaded) return;
    window.hbspt!.forms.create({
      portalId: '24983590',
      region: 'eu1',
      formId: formIdRef.current,
      target: `#${idRef.current}`,
      // Remember email address if user consents to tracking
      onFormSubmitted: (redirectUrl, { submissionValues }) => {
        if (hasConsentedToTracking && submissionValues.email) {
          setEmailAddressRef.current(submissionValues.email as string);
        }
      },
    });
    // Don't react to hasConsentedToTracking changes - just use the value at the time of form submission
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  // Identify user using email address
  useEffect(() => {
    if (!hasConsentedToTracking || !emailAddress || emailAddress === identifiedEmail) {
      return;
    }

    const _hsq = (window._hsq = window._hsq || []);
    console.log('Identifying user:', emailAddress);
    _hsq.push([
      'identify',
      {
        email: emailAddress,
      },
    ]);
    identifiedEmail = emailAddress;
  }, [emailAddress, hasConsentedToTracking]);

  return (
    <>
      <Script
        id="hubspot-embed"
        data-cookieconsent="necessary"
        src="https://js-eu1.hsforms.net/forms/embed/v2.js"
        onReady={() => {
          setIsLoaded(true);
        }}
      />
      {isLoaded ? (
        <div id={idRef.current} />
      ) : (
        <p className="flex items-center justify-center">
          <span
            className="inline-block size-8 animate-spin rounded-full border-[3px] border-current border-t-transparent text-brabners-copper"
            role="status"
            aria-label="Loading"
          />{' '}
          <span className="ml-3">Loading form...</span>
        </p>
      )}
    </>
  );
}

function getHtmlId(str: string): string {
  return str.replace(/^[^a-z]+|[^\w:.-]+/gi, '-');
}
