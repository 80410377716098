// import defaultTheme from 'tailwindcss/defaultTheme';
import type { Config } from 'tailwindcss';
import Forms from '@tailwindcss/forms';
import Preline from 'preline/plugin';

/**
 * Like TW's default colors, these go from light to dark with 500 as the default.
 */
const themeColors = {
  brabners: {
    // ================= Brabners - Darker feel use of more Navy & Blue
    navy: {
      DEFAULT: '#181b25', // 500
      100: '#727987',
      200: '#475062', // Light navy for strokes and gradients
      250: '#2b303b', // Darker for thicker strokes
      300: '#252935',
      400: '#222732',
      500: '#181b25',
    },
    blue: {
      DEFAULT: '#175677', // 500
      300: '#3b7ea1', // Light blue for strokes. Ignoring 3B7EA0 from design - only used for testmonial carousel pagination
      500: '#175677',
    },
    copper: {
      DEFAULT: '#b17450', // 500
      300: '#dc8c5d', // Light
      500: '#b17450',
      // 500: '#a06847',
      600: '#9d6646',
      700: '#d38a40',
    },
    white: '#ffffff',
    black: {
      DEFAULT: '#2b2b2b', // 500
      500: '#262626', // Prime use text
      600: '#101010',
      700: '#111111',
    },
    cream: {
      DEFAULT: '#fbf9f8', // 500
      300: '#fff7f6', // Patterns XXX not used?
      400: '#fbfaf8',
      500: '#fbf9f8',
      600: '#e2dcda',
    },
    mushroom: '#efe9e5',
    storm: '#dbcabe',
    beige: '#ae9e93',
    // ================= Brabners Personel - Lighter feel use of colour
    'sunset-yellow': {
      DEFAULT: '#f4b16d', // 500
      400: '#e19c73',
      500: '#f4b16d',
    },
    'stone-pink': {
      DEFAULT: '#f8dbdb', // 500
      500: '#f8dbdb',
      600: '#e7b2b2', // Patterns
      700: '#e49c98', // Testimonial carousel pagination inactive
    },
    //
    // ================= Shareholder rights
    'lunar-green': {
      DEFAULT: '#274E37', // 500
      300: '#5f6c3b',
      500: '#274e37',
    },
    // ================= Extra
    heading: 'var(--color-heading)',
  },
};
const config: Config = {
  content: [
    './src/app/**/*.{js,ts,jsx,tsx,mdx}',
    './src/components/**/*.{js,ts,jsx,tsx,mdx}',
    './src/lib/**/*.{js,ts,jsx,tsx,mdx}',
    './src/styles/**/*.css',
    './node_modules/preline/preline.js',
  ],
  theme: {
    // Comments of the default breakpoints in use
    screens: {
      xs: '425px', // @media (min-width: 425px) { ... }
      sm: '640px', // @media (min-width: 640px) { ... }
      md: '768px', // @media (min-width: 768px) { ... }
      lg: '1024px', // @media (min-width: 1024px) { ... }
      xl: '1280px', // @media (min-width: 1280px) { ... }
      '2xl': '1536px', // @media (min-width: 1536px) { ... }
      '3xl': '1920px', // @media (min-width: 1920px) { ... }
      '4xl': '2560px', // @media (min-width: 2560px) { ... }
    },
    extend: {
      backgroundColor: themeColors,
      backgroundImage: {
        'navy-radial':
          'radial-gradient(44.76% 50% at 50% 50%, #373f50 5.11%, theme(colors.brabners.navy.500) 100%)',
        'green-radial': 'radial-gradient(67.79% 63.12% at 50% 50%, #496e48 5.11%, #21432f 100%)',
        'navy-radial-mobile':
          'radial-gradient(33.25% 50% at 50% 50%, theme(colors.brabners.navy.200) 0%, theme(colors.brabners.navy.500) 100%)',
        'navy-radial-timeline':
          'radial-gradient(50% 50% at 50% 50%, #273244 0%, theme(colors.brabners.navy.500) 100%)',
        'image-overlay': 'linear-gradient(180deg, rgb(65 65 65 / 0) 0%, rgb(0 0 0 / 0.76) 100%)',
        'image-overlay-flipped':
          'linear-gradient(0deg, rgb(65 65 65 / 0) 0%, rgb(0 0 0 / 0.76) 100%)',
        select:
          'url(\'data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none"%3E%3Cpath d="m7 7 3-3 3 3m0 6-3 3-3-3" stroke="%239fa6b2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E\')',
      },
      backgroundPosition: {
        select: 'right .25rem center',
      },
      backgroundSize: {
        select: '1.5em 1.5em',
      },
      boxShadow: {
        glow: '0 0 20px rgb(255 255 255 / 0.1)',
        default: '5px 5px 30px 0 #e2dcda',
        test: '5px 5px 30px 0 red',
        'person-card': '0 10px 20px 0 #d4d1d0',
        'timeline-card': '10px 10px 40px 0 rgba(0, 0, 0, 0.25)',
      },
      colors: themeColors,
      fontFamily: {
        // Falbacks are included in vars
        sans: ['var(--font-akkurat)' /* , ...defaultTheme.fontFamily.sans */],
        serif: ['var(--font-itc-caslon)' /* , ...defaultTheme.fontFamily.serif */],
      },
      fontSize: {
        sm: ['0.875rem' /* 14px */, '1.75rem' /* 28px */],
        base: ['1rem' /* 16px */, '1.875rem' /* 30px */],
        '4.5xl': ['2.5rem' /* 40px */, '2.75rem' /* 44px */],
        // 5xl is 48px by default, using 43px instead as 48px is only used in one place
        '5xl': ['2.6875rem' /* 43px */, '2.875rem' /* 46px */],
        '5.5xl': ['3rem' /* 48px */, '1'],
      },
      gridTemplateColumns: {
        'article-hero': 'minmax(0, 1fr) minmax(0, 55%)',
        // '2-1': 'minmax(0, 2fr) minmax(0, 1fr)',
        // '1-2': 'minmax(0, 1fr) minmax(0, 2fr)',

        // this ensures that the columns are 2fr / 1fr of the default container width (75rem),
        // rather than the full viewport width. This means that the edge of the left column will
        // align with the left edge of other blocks using .max-width-container using the smaller
        // column as an example it's (container width / 3) + ((viewport width - container width) /
        // 2)
        '2-1': 'calc(50rem + ((100dvw - 75rem) / 2)) calc(25rem + ((100dvw - 75rem) / 2))',
        '1-2': 'calc(25rem + ((100dvw - 75rem) / 2)) calc(50rem + ((100dvw - 75rem) / 2))',
      },
      inset: {
        /*
          The top position is the gap between the search icon and the top of the input,
          ie. (height of input - height of search icon) / 2. It's the same for the right
          position - the gap between the search icon and the right of the input - but it's
          already absolutely positioned with right-3 so we just need to invert that.
        */
        'header-search-top': 'calc((2.25rem - 23px) / 2)',
      },
      maxWidth: {
        // '2xs': '16.25rem' /* 260px */,
        'rich-text': '50rem' /* 800px */,
        narrow: '43.75rem' /* 700px */,
        // One third of max-w-widest
        'full-one-third': '40rem' /* 640px */,
        'full-two-third': '80rem' /* 1280px */,
        'container-one-third': '25rem' /* 400px */,
        'container-two-third': '50rem' /* 800px */,
        'container-half': '37.5rem' /* 600px */,
        slim: '64rem' /* 1024px */,
        container: '75rem' /* 1200px */,
        'heading-cards': '80.5rem' /* 1288px */,
        footer: '86.25rem' /* 1380px */,
        wide: '90rem' /* 1440px - the viewport used in the design */,
        widest: '120rem' /* 1920px */,
        '4k': '160rem' /* 2560px */,
      },
      lineHeight: {
        '8.5': '2.125rem' /* 34px */,
      },
      spacing: {
        '1.25': '0.3125rem' /* 5px */,
        '12.5': '3.125rem' /* 50px */,
        '15': '3.75rem' /* 60px */,
        '18': '4.5rem' /* 72px */,
        '34': '8.5rem' /* 136px */,
        '90': '22.5rem' /* 360px */,
      },
      width: {
        footer: 'min(86.25rem, 100% - 3.75rem)' /* min(1380px, 100% - 60px)*/,
      },
      keyframes: {
        glitch: {
          '0%': { transform: 'translate(0, 0)', filter: 'hue-rotate(0deg)' },
          '1%': { transform: 'translate(0.125rem, 0)' },
          '2%': { transform: 'translate(-0.125rem, 0)' },
          '3%': { transform: 'translate(0.438rem, 0)' },
          '4%': { transform: 'translate(0.125rem, 0)' },
          '5%': { transform: 'translate(-0.563rem, 0)' },
          '6%': { transform: 'translate(-0.813rem, 0)' },
          '7%': { transform: 'translate(0, -0.625rem)' },
          '8%': { transform: 'translate(-0.938rem, 0)' },
          '9%': { transform: 'translate(0, 0)' },
          '10%': { transform: 'translate(0, 0)', filter: 'hue-rotate(360deg)' },
          '11%': { transform: 'translate(0, 0)', filter: 'hue-rotate(0deg)' },
          '15%': { transform: 'translate(0, 0)', filter: 'hue-rotate(0deg)' },
          '16%': { transform: 'translate(-0.125rem, 0)' },
          '17%': { transform: 'translate(0.125rem, 0)' },
          '18%': { transform: 'translate(-0.438rem, 0)' },
          '19%': { transform: 'translate(-0.125rem, 0)' },
          '20%': { transform: 'translate(0.563rem, 0)' },
          '21%': { transform: 'translate(0.813rem, 0)' },
          '22%': { transform: 'translate(0, 0.625rem)' },
          '23%': { transform: 'translate(0.938rem, 0)' },
          '24%': { transform: 'translate(0, 0)' },
          '25%': { transform: 'translate(0, 0)', filter: 'hue-rotate(360deg)' },
          '27%': { transform: 'translate(0, 0)', filter: 'hue-rotate(0deg)' },
          '100%': { transform: 'translate(0, 0)', filter: 'hue-rotate(0deg)' },
        },
        wobble: {
          '0%, 100%': { transform: 'translateX(0%)', 'transform-origin': '50% 50%' },
          '7%': { transform: 'translateX(-2rem) rotate(-10deg)' },
          '15%': { transform: 'translateX(calc(2rem / 2)) rotate(10deg)' },
          '23%': { transform: 'translateX(calc(-2rem / 2)) rotate(calc(-10deg / 1.8))' },
          '30%': { transform: 'translateX(calc(2rem / 3.3)) rotate(calc(10deg / 3))' },
          '37%': { transform: 'translateX(calc(-2rem / 5.5)) rotate(calc(-10deg / 5))' },
          '45%': { transform: 'translateX(0%)' },
        },
        hover: {
          '0%': { transform: 'translateY(0)' },
          '50%': { transform: 'translateY(-2.5rem)' },
          '100%': { transform: 'translateY(0)' },
        },
        shadowHover: {
          '0%': { transform: 'scaleX(1) translateY(100%)' },
          '50%': { transform: 'scaleX(.8) scaleY(.8) translateY(100%)' },
          '100%': { transform: 'scaleX(1) scaleY(1) translateY(100%)' },
        },
        shadowWobble: {
          '0%, 100%': { transform: 'translateX(0%)' },
          '7%': { transform: 'translateX(-2.375rem)' },
          '15%': { transform: 'translateX(calc(2.375rem / 2))' },
          '23%': { transform: 'translateX(calc(-2.375rem / 2))' },
          '30%': { transform: 'translateX(calc(2.375rem / 3.3))' },
          '37%': { transform: 'translateX(calc(-2.375rem / 5.5))' },
          '45%': { transform: 'translateX(0%)' },
        },
        liftupRotate: {
          '0%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(-3.75rem) rotate(15.75deg)' }, // 60px
        },
        liftup: {
          '0%': { transform: `translateY(0)` },
          '100%': { transform: `translateY(-20%)` }, // 60px
        },
        shadowLiftup: {
          '0%': { transform: 'scaleX(1) translateY(100%)' },
          '100%': { transform: 'scaleX(.6) scaleY(.8) translateY(100%) translateX(-3rem)' }, // 20px
        },
        fadeIn: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
      },
      animation: {
        glitch: 'glitch 3s 3s linear infinite',
        hover: 'hover 7s 3s cubic-bezier(0.22, 1, 0.36, 1) infinite',
        shadowHover: 'shadowHover 7s 3s cubic-bezier(0.22, 1, 0.36, 1) infinite',
        wobble: 'wobble 4s 3s ease infinite',
        shadowWobble: 'shadowWobble 4s 3s ease infinite',
        liftupRotate: 'liftupRotate .75s 4s ease-in-out forwards',
        liftup: 'liftup .75s 4s ease-in-out forwards',
        shadowLiftup: 'shadowLiftup .75s 4s ease-in-out forwards',
        fadeIn: 'fadeIn .75s 4.75s ease-in-out forwards',
      },
    },
  },
  plugins: [Forms, Preline],
};
export default config;
