import type { HeaderNav_Node } from '@/types/queries/getHeaderNav';
import Link from '@/components/Link';

type BaseNavNodeProps = React.ComponentPropsWithoutRef<'li'> & {
  innerProps?:
    | Omit<React.ComponentPropsWithoutRef<'a'>, 'href' | 'rel' | 'target'>
    | React.ComponentPropsWithoutRef<'span'>
    | undefined;
} & { node: HeaderNav_Node };

export default function BaseNavNode({
  node,
  innerProps = {
    className:
      'block h-full min-h-[4.375rem] border-t-4 border-transparent p-4 hover:border-t-brabners-cream focus:bg-brabners-cream focus:text-brabners-black',
  },
  ...attributes
}: BaseNavNodeProps) {
  // Passive node
  if (node.url === null) {
    return (
      <li {...attributes}>
        <span {...innerProps}>{node.title}</span>
      </li>
    );
  }

  return (
    <li {...attributes}>
      <Link href={node.url} {...innerProps}>
        {node.title}
      </Link>
    </li>
  );
}
