import { GraphQLClient } from 'graphql-request';
import type { Entries } from '@/types/utils';
import { getSdk, type Sdk, type SdkFunctionWrapper } from '@/generated/gql/sdk';
import memoize from 'lodash/memoize';

/**
 * Not using `process.env.NEXT_PUBLIC_ENVIRONMENT` as that would still be dev even when running `npm
 * run build` locally.
 */
const isDev = process.env.NODE_ENV === 'development';

const logOperationName = isDev;

const GRAPHQL_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL as string;

/**
 * Using Map to ensure the same client objects are reused, which in turn ensures the gql sdks are
 * reused, which in turn ensures that the sdks' query methods are memoized.
 */
const gqlClients = new Map<string | null, GraphQLClient>();

const gqlSdks = new Map<GraphQLClient, Sdk>();

/**
 *
 * @param token Use `null` to use the public GraphQL schema
 */
export function getGqlClient(token: string | null): GraphQLClient {
  if (gqlClients.has(token)) {
    return gqlClients.get(token)!;
  }

  const gqlClient = new GraphQLClient(GRAPHQL_URL, {
    headers: {
      'User-Agent': 'Vercel Build Bot/1.0 (brabners.com deployment)',
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined),
    },
    fetch: async (url: string | Request | URL, init?: RequestInit) => {
      // Add operation name in dev to make it easier to identify requests
      const headers = init?.headers as Record<string, string> | undefined;
      const operationName = headers?.['x-operation-name'];

      if (init?.headers) {
        if ('x-operation-name' in init.headers) {
          delete init.headers?.['x-operation-name'];
        }

        // Add revalidate from headers
        if ('x-next-revalidate' in init.headers) {
          init.next = init.next ?? {};
          init.next.revalidate =
            init.headers['x-next-revalidate'] === 'false'
              ? false
              : Number(init.headers['x-next-revalidate']);
          // Custom header, only used here to pass data from wrapper
          delete init.headers['x-next-revalidate'];
        }

        // Disable data cache for draft mode. Draft mode enables dynamic rendering, but will still
        // use cached data unless we opt out.
        if ('X-Craft-Token' in init.headers) {
          init.cache = 'no-store'; // The no-cache option behaves the same way as no-store in Next.js.
        }

        // Set cache from override
        if ('x-cache' in init.headers) {
          init.cache = init.headers['x-cache'] as RequestCache;

          // Custom header, only used here to pass data from wrapper
          delete init.headers['x-cache'];
        }
      }

      return fetch(
        `${url}${logOperationName && operationName ? `?operationName=${operationName}` : ''}`,
        init,
      );
    },
    ...(isDev ? { cache: 'no-cache' } : undefined),
  });
  gqlClients.set(token, gqlClient);
  return gqlClient;
}

/**
 * Adds operation name in dev to make it easier to identify requests
 */
const devWrapper: SdkFunctionWrapper | undefined = logOperationName
  ? (action, _operationName /* , _operationType, _variables */) => {
      return action({ 'x-operation-name': _operationName });
    }
  : undefined;

export function getGqlSdk(gqlClient: GraphQLClient): Sdk {
  if (gqlSdks.has(gqlClient)) {
    return gqlSdks.get(gqlClient)!;
  }

  const gqlSdk: Sdk = getSdk(gqlClient, devWrapper);

  /**
   * Memoized version of the SDK to avoid duplicate GQL requests for this render cycle. Replaces
   * Next.js fetch memoization, which only works for GET requests whereas gql requests are always
   * POST.
   */
  const memoizedGqlSdk: Sdk = Object.fromEntries(
    Object.entries(gqlSdk).map(([key, value]) => {
      return [
        key,
        // XXX Using lodash.memoize as React.cache doesn't seem to work
        memoize(
          value,
          // Cache key with current second - not sure why but data was persisting between requests
          // without this
          (...args) => `${Math.floor(Date.now() / 1000)}-${JSON.stringify(args)}`,
        ),
      ];
    }) as Entries<Sdk>,
  );
  gqlSdks.set(gqlClient, memoizedGqlSdk);
  return memoizedGqlSdk;
}
