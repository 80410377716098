'use client';

import type {
  Sector,
  SectorOrSectorMirror,
  SectorMirrorSector,
} from '@/types/queries/getRootSectors';
import NextLink from 'next/link';
import { useState } from 'react';
import getColourScheme, { type Colourway } from '@/lib/theme';
import BaseNavNode from './BaseNavNode';
import NlToBr from '@/components/utils/NlToBr';

type SectorsNavNodeInnerProps = {
  sectors: SectorOrSectorMirror[];
  colourway: Colourway;
};

export default function SectorsNavNodeInner({
  sectors,
  colourway: headerColourway,
}: SectorsNavNodeInnerProps) {
  const [highlightedSector, setHighlightedSector] = useState<SectorOrSectorMirror | null>(null);
  const normalisedHighlightedSector = highlightedSector ? normaliseSector(highlightedSector) : null;
  const colourway = getColourway(headerColourway);
  const theme = getColourScheme(colourway);

  return (
    <div
      className="grid grid-cols-2-1"
      onMouseLeave={() => {
        setHighlightedSector(null);
      }}
    >
      <div className="container-2-1-left py-14 lg:pr-14">
        <NextLink className="heading mb-5 block lg:text-3xl" href="/sectors">
          Sectors
        </NextLink>
        <ul className="columns-3 gap-x-7 [column-width:18.75rem]">
          {sectors.map((sector) => (
            <BaseNavNode
              key={sector.id}
              className="mb-4 hover:underline"
              innerProps={{ className: 'block' }}
              node={{
                title: normaliseSector(sector).title,
                url: sector.url,
                element: {
                  sectionHandle: sector.sectionHandle,
                  id: sector.id,
                },
              }}
              onMouseEnter={() => {
                setHighlightedSector(sector);
              }}
            />
          ))}
        </ul>
      </div>
      {normalisedHighlightedSector && (
        <div className={`${theme.bg} ${theme.text} ${theme.heading}`}>
          <div className="container-2-1-right py-14 pl-14">
            <p className="heading mb-5 block lg:text-3xl">{normalisedHighlightedSector.title}</p>
            {normalisedHighlightedSector.indexSummary && (
              <p>
                <NlToBr text={normalisedHighlightedSector.indexSummary} />
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function getColourway(headerColourway: Colourway): Colourway {
  switch (headerColourway) {
    case 'white': // Brabners personal
      return 'yellow';
    case 'pink':
      return 'mushroom';
    default:
      return 'blue';
  }
}

/**
 * Normalises mirrored sectors to the same shape as normal sectors.
 */
function normaliseSector(sector: SectorOrSectorMirror): Sector {
  if (!('mirroredEntry' in sector)) {
    return sector;
  }

  const { mirroredEntry: mirroredEntries, ...rest } = sector;
  // const mirroredEntry = mirroredEntries[0] ?? {title: undefined, indexSummary: undefined};
  const mirroredEntry = (mirroredEntries[0] as SectorMirrorSector | undefined) || {
    title: '',
    indexSummary: '',
  };

  return {
    ...rest,
    ...mirroredEntry,
  };
}
